import React from 'react'
import * as S from './styles'

import { formatCurrency } from '../../../../utils/currency'
import { dateFormat, formatDateTime } from '../../../../utils/date'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'

interface ILabelDetalhesOptionsProps {
  options: {
    value: string
    label: string
  }[]
  value: string | undefined
}

interface ILabelDetalhesProps {
  label: string
  text?: string
  value?: number
  date?: string
  boolValue?: boolean
  bool?: boolean
  dateTime?: string
  option?: ILabelDetalhesOptionsProps
  obs?: string
}

const LabelDetalhes = ({
  label,
  value,
  text,
  date,
  dateTime,
  bool,
  boolValue,
  option,
  obs
}: ILabelDetalhesProps) => {
  console.log(label, text, dateTime)
  return (
    <S.LabelDetalhe>
      <div>{label}</div>
      {!!value && <span>{formatCurrency(value)}</span>}
      {!!date && <span>{formatDateTime(date, dateFormat)}</span>}
      {!!dateTime && <span>{formatDateTime(dateTime)}</span>}
      {bool && <span>{boolValue ? 'Sim' : 'Não'}</span>}
      {!!option && (
        <span>{getLabelFromOptions(option.options, option?.value)}</span>
      )}
      {!!text && <span>{text}</span>}
      {!!obs && <span>Obs: {obs}</span>}
    </S.LabelDetalhe>
  )
}

export default LabelDetalhes
