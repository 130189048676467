import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  id: Yup.number().optional(),
  nome: Yup.string().min(3).required(),
  email: Yup.string().email().required(),
  tipo: Yup.string().required().nullable(),
  ativo: Yup.string().required().nullable(),
  senha: Yup.string().when('id', {
    is: (value: string) => !value,
    then: Yup.string().min(4).required()
  })
})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
