import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FaCheck, FaCogs } from 'react-icons/fa'
import { IApiVendaStatus } from '../../../../services/ApiRequests/Venda/interfaces'
import { BadgeText, BadgeVendaStatus, BadgeWrapper } from './styles'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'
import VendaStatusForm from '../../forms/VendaStatus'
import Api from '../../../../services/Api'
import { RoleType } from '../../../../config/Roles'

interface IVendaStatusProps {
  dados?: IApiVendaStatus
  userRole?: RoleType
}

const VendaStatus: React.FC<IVendaStatusProps> = ({ dados, userRole }) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [status, setStatus] = useState<IApiVendaStatus>()
  const [corBadge, setCorBadge] = useState<string>()
  const [statusText, setStatusText] = useState<string>()

  useEffect(() => {
    setStatus(dados)
  }, [dados])

  const handleStatusText = useCallback((status?: IApiVendaStatus) => {
    if (!status?.id) return ''

    setCorBadge('#ff9419')

    if (status.cancelado) {
      setCorBadge('#c72901')
      return 'Contrato Cancelado'
    }

    if (status.finalizado) {
      setCorBadge('#2dc000')
      return 'Finalizado'
    }
    if (status.registrando) return 'Registrando'
    if (status.recolheuitbi) return 'Recolhendo ITBI'
    if (status.escritfinanassinado)
      return 'Enviando para Escritura/Financiamento'
    if (status.bancogerandocontrato) return 'Banco gerando Contrato'
    if (status.bancoanalisedoc) return 'Banco analisando documentação/Avaliação'
    if (status.contratoparticularassinado) return 'Contrato particular Assinado'
    if (status.pedidocertidao) return 'Pedido de Certidões'
    if (status.faltadocumentos) return 'Faltando Documentos'
  }, [])

  useEffect(() => {
    setStatusText(handleStatusText(status))
  }, [handleStatusText, status])

  const handleOnCloseModal = useCallback((update?: IApiVendaStatus) => {
    if (update) {
      setStatus(update)
    }
  }, [])

  const handleEdit = useCallback(() => {
    if (dados?.id)
      Api.venda.getStatus(dados?.id).then((data) => {
        setStatus(data)
        modalRef.current?.openModal()
      })
  }, [dados?.id])

  return (
    <>
      <BadgeWrapper>
        {statusText && (
          <BadgeVendaStatus color={corBadge}>
            <FaCheck />
            <BadgeText>{statusText}</BadgeText>
          </BadgeVendaStatus>
        )}
        {userRole !== 'CORRETOR' && (
          <Button onClick={() => handleEdit()} variant="secondary">
            <FaCogs />
          </Button>
        )}
      </BadgeWrapper>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`Alteração de Status`}
      >
        <VendaStatusForm
          callback={(update) => {
            modalRef.current?.closeModal()
            handleOnCloseModal(update)
          }}
          status={status}
        />
      </Modal>
    </>
  )
}

export default VendaStatus
