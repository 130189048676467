import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import InputSelect from '../../../../components/InputSelect'
import {
  Form,
  FormRow,
  FormGroupSeparator,
  FormRowStrippedWrapper
} from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import InputMask from '../../../../components/InputMask'
import {
  InputSelectOptionsEstadoCivil,
  InputSelectOptionsFile
} from '../../../../config/InputSelectConfig'
import { FaTimesCircle, FaCheckCircle, FaTrash } from 'react-icons/fa'
import InputFileSelectMultiplo from '../../../../components/InputFileSelectMultiplo'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { Table } from '../../../../components/Table'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { useSelector } from 'react-redux'
import { alertError } from '../../../../utils/sweetAlert'
// import { Container } from './styles';

export interface ClienteDataArquivo {
  id: number
  nome: string
  categoria: string
  url?: string
}

export interface ClienteDataForm {
  id: number
  nome: string
  email: string
  telefone: string
  cidade: {
    id: string
    nome: string
    uf: string
  }
  conjuge: {
    id: number
    nome: string
    documento: string
  }
  corretor: {
    id: number
    nome: string
  }
  arquivos: ClienteDataArquivo[]
}

interface ClienteFormProps {
  cliente?: ClienteDataForm
}

const ClienteForm: React.FC<IFormCallback & ClienteFormProps> = ({
  callback,
  cliente
}) => {
  const formRef = useRef<FormHandles>(null)
  const [clienteID, setClienteID] = useState<string | null>(null)
  const [arquivosApagados, setArquivosApagados] = useState<number[]>([])

  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  //masks = ['000.000.000-000', '00.000.000/0000-00']

  const handleDeleteFile = useCallback(
    (id: number) => {
      if (cliente)
        cliente.arquivos = cliente.arquivos.filter((arq) => arq.id !== id)
      setArquivosApagados([...arquivosApagados, id])
    },
    [arquivosApagados, cliente]
  )

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      //console.log(data)
      await validateForm(data)

      if (!data.corretorid) throw new Error('Informe o(a) Corretor(a)!')

      const formData = new FormData()

      formData.append(
        'dados',
        JSON.stringify({
          id: clienteID,
          arquivosApagados,
          ...data
        })
      )

      if (data.arquivos?.length) {
        data.arquivos.map((file: File) =>
          formData.append(`arquivos`, file, file.name)
        )
      }

      if (!clienteID) {
        await Api.cliente.save(formData)
      } else {
        await Api.cliente.update(formData)
      }

      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    if (cliente && cliente.id) {
      const { cidade, corretor, conjuge, ...rest } = cliente

      setClienteID(`${cliente.id}`)
      formRef.current.setData({
        ...rest,
        cidade: {
          label: `${cidade.nome} - ${cidade.uf}`,
          value: cidade.id
        },
        ...(corretor?.id && {
          corretorid: {
            label: corretor.nome,
            value: corretor.id
          }
        }),
        ...(conjuge?.id && {
          conjugeid: {
            label: conjuge.nome,
            value: conjuge.id
          }
        })
      })
    }
  }, [cliente])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCidade = useCallback(async (inputValue?: string) => {
    return Api.municipio.get(inputValue).then((data) => {
      const arrayData = data.map((mun) => ({
        label: `${mun.nome} - ${mun.uf}`,
        value: mun.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div style={usuario.tipo === '5' ? { display: 'none' } : {}}>
        <InputSelectAsync
          label="Corretor"
          name="corretorid"
          asyncLoadOptions={handleSearchCorretor}
        />
        <FormGroupSeparator>
          <span>Dados do Cliente</span>
          <hr />
        </FormGroupSeparator>
      </div>
      <FormRow>
        <Input name="nome" label="Nome/Razão Social" />
        <Input
          name="documento"
          label="CPF/CNPJ"
          // mask="999.999.999-99"
          width="15rem"
        />
        <Input name="rg" label="RG/I.E." width="13rem" />
      </FormRow>
      <FormRow>
        <InputSelectAsync
          name="conjugeid"
          label="Cônjuge"
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRow>
      <FormRow>
        <Input name="logradouro" label="Logradouro" width="50%" />
        <Input name="bairro" label="Bairro" />
        <InputMask mask="99999-999" name="cep" label="CEP" />
      </FormRow>
      <FormRow>
        <Input name="numero" label="Número" width="10rem" />
        <InputSelectAsync
          name="cidade"
          label="Cidade"
          asyncLoadOptions={handleSearchCidade}
        />
        <InputSelect
          name="estadocivil"
          label="Estado Cível"
          options={InputSelectOptionsEstadoCivil}
          width="15rem"
        />
        <Input name="profissao" label="Profissão" width="18rem" />
      </FormRow>
      <FormRow>
        <InputMask
          mask="(99) 99999-9999"
          name="telefone"
          label="Telefone"
          width="14rem"
        />
        <InputMask
          mask="(99) 99999-9999"
          name="celular"
          label="Celular"
          width="14rem"
        />
        <Input name="email" label="E-mail" />
      </FormRow>

      <FormGroupSeparator>
        <span>Anexos</span>
        <hr />
      </FormGroupSeparator>
      {cliente?.arquivos && (
        <Table<ClienteDataArquivo & { opcoes: string }>
          headers={{
            nome: 'Arquivo',
            categoria: 'Categoria',
            url: 'Visualizar',
            opcoes: 'Opções'
          }}
          columnSizes={{
            nome: '10rem',
            categoria: '10rem',
            url: '5rem',
            opcoes: '2rem'
          }}
          items={cliente.arquivos}
          customRenderers={{
            url: (obj) => {
              if (obj.nome) {
                return (
                  <a
                    href={`/api/arquivos/${obj.nome}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clique Aqui
                  </a>
                )
              }
            },
            categoria: (obj) => {
              return getLabelFromOptions(InputSelectOptionsFile, obj.categoria)
            },
            opcoes: (obj) => (
              <TableButtonWrapper>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    if (obj.id) handleDeleteFile(obj.id)
                  }}
                >
                  <FaTrash />
                </Button>
              </TableButtonWrapper>
            )
          }}
        />
      )}
      <FormRowStrippedWrapper>
        <InputFileSelectMultiplo
          inputFile={{ name: 'arquivos', label: 'Arquivo' }}
          inputSelect={{ name: 'categorias', label: 'Categoria' }}
          optionsSelect={InputSelectOptionsFile}
        />
      </FormRowStrippedWrapper>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Cliente</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default ClienteForm
