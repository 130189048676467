import * as Yup from 'yup'
import { yupFiles } from '../../../../utils/yup/yup.files'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  prazo: Yup.string().required(),
  dataposse: Yup.string().required('Data da Posse é um campo obrigatório'),
  valorvenda: Yup.number()
    .required('Informe o Valor')
    .moreThan(0, 'Informe um valor maior que zero.'),
  arquivos: yupFiles(true),
  categorias: Yup.array().of(
    Yup.string().nullable().required('Informe uma categoria para o arquivo')
  ),
  depositos: Yup.array()
    .optional()
    .of(
      Yup.object().shape({
        titular: Yup.string().required('Titular é um campo obrigatório'),
        banco: Yup.string().required('Banco é um campo obrigatório'),
        agencia: Yup.string().required('Agência é um campo obrigatório'),
        conta: Yup.string().required('Conta é um campo obrigatório'),
        tipo: Yup.string().required('Tipo é um campo obrigatório'),
        valor: Yup.number()
          .required('Informe o Valor')
          .moreThan(0, 'Informe um valor maior que zero.')
      })
    )
})
const schemaCreate = Yup.object()
  .shape({
    compradores: Yup.array().min(1, 'Informe o comprador'),
    vendedores: Yup.array().min(1, 'Informe o vendedor')
  })
  .concat(schema)

export const validateForm = async (data: any, create: boolean) => {
  await (create ? schemaCreate : schema).validate(data, {
    abortEarly: false
  })
}
