import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaEye, FaImage } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxStore } from '../../../../redux'

import Api from '../../../../services/Api'

import { formatCurrency } from '../../../../utils/currency'

import { formatDateTime, dateFormat } from '../../../../utils/date'

import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { Button } from '../../../../components/Button'
import Pagination from '../../../../components/Pagination'
import {
  IApiCaptacaoImovelSearchItem,
  IApiCaptacaoImovelSearchRequest
} from '../../../../services/ApiRequests/CaptacaoImovel/interfaces'
import { ICaptacaoImovelRedux } from '../../../../redux/modules/captacaoimovel/types'
import {
  modalCaptacaoImovelFiltroHandler,
  setFiltroCaptacaoImovel
} from '../../../../redux/modules/captacaoimovel/actions'
import CaptacaoImovelFiltro from '../CaptacaoImovelFiltro'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { getRole, RoleType } from '../../../../config/Roles'
import CaptacaoImovelStatus from '../CaptacaoImovelStatus'
import MultipleUpload from '../../../../components/MultipleUpload'

export interface ICaptacaoImovelPesquisaHandles {
  refreshSearch: () => void
}

interface ICaptacaoImovelPesquisaProps {
  opcoesRender?: (
    item: Partial<IApiCaptacaoImovelSearchItem>
  ) => React.ReactNode
}

const CaptacaoImovelPesquisa: React.ForwardRefRenderFunction<
  ICaptacaoImovelPesquisaHandles,
  ICaptacaoImovelPesquisaProps
> = ({ opcoesRender }, ref) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const modalUploadRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const dispatch = useDispatch()
  const [search, setSearch] = useState<IApiCaptacaoImovelSearchRequest>()
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()
  const [captacaoUpload, setCaptacaoUpload] = useState<
    Partial<IApiCaptacaoImovelSearchItem>
  >()

  const captacaoImovelRedux = useSelector<ReduxStore, ICaptacaoImovelRedux>(
    (state) => state.captacaoImovel
  )
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  useEffect(() => {
    if (captacaoImovelRedux.pesquisa.modalShow) modalRef.current?.openModal()
  }, [captacaoImovelRedux.pesquisa.modalShow])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setSearch({} as IApiCaptacaoImovelSearchRequest)

    Api.captacaoImovel
      .search({ ...captacaoImovelRedux.pesquisa.filtro })
      .then((data) => {
        setSearch(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [captacaoImovelRedux.pesquisa.filtro])

  const handleOnUpload = useCallback(
    async (files: File[]) => {
      console.log(!captacaoUpload?.id, files)
      if (!captacaoUpload?.id) return

      const formData = new FormData()
      formData.append('data', JSON.stringify(captacaoUpload))
      files.map((file: File) => formData.append('arquivos', file, file.name))

      await Api.captacaoImovel.uploadMultiple(formData)
    },
    [captacaoUpload]
  )

  useImperativeHandle(ref, () => {
    return {
      refreshSearch: () => handleLoad()
    }
  })

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <Table<IApiCaptacaoImovelSearchItem>
        headers={{
          id: 'Código',
          created_at: 'Cadastro',
          corretor: 'Corretor',
          nome: 'Cliente',
          bairro: 'Bairro',
          valorimovel: 'Valor',
          status: 'Status',
          opcoes: 'Opções'
        }}
        columnSizes={{
          id: '3rem',
          created_at: '5rem',
          nome: '10rem',
          corretor: '8rem',
          bairro: '8rem',
          valorimovel: '5rem',
          status: '7rem',
          opcoes: '9rem'
        }}
        items={search?.list}
        customRenderers={{
          corretor: (it) => {
            return it.corretor?.nome
          },
          nome: (it) => {
            return it?.cliente?.nome || it.nome
          },
          valorimovel: (it) => {
            if (it.valorimovel) return formatCurrency(it.valorimovel)
          },
          created_at: (it) => {
            if (it.created_at) return formatDateTime(it.created_at, dateFormat)
          },
          opcoes: (it) => (
            <TableButtonWrapper>
              <Link to={`/dashboard/capitacaoimoveis/detalhes/${it.id}`}>
                <Button variant="info">
                  <FaEye />
                </Button>
              </Link>
              <Button
                variant="secondary"
                onClick={() => {
                  if (it) {
                    setCaptacaoUpload(it)
                    modalUploadRef.current?.openModal()
                  }
                }}
              >
                <FaImage />
              </Button>
              {opcoesRender && opcoesRender(it)}
            </TableButtonWrapper>
          ),
          status: (it) => {
            if (it.id)
              return (
                <CaptacaoImovelStatus
                  dados={{
                    id: it.id,
                    status: it.status
                  }}
                  userRole={usuarioRole}
                />
              )
          }
        }}
      />
      <Pagination
        pageSize={search?.pageSize}
        totalCount={search?.count}
        onPageChange={(page) => dispatch(setFiltroCaptacaoImovel({ page }))}
      />

      <Loading ref={loadRef} />

      <Modal
        ref={modalRef}
        title={`Filtrar Pesquisa de Captação de Imóveis`}
        onClose={() => dispatch(modalCaptacaoImovelFiltroHandler(false))}
      >
        <CaptacaoImovelFiltro callback={() => modalRef.current?.closeModal()} />
      </Modal>

      <Modal
        ref={modalUploadRef}
        title={`Enviar fotos da Captação de Imóveis de Código ${captacaoUpload?.id}`}
      >
        <MultipleUpload onUpload={handleOnUpload} />
      </Modal>
    </>
  )
}

export default forwardRef(CaptacaoImovelPesquisa)
