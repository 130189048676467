import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaFilter, FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'

import { getRole, RoleType } from '../../../../config/Roles'
import VendaForm from '../../forms/Venda'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { modalFiltroHandler } from '../../../../redux/modules/vendas/actions'
import Api from '../../../../services/Api'
import { IApiVendaData } from '../../../../services/ApiRequests/Venda/interfaces'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import VendasPesquisa, {
  IVendasPesquisaHandles
} from '../../components/VendasPesquisa'

const Vendas = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const modalRef = useRef<IModalPropsHandles>(null)
  const pesquisiaRef = useRef<IVendasPesquisaHandles>(null)
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const [vendaData, setVendaData] = useState<IApiVendaData>({} as IApiVendaData)
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const handleDelete = useCallback(async (id?: number) => {
    if (!id) return

    alertConfirma(
      `Confirma a exclusão da venda de código "${id}"?`,
      async () => {
        await Api.venda.delete(id)
        pesquisiaRef.current?.refreshSearch()
      }
    )
  }, [])

  const handleEdit = useCallback(async (id?: number) => {
    if (!id) return

    const dados = await Api.venda.getById(id)
    setVendaData(dados)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setVendaData({} as IApiVendaData)
    pesquisiaRef.current?.refreshSearch()
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Vendas</h1>
        <Button onClick={() => history.push('/dashboard/vendas/cadastro')}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Vendas</h4>
          <Button
            variant="secondary"
            onClick={() => dispatch(modalFiltroHandler(true))}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <VendasPesquisa
            ref={pesquisiaRef}
            opcoesRender={(it) => (
              <>
                {usuarioRole !== 'CORRETOR' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(it.id)}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it.id)}
                    >
                      <FaTrash />
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          vendaData.id
            ? `Editar venda de código ${vendaData.id}`
            : 'Cadastro de Vendas'
        }`}
      >
        <VendaForm
          callback={() => modalRef.current?.closeModal()}
          venda={vendaData}
        />
      </Modal>
    </>
  )
}

export default Vendas
