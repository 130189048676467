import styled, { css } from 'styled-components'

type BadgeProps = {
  color?: string
}

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  padding: 0.2rem;
  border-radius: 0.5rem;
  border: 1px Empreendimentoed ${({ theme }) => theme.colors.grey100};

  ${({ theme }) => css`
    ${theme.media.tablet} {
      justify-content: flex-start;
      button {
        margin-left: 1rem;
      }
    }

    ${theme.media.phone} {
      justify-content: flex-start;
      button {
        margin-left: 1rem;
      }
    }
  `};
`

export const BadgeText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const BadgeEmpreendimentoStatus = styled.div<BadgeProps>(
  ({ color }) => css`
    background-color: ${color || '#C4c4c4'};
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
    font-size: 1.2rem;

    display: flex;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin: 0 0.2rem;
      font-size: 1.3rem;
    }
  `
)


interface ICardBoxProps {
  boxcolor?: string
}

export const EmpreendimentoGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  h4 {
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`

export const EmpreendimentoBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;

  ${({ theme }) => css`

    ${theme.media.phone} {
      grid-template-columns: repeat(1, 1fr);

    }
  `};  
`

export const EmpreendimentoCard = styled.main<ICardBoxProps>`
  display: flex ;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;
  margin: 0 0.5rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #eaeaea;
  border-radius: 3px;

  margin-bottom: 1rem;

  header {
    font-size: 1.3rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ theme }) => css`
    ${theme.media.tablet} {
      flex-direction: column;
    }

    ${theme.media.phone} {
      flex-direction: column;
    }
  `};

  ${({ boxcolor }) =>
    boxcolor &&
    css`
      background-color: ${boxcolor};
      /* color: #f0f0f0 !important; */
      header {
        /* color: #f0f0f0 !important; */
      }
    `}
`

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'

type ButtonProps = {
  variant: ButtonType
  hide?: boolean
}

export const LinkButtonStyled = styled.a<ButtonProps>`
  background: ${({ theme, variant }) => theme.colors[variant]};
  border-radius: 0.5rem;
  border: none;
  padding: 0 1rem;
  height: 3rem;
  color: ${({ theme, variant }) =>
    variant === 'info' ? '#4d4d4d' : theme.colors.white};
  transition: opacity 0.2s;
  cursor: pointer;
  text-decoration: none;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  svg {
    font-size: 1.3rem;

    & + span {
      margin-left: 0.5rem;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
