import styled from 'styled-components'

export const Navbar = styled.div`
  grid-area: nav;
  background: ${({ theme }) => theme.colors.primary};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 2rem;
  border-bottom: 1px solid lightgray;
`

export const Icon = styled.div`
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem;

  svg {
    margin-right: 1rem;
  }
`

export const NavbarLeft = styled.div`
  /* & > a {
    //margin-right: 30px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    font-weight: 500;
  }

  @media only screen and (max-width: 480px) {
    display: none;
  } */
`
export const NavbarRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > a {
    margin-left: 20px;
    text-decoration: none;
  }

  & > a > i {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    text-align: center;
    margin-right: 1rem;
  }
`

export const NavbarRightUser = styled.a`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  div {
    font-weight: 500;
    font-size: 1.4rem;
  }
  span {
    font-size: 1.1rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const NavbarToggleBar = styled.div`
  display: none;
  cursor: pointer;

  & > i {
    font-size: 3rem;
    color: ${({ theme }) => theme.colors.white};
  }

  @media only screen and (max-width: 978px) {
    display: inline;
  }
`
