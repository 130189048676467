import { useCallback, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Loading, { ILoadingHandles } from '../Loading'
import { FileList } from './FileList'
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from './styles'

interface IMultipleUploadProps {
  onUpload?: (files: File[]) => Promise<void>
}

const MultipleUpload: React.FC<IMultipleUploadProps> = ({
  children,
  onUpload
}) => {
  const [files, setFiles] = useState<File[]>([])
  const loadRef = useRef<ILoadingHandles>(null)

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (onUpload) {
        setFiles(acceptedFiles)
        loadRef.current?.showLoading()
        try {
          await onUpload(acceptedFiles)
        } catch (err: any) {}
        loadRef.current?.hideLoading()
      }
    },
    [onUpload]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'image/*'
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Carregue as imagens aqui ...</p>
        ) : (
          <p>Arraste e solte os arquivos, ou clique em selecionar arquivos</p>
        )}
      </div>
      <Loading ref={loadRef} />
      <FileList files={files} />
    </div>
  )
}

export default MultipleUpload
