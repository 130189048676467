import styled from 'styled-components'

export const NotificacaoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.6rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  main > section {
    border-radius: 0.5rem;
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.grey300};
    }
  }
`
export const NotificacaoItemWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.3rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100} !important;
  }
`
export const NotificacaoItemMensagem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  svg {
    color: ${({ theme }) => theme.colors.success};
    font-size: 1.5rem;
    margin-right: 0.8rem;
  }
`
