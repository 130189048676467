import { IApiAuthLoginResult } from '../../../services/ApiRequests/AuthRequests/interfaces'
import { SetUsuario, TypeUsuario } from './types'

export const setUsuario = (payload: IApiAuthLoginResult): SetUsuario => ({
  type: TypeUsuario.SET_USUARIO,
  payload
})

export const clearUsuario = () => ({
  type: TypeUsuario.CLEAR_USUARIO
})
