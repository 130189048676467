import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authIsAuthenticated } from '../../hooks/auth'

interface IPropsPrivateRoute {
  component: React.FC<any>
  path: string
  exact?: boolean
}

export const PrivateRoute: React.FC<IPropsPrivateRoute> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authIsAuthenticated()) {
          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }

        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
