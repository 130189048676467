import React from 'react'
import { IApiVendaJuridico } from '../../../../services/ApiRequests/Venda/interfaces'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'

import LabelDetalhes from './LabelDetalhes'

interface IDetalhesJuridicoProps {
  juridico: IApiVendaJuridico
}

const DetalhesJuridico = ({ juridico }: IDetalhesJuridicoProps) => {
  return (
    <>
      <section>
        <LabelDetalhes
          label="Documento do Imóvel"
          text={`${juridico.docimovel ? 'Sim' : 'Não'}`}
          obs={juridico?.docimovelobs}
        />
        <LabelDetalhes
          label="Documento do Comprador"
          text={`${juridico.doccomprador ? 'Sim' : 'Não'}`}
          obs={juridico?.doccompradorobs}
        />
        <LabelDetalhes
          label="Documento do Vendedor"
          text={`${juridico.docvendedor ? 'Sim' : 'Não'}`}
          obs={juridico?.docvendedorobs}
        />
      </section>
      <section>
        <LabelDetalhes
          label="Confecção de Contrato Compra e Venda"
          text={`${juridico.confeccaocontrato ? 'Sim' : 'Não'}`}
          obs={juridico?.confeccaocontratoobs}
        />
        <LabelDetalhes
          label="Notificou Marketing para remover anúncio"
          text={`${juridico.notificoumarketing ? 'Sim' : 'Não'}`}
          obs={juridico?.notificoumarketingobs}
        />
        <LabelDetalhes
          label="Digitalizar Documentos em uma Pasta"
          text={`${juridico.digitalizoudocs ? 'Sim' : 'Não'}`}
          obs={juridico?.digitalizoudocsobs}
        />
      </section>

      <section>
        <LabelDetalhes
          label="Conferência de Contrato"
          text={`${juridico.conferenciacontrato ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Assinatura de Contrato"
          text={`${
            juridico.assinaturacontrato ? 'Sim' : 'Não'
          } ${getLabelFromOptions(
            InputSelectOptionsAssinaturaTipo,
            juridico.assinaturacontratotipo
          )}`}
        />
        <LabelDetalhes
          label="Deu entrada na Escritura"
          text={`${juridico.entradaescritura ? 'Sim' : 'Não'}`}
        />
      </section>
      <section>
        <LabelDetalhes
          label="Deu entrada no ITBI"
          text={`${juridico.entradaitbi ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Deu entrada no Registro"
          text={`${juridico.entradaregistro ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes label="" text="" />
      </section>
    </>
  )
}

export default DetalhesJuridico
