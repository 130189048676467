import { TypeFiltroVenda, IFiltroVenda, SetFiltro } from './types'

export const setFiltroVenda = (filtro: IFiltroVenda): SetFiltro => ({
  type: TypeFiltroVenda.SET_FILTRO,
  filtro
})

export const clearFiltroVenda = () => ({
  type: TypeFiltroVenda.CLEAR_FILTRO
})

export const modalFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroVenda.MODAL_SHOW : TypeFiltroVenda.MODAL_HIDE
})
