import React, { useState, useCallback } from 'react'
import { IRoutesProps } from '../../../../../routes/interface'
import * as S from './styles'

const Item: React.FC<IRoutesProps> = ({
  text,
  icon: Icon,
  active,
  to = '#',
  subRoutes
}) => {
  const [dropped, setDropped] = useState(active)

  const handlerClick = useCallback(
    (e: React.MouseEvent) => {
      if (!!subRoutes) e.preventDefault()

      setDropped(!dropped)
    },
    [subRoutes, dropped]
  )

  return (
    <S.Item>
      <S.StyledLink
        exact={to.split('/').slice(1).length === 1}
        to={to}
        onClick={handlerClick}
      >
        <S.InsideLink hasDropDown={!!subRoutes} dropped={dropped}>
          {Icon && <Icon />}
          <span>{text}</span>
        </S.InsideLink>
      </S.StyledLink>

      {subRoutes && (
        <S.DropDown dropped={dropped}>
          {subRoutes
            .filter((drop) => !!drop.text) // Pegar os que não está vazio
            .map((drop, index) => {
              return (
                <S.StyledLink
                  exact={drop.to === ''}
                  key={index}
                  to={`${to}${drop.to}`}
                >
                  {drop.text}
                </S.StyledLink>
              )
            })}
        </S.DropDown>
      )}
    </S.Item>
  )
}

export default Item
