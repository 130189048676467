import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaEye } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxStore } from '../../../../redux'
import {
  modalFiltroHandler,
  setFiltroVenda
} from '../../../../redux/modules/vendas/actions'
import { IVendaRedux } from '../../../../redux/modules/vendas/types'
import Api from '../../../../services/Api'
import {
  IApiVendaSearch,
  IApiVendaSearchItem
} from '../../../../services/ApiRequests/Venda/interfaces'
import { formatCurrency } from '../../../../utils/currency'
import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import VendaFiltro from '../VendaFiltro'
import VendaStatus from '../VendaStatus'
import { formatDateTime, dateFormat } from '../../../../utils/date'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { getRole, RoleType } from '../../../../config/Roles'
import Pagination from '../../../../components/Pagination'

export interface IVendasPesquisaHandles {
  refreshSearch: () => void
}

interface IVendasPesquisaProps {
  opcoesRender?: (item: Partial<IApiVendaSearchItem>) => React.ReactNode
}

const VendasPesquisa: React.ForwardRefRenderFunction<
  IVendasPesquisaHandles,
  IVendasPesquisaProps
> = ({ opcoesRender }, ref) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const dispatch = useDispatch()
  const [search, setSearch] = useState<IApiVendaSearch>()
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const vendaRedux = useSelector<ReduxStore, IVendaRedux>(
    (state) => state.venda
  )
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  useEffect(() => {
    if (vendaRedux.pesquisa.modalShow) modalRef.current?.openModal()
  }, [vendaRedux.pesquisa.modalShow])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setSearch({} as IApiVendaSearch)

    Api.venda
      .search({ ...vendaRedux.pesquisa.filtro })
      .then((data) => {
        setSearch(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [vendaRedux.pesquisa.filtro])

  useImperativeHandle(ref, () => {
    return {
      refreshSearch: () => handleLoad()
    }
  })

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <Table<IApiVendaSearchItem>
        headers={{
          id: 'Código',
          cadastro: 'Cadastro',
          compradores: 'Compradores',
          vendedores: 'Vendedores',
          corretor: 'Corretor',
          prazo: 'Prazo Final',
          valorvenda: 'Valor',
          status: 'Status',
          opcoes: 'Opções'
        }}
        columnSizes={{
          id: '3rem',
          cadastro: '5rem',
          compradores: '8rem',
          vendedores: '8rem',
          corretor: '5rem',
          prazo: '4rem',
          valorvenda: '5rem',
          status: '8rem',
          opcoes: '7rem'
        }}
        items={search?.list}
        customRenderers={{
          valorvenda: (it) => {
            if (it.valorvenda) return formatCurrency(it.valorvenda)
          },
          prazo: (it) => {
            if (it.prazo) return formatDateTime(it.prazo, dateFormat)
          },
          cadastro: (it) => {
            if (it.cadastro) return formatDateTime(it.cadastro, dateFormat)
          },
          opcoes: (it) => (
            <TableButtonWrapper>
              <Link to={`/dashboard/vendas/detalhes/${it.id}`}>
                <Button variant="info">
                  <FaEye />
                </Button>
              </Link>
              {opcoesRender && opcoesRender(it)}
            </TableButtonWrapper>
          ),
          status: (it) => (
            <VendaStatus dados={it.status} userRole={usuarioRole} />
          )
        }}
      />
      <Pagination
        pageSize={search?.pageSize}
        totalCount={search?.count}
        onPageChange={(page) => dispatch(setFiltroVenda({ page }))}
      />

      <Loading ref={loadRef} />

      <Modal
        ref={modalRef}
        title={`Filtrar Pesquisa`}
        onClose={() => dispatch(modalFiltroHandler(false))}
      >
        <VendaFiltro callback={() => modalRef.current?.closeModal()} />
      </Modal>
    </>
  )
}

export default forwardRef(VendasPesquisa)
