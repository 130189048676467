import { useRef, useEffect, useCallback } from 'react'
import * as S from './styles'
import Item from './Item'
import { useHistory } from 'react-router-dom'
import { IMenuProps } from '../../../../routes/interface'
import { HEADER_SIDEBAR } from '../../../../config/global'

interface IPropsSidebar {
  menu: IMenuProps[]
  isOpen: boolean
  closeSidebar: () => void
}

const SideBar = ({ menu, isOpen, closeSidebar }: IPropsSidebar) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const handleClick = useCallback(
    (e) => {
      if (menuRef.current?.contains(e.target)) {
        return
      }
      // outside click
      if (isOpen) closeSidebar()
    },
    [closeSidebar, isOpen]
  )

  useEffect(() => {
    window.addEventListener('mousedown', handleClick)
    return () => window.removeEventListener('mousedown', handleClick)
  }, [handleClick])

  useEffect(() => {
    return history.listen(() => {
      if (isOpen) closeSidebar()
    })
  }, [closeSidebar, history, isOpen])

  return (
    <S.Sidebar isOpen={isOpen} id="sidebar" ref={menuRef}>
      <S.Title>
        <div>
          {/* <img src="/img/logo.svg" alt="logo" /> */}
          <h1>{HEADER_SIDEBAR}</h1>
        </div>
        <i
          className="fa fa-times"
          id="sidebarIcon"
          onClick={() => closeSidebar()}
        ></i>
      </S.Title>
      <S.Menu>
        {menu.map(({ header, routes }) => (
          <div key={header}>
            <S.Header>{header}</S.Header>
            {routes
              .filter((route) => !!route.text)
              .map((route, i) => (
                <Item
                  key={i}
                  text={route.text}
                  icon={route.icon}
                  to={route.to}
                  subRoutes={route.subRoutes}
                />
              ))}
          </div>
        ))}
      </S.Menu>
    </S.Sidebar>
  )
}

export default SideBar
