import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiCaptacaoImovelData,
  IApiCaptacaoImovelSearchParams,
  IApiCaptacaoImovelSearchRequest,
} from './interfaces'

class CaptacaoImovelRequests extends BaseRequests {
  constructor() {
    super('captacaoimoveis')
  }

  async search(
    filter?: IApiCaptacaoImovelSearchParams
  ): Promise<IApiCaptacaoImovelSearchRequest> {
    return await this.sendGet<IApiCaptacaoImovelSearchRequest>({
      params: { ...filter }
    })
  }

  async getById(id: string | number): Promise<IApiCaptacaoImovelData> {
    return this.sendGet<IApiCaptacaoImovelData>({ url: `${id}` })
  }


  async getPdf(id: string | number): Promise<void> {
    alertLoading('Gerando PDF, aguarde....')
    this.sendGet<any>({
      url: `/pdf/${id}`,
      responseType: 'blob'
    })
      .then((res) => {
        alertSucesso()
        const url = window.URL.createObjectURL(res)
        window.open(url)
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Captação de Imóvel...')
    return this.sendPost({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async uploadMultiple(data: any): Promise<void> {
    alertLoading('Enviando imagens da Captação de Imóvel...')
    return this.sendPost({ url: 'multiple', data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Captação de Imóvel...')
    return this.sendPut({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async updateStatus(data: any): Promise<void> {
    alertLoading('Atualizando Captação de Imóvel...')
    return this.sendPut({ url: '/status', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Captação de Imóvel...')
    return this.sendDelete({ param: `${id}` }).then(() => {
      alertSucesso()
    })
  }

}
export default CaptacaoImovelRequests
