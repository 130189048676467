import styled, { keyframes } from 'styled-components'
const LoadingKeyframe = keyframes`
to {
    transform: rotate(1turn);
  }
`

export const LoadingIcon = styled.div`
  animation: ${LoadingKeyframe} 1s infinite;
  border: 8px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.primary};
  height: 5.5rem;
  width: 5.5rem;
`
export const LoadingLabel = styled.div`
  padding: 0.5rem;
  font-weight: 500;
`

export const LoadingWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`
