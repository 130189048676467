import BaseRequests from '../BaseRequests'
import { IApiAuthLogin, IApiAuthLoginResult } from './interfaces'

class AuthRequests extends BaseRequests {
  constructor() {
    super('auth')
  }

  login = async (data: IApiAuthLogin): Promise<IApiAuthLoginResult> => {
    return this.sendPost<IApiAuthLoginResult>({ data })
  }

  check = async (token?: string): Promise<void> => {
    return this.sendPost({ url: 'check', data: { token } })
  }
}

export default AuthRequests
