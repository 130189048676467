import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import {
  Form,
  FormRow,
  FormGroupSeparator,
  FormRowStrippedWrapper,
  FormWrapperColumn,
  FormWrapperRow
} from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import {
  InputSelectOptionsFile,
  InputSelectOptionsTipoContaBancaria
} from '../../../../config/InputSelectConfig'
import { FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputCheckboxCurrency from '../../../../components/InputCheckboxCurrency'
import InputSelectAsyncMultiplo from '../../../../components/InputSelectAsyncMultiplo'
import InputFileSelectMultiplo from '../../../../components/InputFileSelectMultiplo'
import InputContasBancarias from '../../../../components/InputContasBancarias'
import InputDatePiker from '../../../../components/InputDatePiker'
import { InputTextarea } from '../../../../components/InputTextarea'
import { Table } from '../../../../components/Table'
import { formatCurrency } from '../../../../utils/currency'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import {
  IApiVendaArquivoData,
  IApiVendaData,
  IApiVendaDeposito,
  IApiVendaIdNome
} from '../../../../services/ApiRequests/Venda/interfaces'
import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { IApiUsuarioData } from '../../../../services/ApiRequests/UsuarioRequests/interfaces'

interface VendaFormProps {
  venda?: IApiVendaData
}

const VendaForm: React.FC<IFormCallback & VendaFormProps> = ({
  callback,
  venda
}) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)
  const [compradores, setCompradores] = useState<IApiVendaIdNome[]>([])
  const [vendedores, setVendedores] = useState<IApiVendaIdNome[]>([])
  const [depositos, setDepositos] = useState<IApiVendaDeposito[]>([])
  const [corretoresParceiros, setCorretoresParceiros] = useState<
    IApiUsuarioData[]
  >([])
  const [arquivosApagados, setArquivosApagados] = useState<number[]>([])
  const [compradoresApagados, setCompradoresApagados] = useState<number[]>([])
  const [vendedoresApagados, setVendedoresApagados] = useState<number[]>([])
  const [depositosApagados, setDepositosApagados] = useState<number[]>([])
  const [
    corretoresParceirosApagados,
    setCorretoresParceirosApagados
  ] = useState<number[]>([])

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    if (venda && venda.id) {
      setId(venda.id)
      setCompradores(venda.compradores || [])
      setVendedores(venda.vendedores || [])
      setDepositos(venda.depositos || [])
      setCorretoresParceiros(venda.corretoresParceiros || [])

      const { corretorcaptador, corretor, ...dados } = venda
      formRef.current.setData({
        ...dados,
        ...(corretorcaptador && {
          corretorcaptadorid: {
            label: corretorcaptador?.nome,
            value: corretorcaptador?.id
          }
        }),
        corretorid: {
          label: corretor?.nome,
          value: corretor?.id
        }
      })
    }
  }, [usuario.id, usuario.nome, usuario.tipo, venda])

  const handleDeleteFile = useCallback(
    (id: number) => {
      if (venda && venda.arquivos)
        venda.arquivos = venda.arquivos.filter((arq) => arq.id !== id)
      setArquivosApagados([...arquivosApagados, id])
    },
    [arquivosApagados, venda]
  )

  const handleDeleteCorretoresParceiros = useCallback(
    (id: number) => {
      const filtro = corretoresParceiros.filter((arq) => arq.id !== id)
      setCorretoresParceiros(filtro)
      setCorretoresParceirosApagados([...corretoresParceirosApagados, id])
    },
    [corretoresParceiros, corretoresParceirosApagados]
  )

  const handleDeleteCompradores = useCallback(
    (id: number) => {
      const filtro = compradores.filter((arq) => arq.id !== id)
      setCompradores(filtro)
      setCompradoresApagados([...compradoresApagados, id])
    },
    [compradores, compradoresApagados]
  )
  const handleDeleteVendedores = useCallback(
    (id: number) => {
      const filtro = vendedores.filter((arq) => arq.id !== id)
      setVendedores(filtro)
      setVendedoresApagados([...vendedoresApagados, id])
    },
    [vendedores, vendedoresApagados]
  )
  const handleDeleteDepositos = useCallback(
    (id: number) => {
      const filtro = depositos.filter((dep) => dep.id !== id)
      setDepositos(filtro)
      setDepositosApagados([...depositosApagados, id])
    },
    [depositos, depositosApagados]
  )

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      const novosCompradores = (data.compradores || []).filter(
        (id: string) => !!id
      )
      const novosVendedores = (data.vendedores || []).filter(
        (id: string) => !!id
      )

      const novosCorretoresParceiros = (data.corretoresParceiros || []).filter(
        (id: string) => !!id
      )

      if (!data.corretorid) throw new Error('Informe o(a) Corretor(a)!')

      if (!id) {
        if (novosCompradores.length === 0)
          throw new Error('Informe o(s) comprador(es)!')
        if (novosVendedores.length === 0)
          throw new Error('Informe o(s) vededor(es)!')
      }

      const dados = {
        id,
        ...data,
        compradores: novosCompradores,
        vendedores: novosVendedores,
        corretoresParceiros: novosCorretoresParceiros,
        arquivosApagados,
        compradoresApagados,
        vendedoresApagados,
        corretoresParceirosApagados,
        depositosApagados
      }

      //console.log(dados)
      await validateForm(dados, !id)

      const formData = new FormData()
      formData.append('data', JSON.stringify(dados))

      if (data.arquivos?.length) {
        data.arquivos.map((file: File) =>
          formData.append(`arquivos`, file, file.name)
        )
      }

      if (!id) {
        await Api.venda.save(formData)
      } else {
        await Api.venda.update(formData)
      }

      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div style={usuario.tipo === '5' ? { display: 'none' } : {}}>
        <InputSelectAsync
          label="Corretor"
          name="corretorid"
          asyncLoadOptions={handleSearchCorretor}
        />
      </div>

      <FormGroupSeparator>
        <span>Compradores</span>
        <hr />
      </FormGroupSeparator>

      {compradores.length > 0 && (
        <FormRowStrippedWrapper>
          {compradores.map((comp) => (
            <FormWrapperRow key={comp.id}>
              <span>{comp.nome}</span>
              <Button
                type="button"
                variant="secondary"
                onClick={() => handleDeleteCompradores(comp.id)}
              >
                <FaTrash />
              </Button>
            </FormWrapperRow>
          ))}
        </FormRowStrippedWrapper>
      )}
      <FormRowStrippedWrapper>
        <InputSelectAsyncMultiplo
          label="Comprador"
          name="compradores"
          minCount={id ? 0 : 1}
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Vendedores</span>
        <hr />
      </FormGroupSeparator>
      {vendedores.length > 0 && (
        <FormRowStrippedWrapper>
          {vendedores.map((ven) => (
            <FormWrapperRow key={ven.id}>
              <span>{ven.nome}</span>
              <Button
                type="button"
                variant="secondary"
                onClick={() => handleDeleteVendedores(ven.id)}
              >
                <FaTrash />
              </Button>
            </FormWrapperRow>
          ))}
        </FormRowStrippedWrapper>
      )}
      <FormRowStrippedWrapper>
        <InputSelectAsyncMultiplo
          label="Vendedor"
          name="vendedores"
          minCount={id ? 0 : 1}
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>
      <FormRow>
        <InputDatePiker name="prazo" label="Prazo Final" />
        <InputDatePiker name="dataposse" label="Data Posse" />
        <Input name="codigoimovel" label="Código do Imóvel no Site" />
      </FormRow>
      <FormGroupSeparator>
        <span>Valores</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputCurrency name="valorvenda" label="Valor da Venda" />
        <InputCurrency
          name="valorescritura"
          label="Valor para Declarar na Escritura"
        />
        <InputCurrency name="valorpagoavista" label="Valor será Pago à vista" />
      </FormRow>
      <FormRow>
        <InputCurrency name="valorfinanciamento" label="Valor a Financiar" />
        <InputCurrency name="valorfgts" label="Valor a usar do FGTS" />
        <InputCheckbox
          name="imoveljafinanciado"
          label="Imóvel já Financiado?"
        />
      </FormRow>
      <FormRow>
        <InputCheckboxCurrency
          width="30rem"
          inputCheck={{
            name: 'partesdeclaraimpostorenda',
            label: 'As partes vão declarar Imposto de Renda?'
          }}
          inputCurrency={{
            name: 'partesdeclaraimpostorendavalor',
            label: 'Valor'
          }}
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Pagamento do Imóvel</span>
        <hr />
      </FormGroupSeparator>
      <FormRow
        grid
        gridColumns={{ default: '4', desktop: '4', tablet: '2', phone: '1' }}
      >
        <InputCheckboxCurrency
          inputCheck={{
            name: 'pagasscontrato',
            label: 'Assinatura do Contrato Compra e Venda'
          }}
          inputCurrency={{ name: 'pagasscontratovalor', label: 'Valor' }}
        />
        <InputCheckboxCurrency
          inputCheck={{
            name: 'pagassescritura',
            label: 'Assinatura Escritura'
          }}
          inputCurrency={{ name: 'pagassescrituravalor', label: 'Valor' }}
        />
        <InputCheckboxCurrency
          inputCheck={{
            name: 'pagassfinanciamento',
            label: 'Assinatura Financiamento'
          }}
          inputCurrency={{
            name: 'pagassfinanciamentovalor',
            label: 'Valor'
          }}
        />
        <InputCheckboxCurrency
          inputCheck={{
            name: 'pagaposregistro',
            label: 'Após Registro'
          }}
          inputCurrency={{
            name: 'pagaposregistrovalor',
            label: 'Valor'
          }}
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Contas para Depósito</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        {depositos.length > 0 && (
          <>
            <Table<IApiVendaDeposito & { opcoes: string }>
              headers={{
                titular: 'Titular',
                banco: 'Banco',
                agencia: 'Agência',
                conta: 'Conta',
                tipo: 'Tipo',
                valor: 'Valor',
                opcoes: 'Opções'
              }}
              columnSizes={{
                titular: '13rem',
                banco: '4rem',
                agencia: '4rem',
                conta: '5rem',
                tipo: '6rem',
                valor: '6rem',
                opcoes: '2rem'
              }}
              items={depositos}
              customRenderers={{
                valor: ({ valor: total }) => {
                  if (total) return formatCurrency(total)
                },
                tipo: (obj) => {
                  return getLabelFromOptions(
                    InputSelectOptionsTipoContaBancaria,
                    obj.tipo
                  )
                },
                opcoes: (obj) => (
                  <TableButtonWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        if (obj.id) handleDeleteDepositos(obj.id)
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </TableButtonWrapper>
                )
              }}
            />
          </>
        )}
        <InputContasBancarias />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Comissão</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="comissaoavista" label="À vista" />
          <InputCheckbox
            name="comissaorecfinanciamento"
            label="No recebimento do Financiamento"
          />
          <InputCheckbox name="comissaoaprazo" label="A Prazo" />
        </FormWrapperColumn>
        <Input
          name="comissaoaprazodias"
          type="number"
          label="Quantidade Dias"
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Anexos</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        {venda?.arquivos && (
          <>
            <Table<IApiVendaArquivoData & { opcoes: string }>
              headers={{
                nome: 'Arquivo',
                categoria: 'Categoria',
                url: 'Visualizar',
                opcoes: 'Opções'
              }}
              columnSizes={{
                nome: '10rem',
                categoria: '10rem',
                url: '5rem',
                opcoes: '2rem'
              }}
              items={venda.arquivos}
              customRenderers={{
                url: (obj) => {
                  if (obj.nome) {
                    return (
                      <a
                        href={`/api/arquivos/${obj.nome}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique Aqui
                      </a>
                    )
                  }
                },
                categoria: (obj) => {
                  return getLabelFromOptions(
                    InputSelectOptionsFile,
                    obj.categoria
                  )
                },
                opcoes: (obj) => (
                  <TableButtonWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        if (obj.id) handleDeleteFile(obj.id)
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </TableButtonWrapper>
                )
              }}
            />
          </>
        )}
        <InputFileSelectMultiplo
          inputFile={{ name: 'arquivos', label: 'Arquivo' }}
          inputSelect={{ name: 'categorias', label: 'Categoria' }}
          optionsSelect={InputSelectOptionsFile}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Corretores Parceiros</span>
        <hr />
      </FormGroupSeparator>

      {corretoresParceiros.length > 0 && (
        <FormRowStrippedWrapper>
          {corretoresParceiros.map((corretorParceiro) => (
            <FormWrapperRow key={corretorParceiro.id}>
              <span>{corretorParceiro.nome}</span>
              <Button
                type="button"
                variant="secondary"
                onClick={() =>
                  handleDeleteCorretoresParceiros(corretorParceiro.id)
                }
              >
                <FaTrash />
              </Button>
            </FormWrapperRow>
          ))}
        </FormRowStrippedWrapper>
      )}
      <FormRowStrippedWrapper>
        <InputSelectAsyncMultiplo
          label="Corretor"
          name="corretoresParceiros"
          minCount={id ? 0 : 1}
          asyncLoadOptions={handleSearchCorretor}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Outras Informações</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputSelectAsync
          label="Corretor Captador"
          name="corretorcaptadorid"
          asyncLoadOptions={handleSearchCorretor}
          width="25rem"
        />
      </FormRow>
      <FormRow>
        <InputTextarea name="observacoes" label="Observações" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Venda</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default VendaForm
