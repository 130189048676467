import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { InputCheckbox } from '../../../../components/InputCheckbox'

import { InputTextarea } from '../../../../components/InputTextarea'
import { IApiVendaStatus } from '../../../../services/ApiRequests/Venda/interfaces'
import Api from '../../../../services/Api'

interface IVendaStatusFormProps {
  status?: IApiVendaStatus
  callback(update?: IApiVendaStatus): void
}

const VendaStatusForm: React.FC<IVendaStatusFormProps> = ({
  status,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [statusId, setStatusId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      await Api.venda.updateStatus({ id: statusId, ...data })
      if (callback) callback({ id: statusId, ...data })
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!status?.id) {
      return callback?.()
    }

    const { id, ...rest } = status
    setStatusId(id)
    formRef.current.setData(rest)
  }, [callback, status])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="faltadocumentos" label="Faltando Documentos" />
          <InputCheckbox name="pedidocertidao" label="Pedido de Certidões" />
          <InputCheckbox
            name="contratoparticularassinado"
            label="Contrato particular Assinado"
          />
          <InputCheckbox
            name="bancoanalisedoc"
            label="Banco analisando documentação/Avaliação"
          />
          <InputCheckbox
            name="bancogerandocontrato"
            label="Banco gerando Contrato"
          />
        </FormWrapperColumn>
        <FormWrapperColumn>
          <InputCheckbox
            name="escritfinanassinado"
            label="Enviando para Escritura/Financiamento"
          />
          <InputCheckbox name="recolheuitbi" label="Recolhendo ITBI" />
          <InputCheckbox name="registrando" label="Registrando" />
          <InputCheckbox name="finalizado" label="Finalizado" />
          <InputCheckbox name="cancelado" label="Contrato Cancelado" />
        </FormWrapperColumn>
      </FormRow>
      <FormRow>
        <InputTextarea name="comentario" label="Comentários" rows={6} />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default VendaStatusForm
