import { RolesTypes } from '../config/Roles'
import {
  IMenuProps,
  IRouteChildrenProps,
  IRoutesProps
} from '../routes/interface'
import { intersection } from './array'

const filterRoles = (roles: RolesTypes, routes: IRouteChildrenProps[]) => {
  if (!roles.length) return routes

  return routes.filter((route) => {
    if (!route.permission) return true

    return intersection(route.permission, roles).length
  })
}

export function filterAllowedRoutes(
  roles: RolesTypes,
  menu: IMenuProps[]
): IMenuProps[] {
  const routes = menu
    .map((mainRoute) => {
      // Filtrar as Rotas Principais
      const route = filterRoles(roles, mainRoute.routes)

      return { ...mainRoute, routes: route as IRoutesProps[] } as IMenuProps
    })
    // Verifica as Subrotas
    .map((mainRoute) => {
      if (!mainRoute?.routes) return mainRoute

      const routes = mainRoute?.routes
        .map((route) => {
          if (!route?.subRoutes) return route

          const subRoutes = filterRoles(roles, route.subRoutes)

          // Seta nulo o pai do subroute
          if (subRoutes.length === 0) return null

          return { ...route, subRoutes }
        })
        // Limpa o pai do subroute deixando o array vazio
        .filter((mainRoute) => mainRoute !== null)

      return { ...mainRoute, routes: routes as IRoutesProps[] }
    })
    //Limpa os headers que não tem routes
    .filter((mainRoute) => mainRoute.routes.length)

  return routes
}
