import { IMenuProps } from '../routes/interface'
import {
  FaFireAlt,
  FaBriefcase,
  FaShoppingCart,
  FaDoorOpen,
  FaLocationArrow,
  FaHandHoldingUsd,
  FaRegSun,
  FaMoneyBillWave,
  FaSitemap,
  FaBuilding
} from 'react-icons/fa'

import { GiHouse } from 'react-icons/gi'

import Main from '../pages/Main'
import Usuarios from '../modules/usuarios/pages/Usuarios'
import Clientes from '../modules/clientes/pages/Clientes'
import Vendas from '../modules/vendas/pages/Vendas'
import VendasJuridico from '../modules/vendas/pages/VendasJuridico'
import VendasFinanciamento from '../modules/vendas/pages/VendasFinanciamento'
import VendasFinanceiro from '../modules/vendas/pages/VendasFinanceiro'
import VendaDetalhes from '../modules/vendas/pages/VendaDetalhes'
import Logout from '../pages/Logout'
import VendaCadastro from '../modules/vendas/pages/VendaCadastro'
import Aluguel from '../modules/aluguel/pages/Aluguel'
import AluguelCadastro from '../modules/aluguel/pages/AluguelCadastro'
import AluguelDetalhes from '../modules/aluguel/pages/AluguelDetalhes'
import AluguelJuridico from '../modules/aluguel/pages/AluguelJuridico'
import AluguelVistoria from '../modules/aluguel/pages/AluguelVistoria'
import AluguelFinanceiro from '../modules/aluguel/pages/AluguelFinanceiro'
import CaptacaoImovel from '../modules/captacaoimovel/pages/CaptacaoImovel'
import CaptacaoImovelCadastro from '../modules/captacaoimovel/pages/CaptacaoImovelCadastro'
import CaptacaoImovelDetalhes from '../modules/captacaoimovel/pages/CaptacaoImovelDetalhes'
import Empreendimentos from '../modules/empeendimento-disponivel/pages/Empreendimentos'
import EmpreendimentoDetalhes from '../modules/empeendimento-disponivel/pages/EmpreendimentoDetalhes'
import Metas from '../modules/metas/pages/Metas'
import MinhasMetas from '../modules/metas/pages/MinhasMetas'
import CaptacaoImagens from '../modules/captacaoimagens/pages/CaptacaoImagens'
import CaptacaoImagensDetalhes from '../modules/captacaoimagens/pages/CaptacaoImagensDetalhes'
import Financiamentos from '../modules/financiamentos/pages/Financiamentos'
import FinanciamentosDetalhes from '../modules/financiamentos/pages/FinanciamentosDetalhes'

export interface EmpreendimentosDisponivel {
  id: number, nome: string
}


export const getPrivateRoutesConfig = (basePath: string, empDisponiveis?: EmpreendimentosDisponivel[]): IMenuProps[] => {
  return [
    {
      header: 'Dashboard',
      routes: [
        {
          text: 'Painel de Controle',
          icon: FaFireAlt,
          to: basePath,
          component: Main
        }
      ]
    },
    {
      header: 'Cadastros',
      routes: [
        {
          text: 'Cadastros',
          icon: FaBriefcase,
          to: `${basePath}/cadastros`,
          subRoutes: [
            {
              text: 'Clientes',
              to: `/clientes`,
              component: Clientes
            },
            {
              text: 'Usuários',
              to: `/usuarios`,
              component: Usuarios
            }
          ]
        }
      ]
    },
    {
      header: 'Gerenciamento',
      routes: [
        {
          text: 'Controle de Vendas',
          icon: FaShoppingCart,
          to: `${basePath}/vendas`,
          subRoutes: [
            { text: 'Vendas', to: '', component: Vendas },
            { text: '', to: '/cadastro', component: VendaCadastro },
            {
              text: '', //Hide Route
              to: '/detalhes/:id(\\d+)',
              component: VendaDetalhes
            },
            {
              text: 'Jurídico',
              to: '/juridico',
              component: VendasJuridico,
              permission: ['ADMIN', 'JURIDICO']
            },
            {
              text: 'Financiamento',
              to: '/financiamento',
              component: VendasFinanciamento,
              permission: ['ADMIN', 'FINANCIAMENTO']
            },
            {
              text: 'Financeiro',
              to: '/financeiro',
              component: VendasFinanceiro,
              permission: ['ADMIN', 'FINANCEIRO']
            }
          ]
        },
        {
          text: 'Controle de Aluguel',
          icon: GiHouse,
          to: `${basePath}/aluguel`,
          subRoutes: [
            { text: 'Aluguel', to: '', component: Aluguel },
            { text: '', to: '/cadastro', component: AluguelCadastro },
            {
              text: '', //Hide Route
              to: '/detalhes/:id(\\d+)',
              component: AluguelDetalhes
            },
            {
              text: 'Jurídico',
              to: '/juridico',
              component: AluguelJuridico,
              permission: ['ADMIN', 'JURIDICO']
            },
            {
              text: 'Vistoria',
              to: '/vistoria',
              component: AluguelVistoria
              // permission: ['ADMIN', 'FINANCIAMENTO']
            },
            {
              text: 'Financeiro',
              to: '/financeiro',
              component: AluguelFinanceiro,
              permission: ['ADMIN', 'FINANCEIRO']
            }
          ]
        },
        {
          text: 'Captação de Imóveis',
          icon: FaHandHoldingUsd,
          to: `${basePath}/capitacaoimoveis`,
          component: CaptacaoImovel
        },
        {
          text: '',
          to: `${basePath}/capitacaoimoveis/cadastro`,
          component: CaptacaoImovelCadastro
        },
        {
          text: '',
          to: `${basePath}/capitacaoimoveis/detalhes/:id(\\d+)`,
          component: CaptacaoImovelDetalhes
        },
        {
          text: 'Marketing',
          icon: FaSitemap,
          to: `${basePath}/marketing`,
          subRoutes: [
            { text: 'Captação de Imagens', to: '/captacaoImagens', component: CaptacaoImagens },
            {
              text: '', //Hide Route
              to: '/captacaoImagens/detalhes/:id(\\d+)',
              component: CaptacaoImagensDetalhes
            },
          ]
        },
        {
          text: 'Financiamentos',
          icon: FaBuilding,
          to: `${basePath}/financiamentos`,
          component: Financiamentos
        },
        {
          text: '',
          to: `${basePath}/financiamentos/detalhes/:id(\\d+)`,
          component: FinanciamentosDetalhes
        },

      ]
    },
    {
      header: 'Empreendimentos',
      routes: [
        {
          text: 'Disponíbilidades',
          icon: FaLocationArrow,
          to: `${basePath}/empreendimentos`,
          subRoutes: [
            {
              text: 'Gerenciamento',
              to: '/gerenciamento',
              component: Empreendimentos,
              permission: ['ADMIN']
            },
            {
              text: '',
              to: `/:id(\\d+)`,
              component: EmpreendimentoDetalhes
            },
            ...empDisponiveis?.map(disp => ({
              text: disp.nome,
              to: `/${disp.id}`,
            })) || []

          ]
        }
      ]
    },
    {
      header: 'Metas',
      routes: [
        {
          text: 'Gerenciamento',
          icon: FaRegSun,
          to: `${basePath}/metas/gerenciamento`,
          component: Metas,
          permission: ['ADMIN']
        },
        {
          text: 'Minhas Metas',
          icon: FaMoneyBillWave,
          to: `${basePath}/metas/minhas`,
          component: MinhasMetas,
          permission: ['CORRETOR', 'ADMIN']
        },
      ]
    },
    {
      header: '',
      routes: [
        {
          text: 'Sair',
          icon: FaDoorOpen,
          to: `${basePath}/sair`,
          component: Logout
        }
      ]
    }
  ]
}
