import React, { useEffect, useRef, InputHTMLAttributes } from 'react'
import { IconBaseProps } from 'react-icons'
import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'
import { formatCurrency } from '../../utils/currency'
// import * as S from './styles'

interface InputPropsCurrency extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  icon?: React.ComponentType<IconBaseProps>
  hide?: boolean
}

const InputCurrency: React.FC<InputPropsCurrency> = ({
  icon: Icon,
  name,
  label,
  hide = false,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        const value = `${ref.current.value}`
          .replaceAll('.', '')
          .replace(',', '.')
        return Number(value)
      },
      setValue: (ref, value) => {
        ref.current.value = ''

        if (value) {
          const format = formatCurrency(`${value}`)
          ref.current.value = format
        }
      },
      clearValue: (ref) => {
        ref.current.value = ''
      }
    })
  }, [fieldName, registerField])

  const handleFormatCurrency = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = formatCurrency(e.currentTarget.value)
    return e
  }

  return (
    <FormGroup width={rest.width} style={hide ? { display: 'none' } : {}}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        {Icon && <Icon size={23} />}
        <input
          ref={inputRef}
          value={defaultValue}
          onKeyUp={(e: React.FormEvent<HTMLInputElement>) =>
            handleFormatCurrency(e)
          }
          onFocus={clearError}
        />
      </div>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default InputCurrency
