import { OptionTypeBase } from "../../../config/global"


export enum TypeFiltroCaptacaoImovel {
  SET_FILTRO = 'FILTRO_CAPTACAO_IMOVEL_SET',
  CLEAR_FILTRO = 'FILTRO_CAPTACAO_IMOVEL_CLEAR',
  MODAL_SHOW = 'FILTRO_CAPTACAO_IMOVEL_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_CAPTACAO_IMOVEL_MODAL_HIDE'
}

export interface IFiltroCaptacaoImovel {
  dataini?: string
  datafim?: string
  corretor?: OptionTypeBase | null
  page?: number
  pageSize?: number
}

export interface ICaptacaoImovelRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroCaptacaoImovel
  }
}

export interface CaptacaoImovelSetFiltro {
  type: TypeFiltroCaptacaoImovel.SET_FILTRO
  filtro: IFiltroCaptacaoImovel
}

interface CaptacaoImovelClearFiltro {
  type: TypeFiltroCaptacaoImovel.CLEAR_FILTRO
}

interface CaptacaoImovelModalShow {
  type: TypeFiltroCaptacaoImovel.MODAL_SHOW
}

interface CaptacaoImovelModalHide {
  type: TypeFiltroCaptacaoImovel.MODAL_HIDE
}

export type ActionFiltroCaptacaoImovel =
  | CaptacaoImovelSetFiltro
  | CaptacaoImovelClearFiltro
  | CaptacaoImovelModalShow
  | CaptacaoImovelModalHide
