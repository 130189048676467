import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChevronLeft, FaFilePdf } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import LabelDetalhes from './LabelDetalhes'
import Api from '../../../../services/Api'

import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import DetalhesArquivos from './DetalhesArquivos'
import DetalhesJuridico from './DetalhesJuridico'
import DetalhesFinanceiro from './DetalhesFinanceiro'
import DetalhesCliente from './DetalhesCliente'
import { IApiAluguelDetalhes } from '../../../../services/ApiRequests/Aluguel/interfaces'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsTipoGarantia } from '../../../../config/InputSelectConfig'
import DetalhesVistoria from './DetalhesVistoria'

interface RouteParams {
  id: string
}

const AluguelDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiAluguelDetalhes>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)

  const handlePDF = useCallback(() => {
    if (!data?.id) return
    Api.aluguel.getPdf(data.id)
  }, [data])

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.aluguel
        .getByIdDetailed(params.id)
        .then((res) => {
          setData(res)
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard/aluguel')
        })
    }
  }, [handleLoading, history, params.id])

  return (
    <div>
      <CardHeader>
        <h1>Detalhes do Aluguel</h1>
        <div className="print-hide">
          <Button variant="danger" onClick={() => handlePDF()}>
            <FaFilePdf />
            PDF
          </Button>
        </div>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Aluguel Código {params.id}</h4>
          <div className="print-hide">
            <Button onClick={() => history.goBack()}>
              <FaChevronLeft />
              Voltar
            </Button>
          </div>
        </header>
        <div>
          <Loading ref={loadRef} />

          {!isLoading && (
            <div id="printme">
              <S.WrapperVendaDetalhes>
                <h4>Dados Gerais</h4>
                <section className="print-left">
                  <LabelDetalhes label="Corretor" text={data?.corretor.nome} />
                  <LabelDetalhes label="Cadastro" dateTime={data?.created_at} />
                  <LabelDetalhes
                    label="Vencimento (dia)"
                    text={`${data?.vencimento}`}
                  />
                  <LabelDetalhes
                    label="Prazo Locação (Meses)"
                    text={`${data?.prazolocacao}`}
                  />
                </section>
                {data?.codigoimovel && (
                  <section>
                    <LabelDetalhes
                      label="Cód. Imóvel Site"
                      text={data?.codigoimovel}
                    />
                  </section>
                )}
                <DetalhesCliente
                  tipo="Locador (Proprietário)"
                  dado={data?.proprietario}
                />
                <DetalhesCliente
                  tipo="Locatário (inquilino)"
                  dado={data?.inquilino}
                />
                <DetalhesCliente tipo="Fiador" dado={data?.fiador} />

                <h4>Valores</h4>
                <section>
                  <LabelDetalhes label="Valor do Aluguel" value={data?.valor} />
                  <LabelDetalhes
                    label="Garantia Tipo"
                    text={getLabelFromOptions(
                      InputSelectOptionsTipoGarantia,
                      data?.garantiatipo
                    )}
                  />
                  <LabelDetalhes
                    label="Garantia Valor"
                    value={data?.garantiavalor}
                  />
                  {/* <LabelDetalhes label="" text="" /> */}
                </section>
                <section>
                  <LabelDetalhes label="Carência" text={data?.carencia} />

                  <LabelDetalhes
                    label="Vai declarar Imposto de Renda ?"
                    text={`${data?.declararimprenda ? 'Sim' : 'Não'}`}
                  />
                  <LabelDetalhes
                    label="Imóvel com administração ?"
                    text={`${data?.imovelcomadministracao ? 'Sim' : 'Não'}`}
                  />
                </section>

                <DetalhesArquivos anexos={data?.arquivos} />
                <h4>Outras Informações</h4>
                <section>
                  {data?.corretorcaptador?.id && (
                    <LabelDetalhes
                      label="Corretor Captador"
                      text={`${data?.corretorcaptador?.id} - ${data?.corretorcaptador?.nome}`}
                    />
                  )}
                  <LabelDetalhes
                    label="Nº Unidade Energisa"
                    text={data?.numeroenergisa}
                  />
                  <LabelDetalhes
                    label="Nº Matrícula Hidrômetro"
                    text={data?.numerohidrometro}
                  />
                </section>
                <section>
                  <LabelDetalhes label="Observações" text={data?.observacoes} />
                </section>
                <hr />
                {data?.vistoria?.id && (
                  <>
                    <h3>Vistoria</h3>
                    <DetalhesVistoria vistoria={data?.vistoria} />
                  </>
                )}
                {data?.juridico?.id && (
                  <>
                    <h3>Jurídico</h3>
                    <DetalhesJuridico juridico={data.juridico} />
                  </>
                )}
                {data?.financeiro?.id && (
                  <>
                    <h3>Financeiro</h3>
                    <DetalhesFinanceiro financeiro={data.financeiro} />
                  </>
                )}
              </S.WrapperVendaDetalhes>
            </div>
          )}
        </div>
      </CardBox>
    </div>
  )
}

export default AluguelDetalhes
