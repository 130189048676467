import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import Api from '../../../../services/Api'
import { IApiAluguelFinanceiro } from '../../../../services/ApiRequests/Aluguel/interfaces'
import { InputTextarea } from '../../../../components/InputTextarea'
import { IFormCallback } from '../../../../forms/interfaces'

interface IAluguelFinanceiroFormProps extends IFormCallback {
  financeiro?: IApiAluguelFinanceiro
}

const AluguelFinanceiroForm: React.FC<IAluguelFinanceiroFormProps> = ({
  financeiro,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [finId, setFinId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      await Api.aluguel.updateFinanceiro({ id: finId, ...data })
      if (callback) callback()
    } catch (err) {}
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!financeiro?.id) {
      return callback?.()
    }

    const { id, ...rest } = financeiro
    setFinId(id)
    formRef.current.setData(rest)
  }, [callback, financeiro])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputTextarea name="repasses" label="Repasses" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default AluguelFinanceiroForm
