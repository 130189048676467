import { OptionTypeBase } from "../../../config/global"


export enum TypeFiltroCaptacaoImagens {
  SET_FILTRO = 'FILTRO_CAPTACAO_IMAGENS_SET',
  CLEAR_FILTRO = 'FILTRO_CAPTACAO_IMAGENS_CLEAR',
  MODAL_SHOW = 'FILTRO_CAPTACAO_IMAGENS_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_CAPTACAO_IMAGENS_MODAL_HIDE'
}

export interface IFiltroCaptacaoImagens {
  dataini?: string
  datafim?: string
  status?: string
  corretor?: OptionTypeBase | null
  page?: number
  pageSize?: number
}

export interface ICaptacaoImagensRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroCaptacaoImagens
  }
}

export interface CaptacaoImagensSetFiltro {
  type: TypeFiltroCaptacaoImagens.SET_FILTRO
  filtro: IFiltroCaptacaoImagens
}

interface CaptacaoImagensClearFiltro {
  type: TypeFiltroCaptacaoImagens.CLEAR_FILTRO
}

interface CaptacaoImagensModalShow {
  type: TypeFiltroCaptacaoImagens.MODAL_SHOW
}

interface CaptacaoImagensModalHide {
  type: TypeFiltroCaptacaoImagens.MODAL_HIDE
}

export type ActionFiltroCaptacaoImagens =
  | CaptacaoImagensSetFiltro
  | CaptacaoImagensClearFiltro
  | CaptacaoImagensModalShow
  | CaptacaoImagensModalHide
