import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FaCheck, FaCogs } from 'react-icons/fa'
import { BadgeAluguelStatus, BadgeText, BadgeWrapper } from './styles'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'
import Api from '../../../../services/Api'
import { RoleType } from '../../../../config/Roles'
import { IApiAluguelStatus } from '../../../../services/ApiRequests/Aluguel/interfaces'
import AluguelStatusForm from '../../forms/AluguelStatus'

interface IAluguelStatusProps {
  dados?: IApiAluguelStatus
  userRole?: RoleType
}

const AluguelStatus: React.FC<IAluguelStatusProps> = ({ dados, userRole }) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [status, setStatus] = useState<IApiAluguelStatus>()
  const [corBadge, setCorBadge] = useState<string>()
  const [statusText, setStatusText] = useState<string>()

  useEffect(() => {
    setStatus(dados)
  }, [dados])

  const handleStatusText = useCallback((status?: IApiAluguelStatus) => {
    if (!status?.id) return ''

    setCorBadge('#ff9419')

    if (status.cancelado) {
      setCorBadge('#c72901')
      return 'Contrato Cancelado'
    }

    if (status.finalizado) {
      setCorBadge('#2dc000')
      return 'Finalizado'
    }

    if (status.retirarchaves) return 'Retirar chaves'
    if (status.aguardandopagamento) return 'Aguardando pagamento de aluguel'
    if (status.aguardandotransfaguaenergia)
      return 'Aguardando transferir Água e energia'
    if (status.assinaturacontrato) return 'Assinatura de contrato'
    if (status.faltadocumentos) return 'Faltando Documentos'
  }, [])

  useEffect(() => {
    setStatusText(handleStatusText(status))
  }, [handleStatusText, status])

  const handleOnCloseModal = useCallback((update?: IApiAluguelStatus) => {
    if (update) {
      setStatus(update)
    }
  }, [])

  const handleEdit = useCallback(() => {
    if (dados?.id)
      Api.aluguel.getStatus(dados?.id).then((data) => {
        setStatus(data)
        modalRef.current?.openModal()
      })
  }, [dados?.id])

  return (
    <>
      <BadgeWrapper>
        {statusText && (
          <BadgeAluguelStatus color={corBadge}>
            <FaCheck />
            <BadgeText>{statusText}</BadgeText>
          </BadgeAluguelStatus>
        )}
        {userRole !== 'CORRETOR' && (
          <Button onClick={() => handleEdit()} variant="secondary">
            <FaCogs />
          </Button>
        )}
      </BadgeWrapper>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`Alteração de Status do Aluguel`}
      >
        <AluguelStatusForm
          callback={(update) => {
            modalRef.current?.closeModal()
            handleOnCloseModal(update)
          }}
          status={status}
        />
      </Modal>
    </>
  )
}

export default AluguelStatus
