import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FiDelete, FiEdit } from 'react-icons/fi'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'

import { CardBox, CardHeader } from '../../../../styles/Card'
import Api from '../../../../services/Api'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import EmpreendimentoDisponivelForm, {
  EmpreendimentoDisponivelDataForm
} from '../../forms/Disponivel'
import { IApiEmpreendimentosDisponivelData } from '../../../../services/ApiRequests/EmpreendimentoDisponivel/interfaces'
import { alertConfirma } from '../../../../utils/sweetAlert'
import { dateFormat, formatDateTime } from '../../../../utils/date'

const Empreendimentos = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const [
    empreendimento,
    setEmpreendimento
  ] = useState<EmpreendimentoDisponivelDataForm>(
    {} as EmpreendimentoDisponivelDataForm
  )
  const [dados, setDados] = useState<IApiEmpreendimentosDisponivelData[]>([])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setDados([])

    Api.empeendimentoDisponivel
      .getAll()
      .then((data) => {
        setDados(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setEmpreendimento({} as EmpreendimentoDisponivelDataForm)
    handleLoad()
  }, [handleLoad])

  const handleEdit = useCallback(
    async ({ id }: EmpreendimentoDisponivelDataForm) => {
      const res = await Api.empeendimentoDisponivel.getById(id.toString())
      setEmpreendimento(res)

      modalRef.current?.openModal()
    },
    []
  )

  const handleDelete = useCallback(
    async ({ id, nome }: EmpreendimentoDisponivelDataForm) => {
      alertConfirma(
        `Confirma a exclusão do Empreendimento "${nome}"?`,
        async () => {
          await Api.empeendimentoDisponivel.delete(id)
          const novos = dados.filter((disp) => disp.id !== id)
          setDados(novos)
        }
      )
    },
    [dados]
  )

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <CardHeader>
        <h1>Empreendimentos</h1>
        <Button onClick={() => modalRef.current?.openModal()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Empreendimentos</h4>
        </header>
        <div>
          <Table<IApiEmpreendimentosDisponivelData>
            headers={{
              nome: 'Nome',
              created_at: 'Cadastro',
              ativo: 'Ativo',
              opcoes: ''
            }}
            columnSizes={{
              nome: '25rem',
              ativo: '2rem',
              created_at: '5rem',
              opcoes: '5rem'
            }}
            items={dados}
            customRenderers={{
              ativo: ({ ativo }) => (ativo ? 'Sim' : 'Não'),
              created_at: (it) => {
                if (it.created_at)
                  return formatDateTime(it.created_at, dateFormat)
              },
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      handleEdit(it as EmpreendimentoDisponivelDataForm)
                    }
                  >
                    <FiEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() =>
                      handleDelete(it as EmpreendimentoDisponivelDataForm)
                    }
                  >
                    <FiDelete />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
          <Loading ref={loadRef} />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          empreendimento.id ? 'Alteração' : 'Cadastro'
        } de Empreendimento`}
      >
        <EmpreendimentoDisponivelForm
          callback={() => modalRef.current?.closeModal()}
          empreendimento={empreendimento}
        />
      </Modal>
    </>
  )
}

export default Empreendimentos
