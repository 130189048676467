import styled, { css } from 'styled-components'

export const Footer = styled.footer`
  display: block;
  padding: 2rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;

  background-color: #fff;
  color: #999;

  width: 100%;

  ${({ theme }) => css`
    ${theme.media.phone} {
      //display: none;
    }
  `}
`
