import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChevronLeft, FaFilePdf } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import LabelDetalhes from './LabelDetalhes'
import Api from '../../../../services/Api'

import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import DetalhesArquivos from './DetalhesArquivos'
import DetalhesCliente from './DetalhesCliente'
import {
  InputSelectOptionsCaptacaoImovelTipoVaga,
  InputSelectOptionsCaptacaoImovelLocalChaves,
  InputSelectOptionsCaptacaoImovelOcupado,
  InputSelectOptionsCaptacaoImovelPosicaoSol,
  InputSelectOptionsCaptacaoImovelTipoCaptacao,
  InputSelectOptionsCaptacaoImovelTipoForro,
  InputSelectOptionsCaptacaoImovelTipoImovel,
  InputSelectOptionsCaptacaoImovelTipoPiso,
  InputSelectOptionsCaptacaoImovelTipoRua,
  InputSelectOptionsQuantidade,
  InputSelectOptionsSimNao
} from '../../../../config/InputSelectConfig'
import { IApiCaptacaoImovelData } from '../../../../services/ApiRequests/CaptacaoImovel/interfaces'

interface RouteParams {
  id: string
}

const CaptacaoImovelDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiCaptacaoImovelData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)
  const [isTerreno, setIsTerreno] = useState<boolean>(false)

  const handlePDF = useCallback(() => {
    if (!data?.id) return
    Api.captacaoImovel.getPdf(data.id)
  }, [data])

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.captacaoImovel
        .getById(params.id)
        .then((res) => {
          setData(res)
          setIsTerreno(res.tipoimovel === '0')
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard/capitacaoimoveis')
        })
    }
  }, [handleLoading, history, params.id])

  return (
    <div>
      <CardHeader>
        <h1>Detalhes do Captacao do Imovel</h1>
        <div className="print-hide">
          <Button variant="danger" onClick={() => handlePDF()}>
            <FaFilePdf />
            PDF
          </Button>
        </div>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Captacao de Imóvel de Código {params.id}</h4>
          <div className="print-hide">
            <Button onClick={() => history.goBack()}>
              <FaChevronLeft />
              Voltar
            </Button>
          </div>
        </header>
        <div>
          <Loading ref={loadRef} />

          {!isLoading && (
            <div id="printme">
              <S.WrapperVendaDetalhes>
                <DetalhesCliente dado={data} />

                <h4>Dados Gerais</h4>
                <section>
                  <LabelDetalhes label="Corretor" text={data?.corretor.nome} />
                  <LabelDetalhes
                    label="Corretor Parceiro"
                    text={data?.corretorparceiro?.nome}
                  />
                  <LabelDetalhes label="Cadastro" dateTime={data?.created_at} />
                  <LabelDetalhes
                    label="Tipo de Captação"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoCaptacao,
                      value: data?.tipocaptacao
                    }}
                  />
                  <LabelDetalhes
                    label="Tipo de Imóvel"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoImovel,
                      value: data?.tipoimovel
                    }}
                  />
                </section>
                <section>
                  <LabelDetalhes label="Endereço" text={data?.endereco} />
                  <LabelDetalhes label="Número" text={data?.numero} />
                  <LabelDetalhes label="Bairro" text={data?.bairro} />
                  <LabelDetalhes label="Cep" text={data?.cep} />
                  <LabelDetalhes
                    label="Cidade"
                    text={`${data?.cidade.nome} - ${data?.cidade.uf}`}
                  />
                </section>
                <section>
                  <LabelDetalhes label="Complemento" text={data?.complemento} />
                  <LabelDetalhes label="Quadra" text={data?.quadra} />
                  <LabelDetalhes label="Lote" text={data?.lote} />
                  <LabelDetalhes label="Apartamento" text={data?.apartamento} />
                  <LabelDetalhes label="Bloco" text={data?.bloco} />
                </section>
                <h4>Valores</h4>
                <section>
                  <LabelDetalhes value={data?.valorimovel} label="Imóvel" />
                  <LabelDetalhes value={data?.valoriptu} label="IPTU" />
                  <LabelDetalhes
                    value={data?.valorcondominio}
                    label="Condomínio"
                  />
                  <LabelDetalhes
                    value={data?.valorcomissao}
                    label="Comissão %"
                  />
                  <LabelDetalhes
                    value={data?.valorhonoloc}
                    label="Honorários Loc. %"
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    value={data?.valortaxaadm}
                    label="Taxa Mensal Adm. %"
                    hide={isTerreno}
                  />
                </section>

                <h4>Detalhes</h4>
                <section style={isTerreno ? { display: 'none' } : {}}>
                  <LabelDetalhes
                    label="Dormitórios"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.dormitorios
                    }}
                  />
                  <LabelDetalhes
                    label="Suítes"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.suites
                    }}
                  />
                  <LabelDetalhes
                    label="Banheiros"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.banheiros
                    }}
                  />
                  <LabelDetalhes
                    label="Salas"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.salas
                    }}
                  />
                  <LabelDetalhes
                    label="Cozinhas"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.cozinhas
                    }}
                  />
                </section>
                <section>
                  <LabelDetalhes
                    label="Vaga de Garagem"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoVaga,
                      value: data?.vaga
                    }}
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="Laje"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.laje
                    }}
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="Esgoto"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.esgoto
                    }}
                  />
                  <LabelDetalhes
                    label="Tipo de Piso"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoPiso,
                      value: data?.tipopiso
                    }}
                    hide={isTerreno}
                  />

                  <LabelDetalhes
                    label="Tipo de Forro"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoForro,
                      value: data?.tipoforro
                    }}
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="Tipo de Rua"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelTipoRua,
                      value: data?.tiporua
                    }}
                  />
                  <LabelDetalhes
                    label="Posição do Sol"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelPosicaoSol,
                      value: data?.posicaosol
                    }}
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="Placa"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.placa
                    }}
                  />
                </section>
                <section style={isTerreno ? { display: 'none' } : {}}>
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.churrasqueira}
                    label="Churrasqueira"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.lavabo}
                    label="Lavabo"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.piscina}
                    label="Piscina"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.dispensa}
                    label="Dispensa"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.areadeservico}
                    label="Área de Serviço"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.elevador}
                    label="Elevador"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.lavanderia}
                    label="Lavanderia"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.hidro}
                    label="Hidro"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.cercaeletrica}
                    label="Cerca Elétrica"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.concertina}
                    label="Concertina"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.energiasolar}
                    label="Energia Solar"
                  />
                </section>

                <section>
                  <LabelDetalhes
                    text={data?.apartamentoporandar}
                    label="Apt. por Andar"
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    text={data?.apartamentonumandar}
                    label="Nº de Andares"
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="Exclusividade"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.exclusividade
                    }}
                  />
                  <LabelDetalhes
                    text={data?.exclusividadeprazo}
                    label="Prazo de Exclusividade"
                  />
                  <LabelDetalhes
                    label="Ocupado por"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelOcupado,
                      value: data?.ocupado
                    }}
                  />
                </section>

                <h4>Chave da Propriedade</h4>
                <section>
                  <LabelDetalhes
                    label="Local das Chaves"
                    option={{
                      options: InputSelectOptionsCaptacaoImovelLocalChaves,
                      value: data?.localchaves
                    }}
                  />
                  <LabelDetalhes
                    text={data?.contatoresponsavelchave}
                    label="Nome e contato do reponsável"
                  />
                </section>

                <h4 style={isTerreno ? { display: 'none' } : {}}>Armários</h4>
                <section style={isTerreno ? { display: 'none' } : {}}>
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armariodormitorio}
                    label="Dormitório"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armariosuites}
                    label="Suítes"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armariosala}
                    label="Sala"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armariocozinha}
                    label="Cozinha"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armariowc}
                    label="WC"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.armarioedicula}
                    label="Edícula"
                  />
                </section>

                <h4>Área</h4>
                <section>
                  <LabelDetalhes
                    text={data?.areacontruida}
                    label="Área Construída (m²)"
                  />
                  <LabelDetalhes text={data?.areautil} label="Útil (m²)" />
                  <LabelDetalhes text={data?.areacomum} label="Comum (m²)" />
                  <LabelDetalhes
                    text={data?.areaterreno}
                    label="Terreno (m²)"
                  />
                  <LabelDetalhes text={data?.areafrente} label="Frente" />
                  <LabelDetalhes text={data?.areafundo} label="Fundo" />
                  <LabelDetalhes
                    text={data?.arealateraldir}
                    label="Lateral Dir."
                  />
                  <LabelDetalhes
                    text={data?.arealateralesq}
                    label="Lateral Esq."
                  />
                </section>

                <h4>Documentação</h4>
                <section>
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.docfinanciamento}
                    label="Aceita Financiamento"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.docfgts}
                    label="Aceita FGTS"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.docsomenteavista}
                    label="Somente a Vista"
                  />
                  <LabelDetalhes
                    bool={true}
                    boolValue={data?.docpapliacao}
                    label="Possuí planta Apliação/Agio"
                    hide={isTerreno}
                  />
                  <LabelDetalhes
                    label="É Averbado ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.docaverbado
                    }}
                    hide={isTerreno}
                  />

                  <LabelDetalhes
                    label="É registrado ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.docregistrado
                    }}
                  />
                </section>

                <section>
                  <LabelDetalhes
                    label="Observações / Confidêncial"
                    text={data?.obsconfidencial}
                  />
                </section>
                <section>
                  <LabelDetalhes
                    label="Descrição do Imóvel / Texto para Divulgação na Internet"
                    text={data?.descricaoimovel}
                  />
                </section>
                <DetalhesArquivos anexos={data?.arquivos} />
                <hr />
              </S.WrapperVendaDetalhes>
            </div>
          )}
        </div>
      </CardBox>
    </div>
  )
}

export default CaptacaoImovelDetalhes
