import * as Yup from 'yup'
import { yupFiles } from '../../../../utils/yup/yup.files'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  cadastrado_site: Yup.string().nullable().required('Esse campo é obrigatório'),
  corretorid: Yup.string().nullable().required('Corretor é um campo obrigatório'),
})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
