import * as Yup from 'yup'
import { yupFiles } from '../../../../utils/yup/yup.files'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  clienteid: Yup.string().nullable().required('Proprietário é um campo obrigatório'),
  corretorid: Yup.string().nullable().required('Corretor é um campo obrigatório'),
  tipocaptacao: Yup.string().nullable().required('Tipo de Captação é um campo obrigatório'),
  tipoimovel: Yup.string().nullable().required('Tipo do Imóvel é um campo obrigatório'),
  endereco: Yup.string().required(),
  bairro: Yup.string().required(),
  cidadeid: Yup.string().nullable().required('Cidade é um campo obrigatório'),
  esgoto: Yup.string().nullable().required(),
  exclusividade: Yup.string().nullable().required(),
  tiporua: Yup.string().nullable().required('Tipo de Rua é um campo obrigatório'),
  ocupado: Yup.string().nullable().required(),
  // areacontruida: Yup.string().required('Área Construída é um campo obrigatório'),
  // areaterreno: Yup.string().required('Terreno é um campo obrigatório'),
  // dormitorios: yup.string().nullable().required(),
  // suites: yup.string().nullable().required(),
  // banheiros: yup.string().nullable().required(),
  // salas: yup.string().nullable().required(),
  // cozinhas: yup.string().nullable().required(),
  // tipopiso: yup.string().nullable().required('Tipo de Piso é um campo obrigatório'),
  // laje: yup.string().nullable().required(),
  // docaverbado: yup.string().nullable().required('"É Averbado ?" é um campo obrigatório'),  

  docregistrado: Yup.string().nullable().required('"É Registrado ?" é um campo obrigatório'),

  valorimovel: Yup.number()
    .required('Informe o Valor do Imóvel')
    .moreThan(0, 'Informe um valor maior que zero.'),
  valorcomissao: Yup.number()
    .required('Informe o percentual de comissão')
    .moreThan(0, 'Informe um percentual maior que zero.')
    .max(100, 'O percentual máximo é 100 %'),
  arquivos: yupFiles(true),
  categorias: Yup.array().of(
    Yup.string().nullable().required('Informe uma categoria para o arquivo')
  ),
  valorhonoloc: Yup.number()
    .optional()
    .max(100, 'O percentual máximo é 100 %'),
  valortaxaadm: Yup.number()
    .optional()
    .max(100, 'O percentual máximo é 100 %'),

})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
