import styled, { css } from 'styled-components'

type BadgeProps = {
  color?: string
}

export const BadgeText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const BadgeCaptacaImagensStatus = styled.div<BadgeProps>(
  ({ color }) => css`
    background-color: ${color || '#C4c4c4'};
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
    font-size: 1.1rem;

    display: flex;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin: 0 0.2rem;
      font-size: 1.3rem;
    }
  `
)
