import React from 'react'
import ReactDOM from 'react-dom'

import { ThemeProvider } from 'styled-components'

import theme from './styles/theme'
import GlobalStyles from './styles/global'

import Routes from './routes'
import { ToastContainer } from './components/Toast'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <ToastContainer />
    <Routes />
  </ThemeProvider>,
  document.getElementById('root')
)
