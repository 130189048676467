import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import {
  Form,
  FormRow,
  FormGroupSeparator,
  FormRowStrippedWrapper
} from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import {
  InputSelectOptionsTipoGarantia,
  InputSelectOptionsFile
} from '../../../../config/InputSelectConfig'
import { FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputFileSelectMultiplo from '../../../../components/InputFileSelectMultiplo'
import { InputTextarea } from '../../../../components/InputTextarea'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import {
  IApiAluguelArquivoData,
  IApiAluguelData
} from '../../../../services/ApiRequests/Aluguel/interfaces'
import InputSelect from '../../../../components/InputSelect'

interface AluguelFormProps {
  aluguel?: IApiAluguelData
}

const AluguelForm: React.FC<IFormCallback & AluguelFormProps> = ({
  callback,
  aluguel
}) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)
  const [arquivosApagados, setArquivosApagados] = useState<number[]>([])

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    if (aluguel && aluguel.id) {
      setId(aluguel.id)

      const {
        corretorcaptador,
        inquilino,
        fiador,
        proprietario,
        corretor,
        ...dados
      } = aluguel

      formRef.current.setData({
        ...dados,
        ...(inquilino && {
          inquilinoid: {
            label: inquilino?.nome,
            value: inquilino?.id
          }
        }),
        ...(fiador && {
          fiadorid: {
            label: fiador?.nome,
            value: fiador?.id
          }
        }),
        ...(proprietario && {
          proprietarioid: {
            label: proprietario?.nome,
            value: proprietario?.id
          }
        }),
        ...(corretorcaptador && {
          corretorcaptadorid: {
            label: corretorcaptador?.nome,
            value: corretorcaptador?.id
          }
        }),
        corretorid: {
          label: corretor?.nome,
          value: corretor?.id
        }
      })
    }
  }, [aluguel, usuario.id, usuario.nome, usuario.tipo])

  const handleDeleteFile = useCallback(
    (id: number) => {
      if (aluguel && aluguel.arquivos)
        aluguel.arquivos = aluguel.arquivos.filter((arq) => arq.id !== id)
      setArquivosApagados([...arquivosApagados, id])
    },
    [aluguel, arquivosApagados]
  )

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      if (!data.proprietarioid)
        throw new Error('Informe o Locador (proprietário)!')

      if (!data.inquilinoid) throw new Error('Informe o Locatário (inquilino)!')

      if (!data.corretorid) throw new Error('Informe o(a) Corretor(a)!')

      const dados = {
        id,
        ...data,
        arquivosApagados
      }

      // console.log(dados)
      await validateForm(dados)

      const formData = new FormData()
      formData.append('data', JSON.stringify(dados))

      if (data.arquivos?.length) {
        data.arquivos.map((file: File) =>
          formData.append(`arquivos`, file, file.name)
        )
      }

      if (!id) {
        await Api.aluguel.save(formData)
      } else {
        await Api.aluguel.update(formData)
      }

      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div style={usuario.tipo === '5' ? { display: 'none' } : {}}>
        <InputSelectAsync
          label="Corretor"
          name="corretorid"
          asyncLoadOptions={handleSearchCorretor}
        />
      </div>

      <FormGroupSeparator>
        <span>Proprietário</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        <InputSelectAsync
          label="Locador (proprietário)"
          name="proprietarioid"
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Inquilino</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        <InputSelectAsync
          label="Locatário (inquilino)"
          name="inquilinoid"
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Fiador</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        <InputSelectAsync
          label="Fiador"
          name="fiadorid"
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>
      <FormRow>
        <InputCurrency name="valor" label="Valor do Aluguel" />
        <Input
          name="vencimento"
          type="number"
          max={31}
          label="Vencimento (Dia)"
        />
        <Input
          name="prazolocacao"
          type="number"
          label="Prazo Locação (Meses)"
        />

        <Input name="codigoimovel" label="Código do Imóvel no Site" />
      </FormRow>
      <FormRow>
        <InputSelect
          label="Tipo de Garantia"
          name="garantiatipo"
          options={InputSelectOptionsTipoGarantia}
        />
        <InputCurrency name="garantiavalor" label="Valor da Garantia" />
        <Input name="numeroenergisa" label="Nº Unidade Energisa" />
        <Input name="numerohidrometro" label="Nº Matrícula Hidrômetro" />
      </FormRow>

      <FormRow>
        <Input name="carencia" label="Carência" maxLength={50} />
        <InputCheckbox
          name="declararimprenda"
          label="Vai declarar Imposto de Renda ?"
        />
        <InputCheckbox
          name="imovelcomadministracao"
          label="Imóvel com administração ?"
        />

        {/* <InputCheckboxCurrency
          width="30rem"
          inputCheck={{
            name: 'declararimprenda',
            label: 'As partes vão declarar Imposto de Renda?'
          }}
          inputCurrency={{
            name: 'partesdeclaraimpostorendavalor',
            label: 'Valor'
          }}
        /> */}
      </FormRow>

      <FormGroupSeparator>
        <span>Anexos</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        {aluguel?.arquivos && (
          <>
            <Table<IApiAluguelArquivoData & { opcoes: string }>
              headers={{
                nome: 'Arquivo',
                categoria: 'Categoria',
                url: 'Visualizar',
                opcoes: 'Opções'
              }}
              columnSizes={{
                nome: '10rem',
                categoria: '10rem',
                url: '5rem',
                opcoes: '2rem'
              }}
              items={aluguel.arquivos}
              customRenderers={{
                url: (obj) => {
                  if (obj.nome) {
                    return (
                      <a
                        href={`/api/arquivos/${obj.nome}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique Aqui
                      </a>
                    )
                  }
                },
                categoria: (obj) => {
                  return getLabelFromOptions(
                    InputSelectOptionsFile,
                    obj.categoria
                  )
                },
                opcoes: (obj) => (
                  <TableButtonWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        if (obj.id) handleDeleteFile(obj.id)
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </TableButtonWrapper>
                )
              }}
            />
          </>
        )}
        <InputFileSelectMultiplo
          inputFile={{ name: 'arquivos', label: 'Arquivo' }}
          inputSelect={{ name: 'categorias', label: 'Categoria' }}
          optionsSelect={InputSelectOptionsFile}
        />
      </FormRowStrippedWrapper>
      <FormGroupSeparator>
        <span>Outras Informações</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputSelectAsync
          label="Corretor Captador"
          name="corretorcaptadorid"
          asyncLoadOptions={handleSearchCorretor}
          width="25rem"
        />
      </FormRow>
      <FormRow>
        <InputTextarea name="observacoes" label="Observações" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Aluguel</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default AluguelForm
