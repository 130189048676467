import * as Yup from 'yup'
import { yupFiles } from '../../../../utils/yup/yup.files'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  nome: Yup.string().min(3).required().nullable(),
  documento: Yup.string().required().nullable(),
  cidade: Yup.string().required().nullable(),
  arquivos: yupFiles(true),
  categorias: Yup.array().of(
    Yup.string()
      .nullable()
      .required('Informe uma categoria para o arquivo')
      .nullable()
  ),
  email: Yup.string().optional().email()
})

// arquivos: Yup.array()
// .optional()
// .of(
//   Yup.object().shape({
//     arquivo: yupFile(true),
//     categoria: Yup.string().required('Informe uma categoria para o arquivo')
//   })
// )

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
