import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import LabelDetalhes from './LabelDetalhes'
import Api from '../../../../services/Api'

import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import {
  InputSelectOptionsCaptacaoImagemStatus,
  InputSelectOptionsQuantidade,
  InputSelectOptionsSimNao
} from '../../../../config/InputSelectConfig'
import { IApiCaptacaoImagensData } from '../../../../services/ApiRequests/CaptacaoImagens/interfaces'
import {
  BadgeCaptacaImagensStatus,
  BadgeText,
  BadgeWrapper
} from '../../components/CaptacaoImagensStatus/styles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'

interface RouteParams {
  id: string
}

const CaptacaoImagensDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiCaptacaoImagensData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)
  const [corBadge, setCorBadge] = useState<string>()
  const [status, setStatus] = useState<string>()

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  const handleStatus = useCallback((status) => {
    setStatus(
      getLabelFromOptions(InputSelectOptionsCaptacaoImagemStatus, status)
    )

    setCorBadge('#36617a')

    //Falta Informação
    if (status === '1') {
      setCorBadge('#eec622')
    }

    //Cancelado
    if (status === '2') {
      setCorBadge('#c72901')
    }

    //Cadastro Concluído
    if (status === '3') {
      setCorBadge('#2dc000')
    }
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.captacaoImagens
        .getById(params.id)
        .then((res) => {
          setData(res)
          handleStatus(res?.status)
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard/marketing/captacaoImagens')
        })
    }
  }, [handleLoading, history, params.id])

  return (
    <div>
      <CardHeader>
        <h1>Detalhes do Captação de Imagens</h1>
        <div className="print-hide"></div>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Captacao de Imagem de Código {params.id}</h4>
          <div className="print-hide">
            <Button onClick={() => history.goBack()}>
              <FaChevronLeft />
              Voltar
            </Button>
          </div>
        </header>
        <div>
          <Loading ref={loadRef} />

          {!isLoading && (
            <div id="printme">
              <S.WrapperVendaDetalhes>
                <section>
                  <LabelDetalhes
                    label="Imóvel cadastrado no Site ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.cadastrado_site
                    }}
                  />
                  {data?.cod_imovel && (
                    <LabelDetalhes
                      label="Código do Imóvel"
                      text={data?.cod_imovel}
                    />
                  )}
                  <LabelDetalhes label="Corretor" text={data?.corretor.nome} />
                  <LabelDetalhes label="Cadastro" dateTime={data?.created_at} />
                  {data?.status && (
                    <BadgeCaptacaImagensStatus color={corBadge}>
                      <BadgeText>{status}</BadgeText>
                    </BadgeCaptacaImagensStatus>
                  )}
                </section>
                <h4>Localização do Imóvel</h4>
                <section>
                  <LabelDetalhes label="Endereço" text={data?.endereco} />
                  <LabelDetalhes label="Número" text={data?.numero} />
                  <LabelDetalhes label="Bairro" text={data?.bairro} />
                  <LabelDetalhes label="Cep" text={data?.cep} />
                  <LabelDetalhes
                    label="Cidade"
                    text={
                      data?.cidade?.nome
                        ? `${data?.cidade?.nome} - ${data?.cidade?.uf}`
                        : ''
                    }
                  />
                </section>
                <section>
                  <LabelDetalhes label="Complemento" text={data?.complemento} />
                  <LabelDetalhes label="Quadra" text={data?.quadra} />
                  <LabelDetalhes label="Lote" text={data?.lote} />
                  <LabelDetalhes label="Apartamento" text={data?.apartamento} />
                </section>
                <h4>Dados do Imóvel</h4>
                <section>
                  <LabelDetalhes value={data?.valor_imovel} label="Imóvel" />
                  <LabelDetalhes
                    label="Dormitórios"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.dormitorios
                    }}
                  />
                  <LabelDetalhes
                    label="Suítes"
                    option={{
                      options: InputSelectOptionsQuantidade,
                      value: data?.suites
                    }}
                  />
                  <LabelDetalhes
                    text={data?.area_terreno}
                    label="Área Terreno (m²)"
                  />
                  <LabelDetalhes
                    text={data?.area_edificacao}
                    label="Área Edificação (m²)"
                  />
                </section>

                <h4>Detalhes da Captação</h4>
                <section>
                  <LabelDetalhes
                    label="Precisa de Drone ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.precisa_drone
                    }}
                  />
                  <LabelDetalhes
                    label="Precisa de Videos  ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.precisa_videos
                    }}
                  />
                  <LabelDetalhes
                    label="Precisa de Fotos ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.precisa_fotos
                    }}
                  />
                  <LabelDetalhes
                    label="Agendado com Proprietário ?"
                    option={{
                      options: InputSelectOptionsSimNao,
                      value: data?.agendado_proprietario
                    }}
                  />

                  <LabelDetalhes
                    label="Dia de Visita"
                    dateTime={data?.data_agendamento}
                  />
                </section>

                <hr />
              </S.WrapperVendaDetalhes>
            </div>
          )}
        </div>
      </CardBox>
    </div>
  )
}

export default CaptacaoImagensDetalhes
