import React, { useCallback, useEffect, useState } from 'react'
import * as S from './styles'

import { IMenuProps } from '../../../../routes/interface'

import SideBar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Notification from '../../components/Notification'

interface IDashboardProps {
  menu: IMenuProps[]
}

const Dashboard: React.FC<IDashboardProps> = ({ menu, children }) => {
  const [sideOpen, setSideOpen] = useState(false)

  const closeSidebar = useCallback(() => {
    setSideOpen(false)
  }, [])
  const openSidebar = useCallback(() => {
    setSideOpen(true)
  }, [])

  const handleResize = useCallback(() => {
    if (sideOpen && window.innerWidth > 978) closeSidebar()
  }, [closeSidebar, sideOpen])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  return (
    <S.Wrapper>
      <Notification />
      <Navbar openSidebar={openSidebar} />
      <S.WrapperMain>
        <S.WrapperChildren>{children}</S.WrapperChildren>
        <Footer />
      </S.WrapperMain>
      <SideBar menu={menu} isOpen={sideOpen} closeSidebar={closeSidebar} />
    </S.Wrapper>
  )
}

export default Dashboard
