import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import Api from '../../../../services/Api'

import Loading, { ILoadingHandles } from '../../../../components/Loading'

import { IApiEmpreendimentosDisponivelData } from '../../../../services/ApiRequests/EmpreendimentoDisponivel/interfaces'

interface RouteParams {
  id: string
}

const EmpreendimentoDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiEmpreendimentosDisponivelData>()
  const [corBadge, setCorBadge] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.empeendimentoDisponivel
        .getById(params.id)
        .then((res) => {
          setData(res)
          setCorBadge('#c72901')

          if (res.ativo) {
            setCorBadge('#2dc000')
          }
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard')
        })
    }
  }, [handleLoading, history, params.id])

  return isLoading ? (
    <Loading ref={loadRef} />
  ) : (
    <div>
      <CardHeader>
        <h1>Empreendimento {data?.nome} </h1>
        <div>
          <S.BadgeEmpreendimentoStatus color={corBadge}>
            <S.BadgeText>{data?.ativo ? 'Ativo' : 'Inativo'}</S.BadgeText>
          </S.BadgeEmpreendimentoStatus>
        </div>
      </CardHeader>
      {data?.observacoes && <CardBox>{data?.observacoes}</CardBox>}

      <S.EmpreendimentoGroup>
        <S.EmpreendimentoBox>
          {data?.arq_tabela_valores && (
            <S.EmpreendimentoCard>
              <header>Tabela de Valores</header>
              <S.LinkButtonStyled
                variant="primary"
                href={`/api/arquivos/${data.arq_tabela_valores}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaEye />
                <span>Visualizar</span>
              </S.LinkButtonStyled>
            </S.EmpreendimentoCard>
          )}

          {data?.arq_mapa && (
            <S.EmpreendimentoCard>
              <header>Mapa</header>
              <S.LinkButtonStyled
                variant="primary"
                href={`/api/arquivos/${data.arq_mapa}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaEye />
                <span>Visualizar</span>
              </S.LinkButtonStyled>
            </S.EmpreendimentoCard>
          )}

          {data?.arq_imagens && (
            <S.EmpreendimentoCard>
              <header>Imagens</header>
              <S.LinkButtonStyled
                variant="primary"
                href={`/api/arquivos/${data.arq_imagens}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaEye />
                <span>Visualizar</span>
              </S.LinkButtonStyled>
            </S.EmpreendimentoCard>
          )}

          {data?.arq_diversos && (
            <S.EmpreendimentoCard>
              <header>Diversos</header>
              <S.LinkButtonStyled
                variant="primary"
                href={`/api/arquivos/${data.arq_diversos}`}
                target="_blank"
                rel="noreferrer"
              >
                <FaEye />
                <span>Visualizar</span>
              </S.LinkButtonStyled>
            </S.EmpreendimentoCard>
          )}
        </S.EmpreendimentoBox>
      </S.EmpreendimentoGroup>
    </div>
  )
}

export default EmpreendimentoDetalhes
