import React, { memo } from 'react'
import { FaTrash } from 'react-icons/fa'
import { OptionTypeBase } from 'react-select'
import { Button } from '../Button'
import InputFile from '../InputFile'
import InputSelect from '../InputSelect'

type InputsProps = {
  name: string
  label: string
}

export interface IInputFileSelectProps {
  index: number
  id: string
  inputFile: InputsProps
  inputSelect: InputsProps
  optionsSelect: OptionTypeBase[]
  remove(id: string): void
}

// https://www.digitalocean.com/community/tutorials/react-react-select

const InputFileSelect: React.FC<IInputFileSelectProps> = ({
  index,
  id,
  inputFile,
  inputSelect,
  optionsSelect,
  remove
}) => {
  return (
    <>
      <InputFile
        name={`${inputFile.name}[${index}]`}
        label={`${inputFile.label}`}
      />
      <InputSelect
        name={`${inputSelect.name}[${index}]`}
        label={inputSelect.label}
        options={optionsSelect}
        width="30rem"
      />
      <div className="buttonBottom">
        <Button type="button" variant="secondary" onClick={() => remove(id)}>
          <FaTrash />
        </Button>
      </div>
    </>
  )
}

export default memo(InputFileSelect)
