import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { InputCheckbox } from '../../../../components/InputCheckbox'
import { IApiVendaFinanceiro } from '../../../../services/ApiRequests/Venda/interfaces'
import Api from '../../../../services/Api'
import { IFormCallback } from '../../../../forms/interfaces'

interface IVendaFinanceiroFormProps extends IFormCallback {
  financeiro?: IApiVendaFinanceiro
}

const VendaFinanceiroForm: React.FC<IVendaFinanceiroFormProps> = ({
  financeiro,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [finId, setFinId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      await Api.venda.updateFinanceiro({ id: finId, ...data })
      if (callback) callback()
    } catch (err) {}
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!financeiro?.id) {
      return callback?.()
    }

    const { id, ...rest } = financeiro
    setFinId(id)
    formRef.current.setData(rest)
  }, [callback, financeiro])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="honorario" label="Recebeu Honorários" />
        </FormWrapperColumn>
        <FormWrapperColumn>
          <InputCheckbox name="pagoucorretor" label="Pagou Corretor" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default VendaFinanceiroForm
