import { Reducer } from 'redux'
import produce from 'immer'
import { ActionNotificacao, INotificacaoRedux, TypeNotificacao } from './types'

const INITIAL_STATE: INotificacaoRedux = {
  showModal: false
}

export const notificacao: Reducer<INotificacaoRedux, ActionNotificacao> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TypeNotificacao.SHOW_NOTIFICACAO: {
        draft.showModal = true
        break
      }
      case TypeNotificacao.HIDE_NOTIFICACAO: {
        draft.showModal = false
        break
      }
      default: {
        return state
      }
    }
  })
}
