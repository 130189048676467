import { FinanciamentosSetFiltro, IFiltroFinanciamentos, TypeFiltroFinanciamentos } from "./types"

export const setFiltroFinanciamentos = (filtro: IFiltroFinanciamentos): FinanciamentosSetFiltro => ({
  type: TypeFiltroFinanciamentos.SET_FILTRO,
  filtro
})

export const clearFiltroFinanciamentos = () => ({
  type: TypeFiltroFinanciamentos.CLEAR_FILTRO
})

export const modalFinanciamentosFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroFinanciamentos.MODAL_SHOW : TypeFiltroFinanciamentos.MODAL_HIDE
})
