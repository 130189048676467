import React from 'react'
import * as S from './styles'

import DetalhesClienteItem from './DetalhesClienteItem'
import { IApiAluguelPessoas } from '../../../../services/ApiRequests/Aluguel/interfaces'

interface IDetalhesClientes {
  tipo: string
  dado?: IApiAluguelPessoas
}

const DetalhesCliente = ({ tipo, dado }: IDetalhesClientes) => {
  if (!dado?.nome) return <></>
  return (
    <>
      <h4>{tipo}</h4>
      <S.Lista>
        <S.ListaBordaDashed>
          <DetalhesClienteItem dado={dado} />
          {dado.conjuge?.nome && (
            <div className="conjuge">
              <DetalhesClienteItem dado={dado.conjuge} isConjuge={true} />
            </div>
          )}
        </S.ListaBordaDashed>
      </S.Lista>
    </>
  )
}

export default DetalhesCliente
