import { toast } from 'react-toastify'
import { MsgToast } from './MsgToast'

import { ToastContainer } from './styles'

type ToastProps = {
  title: string
  message: string
  type?: 'default' | 'success' | 'warn' | 'error' | 'info'
}

const addToast = ({ title, message, type = 'default' }: ToastProps) => {
  switch (type) {
    case 'success':
      toast.success(<MsgToast title={title} message={message} />)
      break
    case 'warn':
      toast.warn(<MsgToast title={title} message={message} />)
      break
    case 'error':
      toast.error(<MsgToast title={title} message={message} />)
      break
    case 'info':
      toast.info(<MsgToast title={title} message={message} />)
      break
    default:
      toast(<MsgToast title={title} message={message} />)
  }
}

export { ToastContainer, addToast }
