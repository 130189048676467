import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow, FormGroupSeparator } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import Api from '../../../../services/Api'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import { InputTextarea } from '../../../../components/InputTextarea'
import InputFile from '../../../../components/InputFile'
import { Table } from '../../../../components/Table'

export interface EmpreendimentoDisponivelDataForm {
  id: number
  nome: string
  observacoes?: string
  arq_tabela_valores?: string
  arq_mapa?: string
  arq_imagens?: string
  arq_diversos?: string
  ativo: boolean
}

export interface EmpreendimentoDisponivelArquivo {
  id: number
  nome: string
  url?: string
}

interface EmpreendimentoDisponivelFormProps {
  empreendimento?: EmpreendimentoDisponivelDataForm
}

const EmpreendimentoDisponivelForm: React.FC<
  IFormCallback & EmpreendimentoDisponivelFormProps
> = ({ callback, empreendimento }) => {
  const formRef = useRef<FormHandles>(null)
  const [empreendimentoID, setEmpreendimentoID] = useState<string | null>(null)
  const [arquivos, setArquivos] = useState<EmpreendimentoDisponivelArquivo[]>(
    []
  )

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      //console.log(data)
      await validateForm(data)

      const formData = new FormData()

      formData.append(
        'data',
        JSON.stringify({
          id: empreendimento?.id,
          ...data
        })
      )

      if (data.arq_tabela_valores) {
        formData.append(
          `arq_tabela_valores`,
          data.arq_tabela_valores,
          data.arq_tabela_valores.name
        )
      }

      if (data.arq_mapa) {
        formData.append(`arq_mapa`, data.arq_mapa, data.arq_mapa.name)
      }

      if (data.arq_imagens) {
        formData.append(`arq_imagens`, data.arq_imagens, data.arq_imagens.name)
      }

      if (data.arq_diversos) {
        formData.append(
          `arq_diversos`,
          data.arq_diversos,
          data.arq_diversos.name
        )
      }

      if (!empreendimentoID) {
        await Api.empeendimentoDisponivel.save(formData)
      } else {
        await Api.empeendimentoDisponivel.update(formData)
      }

      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    setArquivos([])

    if (empreendimento && empreendimento.id) {
      const {
        arq_tabela_valores,
        arq_mapa,
        arq_imagens,
        arq_diversos,
        ...rest
      } = empreendimento
      setEmpreendimentoID(`${empreendimento.id}`)
      formRef.current.setData({
        ...rest
      })
      const listaArquivos: EmpreendimentoDisponivelArquivo[] = []
      if (arq_tabela_valores)
        listaArquivos.push({
          id: 1,
          nome: 'Tabela de Valores',
          url: arq_tabela_valores
        })

      if (arq_mapa)
        listaArquivos.push({
          id: 2,
          nome: 'Mapa',
          url: arq_mapa
        })

      if (arq_imagens)
        listaArquivos.push({
          id: 3,
          nome: 'Imagens',
          url: arq_imagens
        })

      if (arq_diversos)
        listaArquivos.push({
          id: 4,
          nome: 'Diversos',
          url: arq_diversos
        })

      setArquivos(listaArquivos)
    }
  }, [empreendimento])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <Input name="nome" label="Nome do Empreendimento" />
        <InputCheckbox name="ativo" label="Ativo ?" />
      </FormRow>
      <FormRow>
        <InputTextarea name="observacoes" label="Observações" rows={4} />
      </FormRow>
      {empreendimentoID && arquivos && (
        <Table<EmpreendimentoDisponivelArquivo>
          headers={{
            nome: 'Arquivo',
            url: 'Visualizar'
          }}
          columnSizes={{
            nome: '10rem',
            url: '5rem'
          }}
          items={arquivos}
          customRenderers={{
            url: (obj) => {
              if (obj.url) {
                return (
                  <a
                    href={`/api/arquivos/${obj.url}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clique Aqui
                  </a>
                )
              }
            }
          }}
        />
      )}
      <FormGroupSeparator>
        <span>Carregar Arquivos</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputFile name="arq_tabela_valores" label="Tabela Valores" />
        <InputFile name="arq_mapa" label="Mapa" />
      </FormRow>
      <FormRow>
        <InputFile name="arq_imagens" label="Imagens" />
        <InputFile name="arq_diversos" label="Diversos" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Empreendimento</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default EmpreendimentoDisponivelForm
