import { useCallback, useState } from 'react'

export function useBooleanState(initialState: boolean) {
  const [value, setValue] = useState<boolean>(initialState)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])

  return { value, setTrue, setFalse }
}
