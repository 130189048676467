import {
  alertClose,
  alertLoading,
  alertSucesso,
  alertSucessoButtonOK
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiAluguelData,
  IApiAluguelDetalhes,
  IApiAluguelFinanceiro,
  IApiAluguelVistoria,
  IApiAluguelJuridico,
  IApiAluguelSearchParams,
  IApiAluguelSearchRequest,
  IApiAluguelStatus,
  IApiAluguelDashboardResumo
} from './interfaces'

class AluguelRequests extends BaseRequests {
  constructor() {
    super('aluguel')
  }

  async search(
    filter?: IApiAluguelSearchParams
  ): Promise<IApiAluguelSearchRequest> {
    return await this.sendGet<IApiAluguelSearchRequest>({
      params: { ...filter }
    })
  }

  async getById(id: string | number): Promise<IApiAluguelData> {
    return this.sendGet<IApiAluguelData>({ url: `${id}` })
  }

  async getByIdDetailed(id: string | number): Promise<IApiAluguelDetalhes> {
    return this.sendGet<IApiAluguelDetalhes>({ url: `/detalhes/${id}` })
  }

  async getDashboardResumo(): Promise<IApiAluguelDashboardResumo> {
    return this.sendGet<IApiAluguelDashboardResumo>({ url: `/dashboard` })
  }

  async getPdf(id: string | number): Promise<void> {
    alertLoading('Gerando PDF, aguarde....')

    this.sendGet<any>({
      url: `/pdf/${id}`,
      responseType: 'blob',
    })
      .then((res) => {

        alertSucesso()
        const url = window.URL.createObjectURL(res)
        window.open(url)
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Aluguel...')
    return this.sendPost({ data, multipart: true })
      .then(() => {
        alertSucessoButtonOK('PARABÉNS POR MAIS UMA CONQUISTA', 'RETIRAR A PLACA DE ALUGUEL IMEDIATAMENTE')

      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Aluguel...')
    return this.sendPut({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Aluguel...')
    return this.sendDelete({ param: `${id}` }).then(() => {
      alertSucesso()
    })
  }

  // Juridico
  async getJuridico(aluguelid: number): Promise<IApiAluguelJuridico> {
    return this.sendGet<IApiAluguelJuridico>({ url: `/juridico/${aluguelid}` })
  }

  async updateJuridico(data: any): Promise<void> {
    alertLoading('Atualizando Jurídico...')
    return this.sendPut({ url: '/juridico', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Vistoria
  async getVistoria(aluguelid: number): Promise<IApiAluguelVistoria> {
    return this.sendGet<IApiAluguelVistoria>({
      url: `/vistoria/${aluguelid}`
    })
  }

  async updateVistoria(data: any): Promise<void> {
    alertLoading('Atualizando Vistoria...')
    return this.sendPut({ url: '/vistoria', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Financeiro
  async getFinanceiro(aluguelid: number): Promise<IApiAluguelFinanceiro> {
    return this.sendGet<IApiAluguelFinanceiro>({
      url: `/financeiro/${aluguelid}`
    })
  }

  async updateFinanceiro(data: any): Promise<void> {
    alertLoading('Atualizando Financeiro...')
    return this.sendPut({ url: '/financeiro', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Status
  async getStatus(id: number): Promise<IApiAluguelStatus> {
    return this.sendGet<IApiAluguelStatus>({
      url: `/status/${id}`
    })
  }

  async updateStatus(data: any): Promise<void> {
    alertLoading('Atualizando Status...')
    return this.sendPut({ url: '/status', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
}

export default AluguelRequests
