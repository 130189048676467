import styled, { css } from 'styled-components'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  outline: 0;

  /* overflow: hidden; */
  overflow-x: hidden;
  overflow-y: auto;
`
export const ModalBody = styled.section`
  position: relative;
  margin: 0 auto;
  width: auto;
  height: auto;
  top: 10%;
  -webkit-transition: opacity 100ms ease-in;
  -moz-transition: opacity 100ms ease-in;
  transition: opacity 100ms ease-in;
  z-index: 2;

  ${({ theme }) => css`
    ${theme.media.custom(1500)} {
      width: 60%;
    }

    ${theme.media.desktop} {
      width: 90%;
    }

    ${theme.media.tablet} {
      width: 90%;
    }

    ${theme.media.phone} {
      width: 100%;
    }
  `}
`
export const ModalContainer = styled.div`
  padding: 0.5rem;
  margin: 0 2rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;
`

export const ModalContainerHeader = styled.header`
  padding: 0.75rem 1.1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  line-height: 1.8rem;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 2px 0 -2px 0;
  }
  span {
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 1.5rem;
    }
  }
`

export const ModalContainerMain = styled.main`
  padding: 0.5rem;
`
