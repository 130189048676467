import React from 'react'
import { Route, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '../redux'

import DashBoardRoutes from './dashboard.routes'
import Login from '../pages/Login'
import PrivateRoute from '../components/PrivateRoute'

function Routes() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PrivateRoute path="/dashboard" component={DashBoardRoutes} />
        <Route path="/" exact component={Login} />
      </Provider>
    </BrowserRouter>
  )
}

export default Routes
