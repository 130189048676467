import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaEraser, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import {
  clearFiltroVenda,
  setFiltroVenda
} from '../../../../redux/modules/vendas/actions'
import {
  IFiltroVenda,
  IVendaRedux
} from '../../../../redux/modules/vendas/types'
import { Form, FormRow } from '../../../../styles/form'
import { Button } from '../../../../components/Button'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputDatePiker from '../../../../components/InputDatePiker'
import { FiltroWrapper } from './styles'
import { OptionTypeBase } from '../../../../config/global'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

interface IVendaFiltroProps {
  callback?(): void
}
const VendaFiltro: React.FC<IVendaFiltroProps> = ({ callback }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const venda = useSelector<ReduxStore, IVendaRedux>((state) => state.venda)

  const [corretor, setCorretor] = useState<OptionTypeBase | null>()
  const [comprador, setComprador] = useState<OptionTypeBase | null>()
  const [vendedor, setVendedor] = useState<OptionTypeBase | null>()

  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(clearFiltroVenda())
    if (callback) callback()
  }, [callback, dispatch])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSubmit: SubmitHandler<IFiltroVenda> = async (
    data,
    { reset },
    event
  ) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      dispatch(
        setFiltroVenda({ ...data, corretor, comprador, vendedor, page: 1 })
      )
      if (callback) callback()
    } catch (err) {}
  }

  useEffect(() => {
    setCorretor(venda.pesquisa.filtro.corretor)
    setComprador(venda.pesquisa.filtro?.comprador)
    setVendedor(venda.pesquisa.filtro?.vendedor)
    formRef.current?.setData(venda.pesquisa.filtro)
  }, [venda.pesquisa.filtro])

  return (
    <FiltroWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormRow>
          <InputDatePiker name="dataini" label="Data Inicial Cadastro" />
          <InputDatePiker name="datafim" label="Data Final Cadastro" />
          <InputSelectAsync
            label="Corretor"
            name="corretor"
            onChangeOption={(value) => setCorretor(value)}
            asyncLoadOptions={handleSearchCorretor}
          />
          <InputCheckbox name="naofinalizadas" label="Apenas não Finalizadas" />
        </FormRow>
        <FormRow>
          <InputSelectAsync
            label="Comprador"
            name="comprador"
            onChangeOption={(value) => setComprador(value)}
            asyncLoadOptions={handleSearchCliente}
          />{' '}
          <InputSelectAsync
            label="Vendedor"
            name="vendedor"
            onChangeOption={(value) => setVendedor(value)}
            asyncLoadOptions={handleSearchCliente}
          />
        </FormRow>
        <FormRow buttons>
          <Button type="button" variant="danger" onClick={() => handleClear()}>
            <FaEraser />
            <span>Limpar Filtro</span>
          </Button>

          <Button type="submit">
            <FaSearch />
            <span>Pesquisar</span>
          </Button>
        </FormRow>
      </Form>
    </FiltroWrapper>
  )
}

export default VendaFiltro
