import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow, FormGroupSeparator } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { IApiFinanciamentosData } from '../../../../services/ApiRequests/Financiamentos/interfaces'
import InputSelect from '../../../../components/InputSelect'
import { InputSelectOptionsFinanciamentoStatus } from '../../../../config/InputSelectConfig'

interface FinanciamentosFormProps {
  financiamento?: IApiFinanciamentosData
}

const FinanciamentosForm: React.FC<IFormCallback & FinanciamentosFormProps> = ({
  callback,
  financiamento
}) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }
    if (financiamento && financiamento.id) {
      setId(financiamento.id)

      const {
        cidade,
        corretor,
        cidade_banco,
        cliente,
        ...dados
      } = financiamento
      formRef.current.setData({
        ...dados,
        corretorid: {
          label: corretor?.nome,
          value: corretor?.id
        },
        cidadeid: {
          label: cidade?.nome,
          value: cidade?.id
        },
        cidadebancoid: {
          label: cidade_banco?.nome,
          value: cidade_banco?.id
        },
        clienteid: {
          label: cliente?.nome,
          value: cliente?.id
        }
      })
    } else {
      formRef.current.setData({ status: '1' })
    }
  }, [financiamento, usuario.id, usuario.nome, usuario.tipo])

  const handleSearchCidade = useCallback(async (inputValue?: string) => {
    return Api.municipio.get(inputValue).then((data) => {
      const arrayData = data.map((mun) => ({
        label: `${mun.nome} - ${mun.uf}`,
        value: mun.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      const dados = {
        id,
        ...data
      }

      await validateForm(dados)

      if (!id) {
        await Api.financiamentos.save(dados)
      } else {
        await Api.financiamentos.update(dados)
      }
      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <InputSelectAsync
          label="Cliente"
          name="clienteid"
          asyncLoadOptions={handleSearchCliente}
        />
        <div
          style={{
            ...(usuario.tipo === '5' ? { display: 'none' } : {}),
            width: '30%'
          }}
        >
          <InputSelectAsync
            label="Corretor"
            name="corretorid"
            asyncLoadOptions={handleSearchCorretor}
          />
        </div>
        <InputSelect
          label="Status"
          name="status"
          isClearable={false}
          options={InputSelectOptionsFinanciamentoStatus}
        />
      </FormRow>
      <FormGroupSeparator>
        <span>Dados Gerais</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <Input name="cod_imovel" label="Código do Imóvel" />
        <InputCurrency name="valor_financ" label="Valor Financiamento" />
        <InputCurrency name="valor_entrada" label="Valor de Entrada" />
      </FormRow>
      <FormRow>
        <InputSelectAsync
          name="cidadeid"
          label="Cidade do Imóvel"
          asyncLoadOptions={handleSearchCidade}
        />
        <Input name="situation" label="Situação" />
      </FormRow>
      <FormGroupSeparator>
        <span>Dados Bancários</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <Input name="banco" label="Banco" />
        <Input name="nome_gerente_banco" label="Nome do Gerente do banco" />
        <InputSelectAsync
          name="cidadebancoid"
          label="Cidade do Banco Financiador"
          asyncLoadOptions={handleSearchCidade}
        />
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default FinanciamentosForm
