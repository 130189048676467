import React from 'react'
import { IApiVendaDeposito } from '../../../../services/ApiRequests/Venda/interfaces'
import { Table } from '../../../../components/Table'
import { formatCurrency } from '../../../../utils/currency'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsTipoContaBancaria } from '../../../../config/InputSelectConfig'

interface IDetalhesDepositosProps {
  depositos?: IApiVendaDeposito[]
}

const DetalhesDepositos = ({ depositos }: IDetalhesDepositosProps) => {
  if (!depositos || depositos.length <= 0) return <></>

  return (
    <>
      <h4>Contas para Depósito</h4>
      <section>
        <Table<IApiVendaDeposito>
          headers={{
            titular: 'Titular',
            banco: 'Banco',
            agencia: 'Agência',
            conta: 'Conta',
            tipo: 'Tipo',
            valor: 'Valor'
          }}
          columnSizes={{
            titular: '13rem',
            banco: '4rem',
            agencia: '4rem',
            conta: '5rem',
            tipo: '6rem',
            valor: '6rem'
          }}
          items={depositos}
          customRenderers={{
            valor: ({ valor: total }) => {
              if (total) return formatCurrency(total)
            },
            tipo: (obj) => {
              return getLabelFromOptions(
                InputSelectOptionsTipoContaBancaria,
                obj.tipo
              )
            }
          }}
        />
      </section>
    </>
  )
}

export default DetalhesDepositos
