import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useBooleanState } from '../../hooks/useBooleanState'

import * as S from './styles'

interface ILoadingProps {
  label?: string
  onLoadingChange?: (status: boolean) => void
}

export interface ILoadingHandles {
  showLoading: () => void
  hideLoading: () => void
}

const Loading: React.ForwardRefRenderFunction<
  ILoadingHandles,
  ILoadingProps
> = ({ label, onLoadingChange }: ILoadingProps, ref) => {
  const { value, setTrue, setFalse } = useBooleanState(false)

  useEffect(() => {
    if (onLoadingChange) onLoadingChange(value)
  }, [onLoadingChange, value])

  useImperativeHandle(ref, () => {
    return {
      showLoading: () => setTrue(),
      hideLoading: () => setFalse()
    }
  })

  return !value ? (
    <></>
  ) : (
    <S.LoadingWrapper>
      {!!label && <S.LoadingLabel> {label}</S.LoadingLabel>}
      <S.LoadingIcon />
    </S.LoadingWrapper>
  )
}

export default forwardRef(Loading)
