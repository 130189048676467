import { CaptacaoImovelSetFiltro, IFiltroCaptacaoImovel, TypeFiltroCaptacaoImovel } from "./types"

export const setFiltroCaptacaoImovel = (filtro: IFiltroCaptacaoImovel): CaptacaoImovelSetFiltro => ({
  type: TypeFiltroCaptacaoImovel.SET_FILTRO,
  filtro
})

export const clearFiltroCaptacaoImovel = () => ({
  type: TypeFiltroCaptacaoImovel.CLEAR_FILTRO
})

export const modalCaptacaoImovelFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroCaptacaoImovel.MODAL_SHOW : TypeFiltroCaptacaoImovel.MODAL_HIDE
})
