import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import { IApiCaptacaoImagensData, IApiCaptacaoImagensSearchParams, IApiCaptacaoImagensSearchRequest } from './interfaces'


class CaptacaoImagensRequests extends BaseRequests {
  constructor() {
    super('captacaoimagens')
  }

  async search(
    filter?: IApiCaptacaoImagensSearchParams
  ): Promise<IApiCaptacaoImagensSearchRequest> {
    return await this.sendGet<IApiCaptacaoImagensSearchRequest>({
      params: { ...filter }
    })
  }

  async getById(id: string | number): Promise<IApiCaptacaoImagensData> {
    return this.sendGet<IApiCaptacaoImagensData>({ url: `${id}` })
  }


  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Captação de Imagens...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }


  async update(data: any): Promise<void> {
    alertLoading('Atualizando Captação de Imagens...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async updateStatus(data: any): Promise<void> {
    alertLoading('Atualizando Captação de Imagens...')
    return this.sendPut({ url: '/status', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Captação de Imagens...')
    return this.sendDelete({ param: `${id}` }).then(() => {
      alertSucesso()
    })
  }

}
export default CaptacaoImagensRequests
