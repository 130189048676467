import styled, { css } from 'styled-components'

type BadgeProps = {
  color?: string
}

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  padding: 0.2rem;
  border-radius: 0.5rem;
  border: 1px dashed ${({ theme }) => theme.colors.grey100};

  ${({ theme }) => css`
    ${theme.media.tablet} {
      justify-content: flex-start;
      button {
        margin-left: 1rem;
      }
    }

    ${theme.media.phone} {
      justify-content: flex-start;
      button {
        margin-left: 1rem;
      }
    }
  `};
`

export const BadgeText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
`

export const BadgeVendaStatus = styled.div<BadgeProps>(
  ({ color }) => css`
    background-color: ${color || '#C4c4c4'};
    color: #fff;
    border-radius: 0.5rem;
    padding: 0.1rem 0.5rem;
    font-size: 1rem;

    display: flex;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin: 0 0.2rem;
      font-size: 1.3rem;
    }
  `
)
