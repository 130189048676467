import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaFilter, FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'

import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import CaptacaoImagensPesquisa, {
  ICaptacaoImagensPesquisaHandles
} from '../../components/CaptacaoImagensPesquisa'
import CaptacaoImagensForm from '../../forms/CaptacaoImagensForm'
import { modalCaptacaoImagensFiltroHandler } from '../../../../redux/modules/captacaoimagens/actions'
import { IApiCaptacaoImagensData } from '../../../../services/ApiRequests/CaptacaoImagens/interfaces'

const CaptacaoImagens = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const modalRef = useRef<IModalPropsHandles>(null)
  const pesquisiaRef = useRef<ICaptacaoImagensPesquisaHandles>(null)
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const [
    captacaoImagens,
    setCaptacaoImagens
  ] = useState<IApiCaptacaoImagensData>({} as IApiCaptacaoImagensData)
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const handleDelete = useCallback(async (id?: number) => {
    if (!id) return

    alertConfirma(
      `Confirma a exclusão da Captação de código "${id}"?`,
      async () => {
        await Api.captacaoImagens.delete(id)
        pesquisiaRef.current?.refreshSearch()
      }
    )
  }, [])

  const handleEdit = useCallback(async (id?: number) => {
    if (!id) return

    const dados = await Api.captacaoImagens.getById(id)
    setCaptacaoImagens(dados)
    modalRef.current?.openModal()
  }, [])

  const handleNew = useCallback(async () => {
    setCaptacaoImagens({} as any)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setCaptacaoImagens({} as IApiCaptacaoImagensData)
    pesquisiaRef.current?.refreshSearch()
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Captação de Imagens</h1>
        <Button onClick={() => handleNew()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Captação de Imagens</h4>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(modalCaptacaoImagensFiltroHandler(true))
            }}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <CaptacaoImagensPesquisa
            ref={pesquisiaRef}
            opcoesRender={(it) => (
              <>
                {usuarioRole !== 'CORRETOR' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(it.id)}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it.id)}
                    >
                      <FaTrash />
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          captacaoImagens.id
            ? `Editar Captação de Imagem de código ${captacaoImagens.id}`
            : 'Cadastro de Captação de Images'
        }`}
      >
        <CaptacaoImagensForm
          callback={() => modalRef.current?.closeModal()}
          captacao={captacaoImagens}
        />
      </Modal>
    </>
  )
}

export default CaptacaoImagens
