import React, { useRef } from 'react'
import { FaChevronLeft, FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../../components/Button'
import CaptacaoImovelForm from '../../forms/CaptacaoImovelForm'
import { CardBox, CardHeader } from '../../../../styles/Card'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import ClienteForm from '../../../clientes/forms/Cliente'

const CaptacaoImovelCadastro = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const history = useHistory()

  return (
    <>
      <CardHeader>
        <Button onClick={() => history.goBack()}>
          <FaChevronLeft />
          Voltar
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Cadastro de Captação de Imóvel</h4>
          <Button
            variant="secondary"
            onClick={() => modalRef.current?.openModal()}
          >
            <FaPlus /> <span>Cadastrar Cliente</span>
          </Button>
        </header>
        <div>
          <CaptacaoImovelForm callback={() => history.goBack()} />
        </div>
      </CardBox>
      <Modal ref={modalRef} title={`Cadastro de Cliente`}>
        <ClienteForm callback={() => modalRef.current?.closeModal()} />
      </Modal>
    </>
  )
}

export default CaptacaoImovelCadastro
