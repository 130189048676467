import { combineReducers } from 'redux'

import { venda } from './vendas/reducer'
import { aluguel } from './aluguel/reducer'
import { usuario } from './usuario/reducer'
import { notificacao } from './notificacao/reducer'
import { captacaoImovel } from './captacaoimovel/reducer'
import { metas } from './metas/reducer'
import { captacaoImagens } from './captacaoimagens/reducer'
import { financiamentos } from './financiamentos/reducer'

export const reducers = combineReducers({
  venda,
  aluguel,
  usuario,
  notificacao,
  captacaoImovel,
  metas,
  captacaoImagens,
  financiamentos
  // otherState: otherReducer
})
