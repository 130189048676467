import styled, { css } from 'styled-components'

interface IPaginationItem {
  active?: boolean
  disabled?: boolean
}

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  
  ${({ theme }) => css`
    ${theme.media.tablet} {
      justify-content: center;
      flex-direction: column;
    }

    ${theme.media.phone} {
      justify-content: center;
      flex-direction: column;
      div + div {
        margin-top: 1rem;
      }
    }
  `}
`

export const PaginationList = styled.ul`
  display: flex;
  padding: 0.2rem;
  li {
    list-style: none;
  }

  & > li:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  & > li:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`
export const PaginationItem = styled.li<IPaginationItem>`
  cursor: pointer;
  padding: 0.8rem;
  width: 3rem;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.grey200};
  color: ${({ theme }) => theme.colors.grey};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: #ccc;
      background-color: #eaecea;
    `}

svg {
    font-size: 1.4rem;
  }
`
export const PaginationHeader = styled.div`
  display: flex;
  font-size: 1.2rem;
  span + span {
    margin-left: 1rem;
  }
`
