import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaEraser, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'

import { Form, FormRow } from '../../../../styles/form'
import { Button } from '../../../../components/Button'
import InputDatePiker from '../../../../components/InputDatePiker'
import { FiltroWrapper } from './styles'

import { IFiltroCaptacaoImovel } from '../../../../redux/modules/captacaoimovel/types'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { OptionTypeBase } from '../../../../config/global'
import { ICaptacaoImagensRedux } from '../../../../redux/modules/captacaoimagens/types'
import {
  clearFiltroCaptacaoImagens,
  setFiltroCaptacaoImagens
} from '../../../../redux/modules/captacaoimagens/actions'

interface ICaptacaoImagensFiltroProps {
  callback?(): void
}
const CaptacaoImagensFiltro: React.FC<ICaptacaoImagensFiltroProps> = (
  { callback },
  ref
) => {
  const formRef = useRef<FormHandles>(null)
  const captacaoImagens = useSelector<ReduxStore, ICaptacaoImagensRedux>(
    (state) => state.captacaoImagens
  )
  const [corretor, setCorretor] = useState<OptionTypeBase | null>()

  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(clearFiltroCaptacaoImagens())
    if (callback) callback()
  }, [callback, dispatch])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler<IFiltroCaptacaoImovel> = async (
    data,
    { reset },
    event
  ) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      dispatch(setFiltroCaptacaoImagens({ ...data, corretor }))
      if (callback) callback()
      // reset()
    } catch (err) {}
  }

  useEffect(() => {
    setCorretor(captacaoImagens.pesquisa.filtro.corretor)
    formRef.current?.setData(captacaoImagens.pesquisa.filtro)
  }, [captacaoImagens.pesquisa.filtro])

  return (
    <FiltroWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormRow>
          <InputDatePiker name="dataini" label="Data Inicial Cadastro" />
          <InputDatePiker name="datafim" label="Data Final Cadastro" />
          {/* <InputCheckbox name="naofinalizadas" label="Apenas não Finalizadas" /> */}
          <InputSelectAsync
            label="Corretor"
            name="corretor"
            onChangeOption={(value) => setCorretor(value)}
            asyncLoadOptions={handleSearchCorretor}
          />
        </FormRow>
        <FormRow buttons>
          <Button type="button" variant="danger" onClick={() => handleClear()}>
            <FaEraser />
            <span>Limpar Filtro</span>
          </Button>

          <Button type="submit">
            <FaSearch />
            <span>Pesquisar</span>
          </Button>
        </FormRow>
      </Form>
    </FiltroWrapper>
  )
}

export default CaptacaoImagensFiltro
