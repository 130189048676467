import { IApiAuthLoginResult } from '../../../services/ApiRequests/AuthRequests/interfaces'

export enum TypeUsuario {
  SET_USUARIO = 'SET_USUARIO',
  CLEAR_USUARIO = 'CLEAR_USUARIO'
}

export interface SetUsuario {
  type: TypeUsuario.SET_USUARIO
  payload: IApiAuthLoginResult
}

interface ClearUsuario {
  type: TypeUsuario.CLEAR_USUARIO
}

export interface IUsuarioRedux extends IApiAuthLoginResult {}

export type ActionUsuario = SetUsuario | ClearUsuario
