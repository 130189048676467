import styled, { css } from 'styled-components'

interface IStyledPropsSidebar {
  isOpen: boolean
}

export const Sidebar = styled.div<IStyledPropsSidebar>`
  grid-area: sidebar;
  background: ${({ theme }) => theme.colors.white};
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  overflow-y: auto;
  height: 100%;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: inline !important;
      z-index: 9999 !important;
      left: 0 !important;
      position: absolute;
    `}

  ${({ theme }) =>
    css`
      ${theme.media.desktop} {
        display: none;
        width: 250px;
      }
    `}
`

export const Title = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.fontdark};
  background: ${({ theme }) => theme.colors.white};
  height: 60px;

  i {
    font-size: 1.5rem;
    display: none;
    cursor: pointer;
  }

  div {
    display: flex;
    align-items: center;

    img {
      width: 75px;
      object-fit: contain;
    }

    h1 {
      font-size: ${({ theme }) => theme.font.sizes.medium};
      margin-left: 0.5rem;
    }
  }

  ${({ theme }) =>
    css`
      ${theme.media.desktop} {
        padding: 0.5rem;
        i {
          display: inline;
          margin-right: 1rem;
        }
      }
    `}
`
export const Menu = styled.ul`
  list-style: none;
  padding: 1rem !important;
`

export const Header = styled.li`
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  text-transform: uppercase;
  letter-spacing: 1.5px;
`
