import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

interface IPropsLink {
  hasDropDown?: boolean
  dropped?: boolean
}

interface IPropsDropDown {
  dropped?: boolean
}

const activeClassName = 'nav-item-active'
const hasDropDown = false

export const StyledLink = styled(NavLink).attrs({
  activeClassName
})`
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.sizes.small};
  position: relative;
  display: inline-block;
  padding: 1rem 0.2rem;
  width: 100%;
  text-decoration: none;

  svg {
    color: ${({ theme }) => theme.colors.grey};
    margin-left: 1rem;
    width: 28px;
    margin-right: 15px;
    text-align: center;
    font-size: 13px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.grey};
    border-radius: 0.5rem;
  }

  &.${activeClassName} {
    &,
    svg {
      color: ${({ theme }) => theme.colors.primary} !important;
      font-weight: 600;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      width: 4px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  ${hasDropDown &&
  css`
    background-color: #000;
  `}
`

export const InsideLink = styled.div<IPropsLink>`
  ${({ dropped, hasDropDown }) => css`
    ${hasDropDown &&
    css`
      &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f078';
        font-weight: 900;
        float: right;
        font-size: 12px;
        margin-top: 3px;
        margin-right: 0.8rem;

        ${dropped &&
        css`
          transform: rotate(180deg);
        `}
      }
    `}
  `}
`

export const Item = styled.li``

export const DropDown = styled.ul<IPropsDropDown>`
  display: none;
  transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
  padding-bottom: 0.5rem;

  ${({ dropped }) =>
    dropped &&
    css`
      display: block;
    `}

  a {
    padding-left: 2rem;
  }
`
