import { OptionTypeBase } from "../../../config/global"

export enum TypeFiltroAluguel {
  SET_FILTRO = 'FILTRO_ALUGUEL_SET',
  CLEAR_FILTRO = 'FILTRO_ALUGUEL_CLEAR',
  MODAL_SHOW = 'FILTRO_ALUGUEL_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_ALUGUEL_MODAL_HIDE'
}

export interface IFiltroAluguel {
  dataini?: string
  datafim?: string
  page?: number
  pageSize?: number
  naofinalizadas?: string
  corretor?: OptionTypeBase | null
  proprietario?: OptionTypeBase | null
  inquilino?: OptionTypeBase | null
}

export interface IAluguelRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroAluguel
  }
}

export interface SetFiltro {
  type: TypeFiltroAluguel.SET_FILTRO
  filtro: IFiltroAluguel
}

interface ClearFiltro {
  type: TypeFiltroAluguel.CLEAR_FILTRO
}

interface ModalShow {
  type: TypeFiltroAluguel.MODAL_SHOW
}

interface ModalHide {
  type: TypeFiltroAluguel.MODAL_HIDE
}

export type ActionFiltroAluguel =
  | SetFiltro
  | ClearFiltro
  | ModalShow
  | ModalHide
