import { useEffect, useRef, InputHTMLAttributes } from 'react'

import { useField } from '@unform/core'
import { FormGroupHorizontal, FormCheckBoxWrapper } from '../../styles/form'

interface Props {
  name: string
  label?: string
  value?: string
  hide?: boolean
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & Props

export const InputCheckbox = ({
  name,
  value,
  hide = false,
  label,
  ...rest
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  const defaultChecked = false

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked
      },
      clearValue: (ref) => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        ref.current.checked = defaultChecked
      },
      setValue: (ref, value) => {
        if (value) ref.current.checked = value
      }
    })
  }, [defaultValue, fieldName, registerField, defaultChecked])

  return (
    <FormGroupHorizontal style={hide ? { display: 'none' } : {}}>
      <FormCheckBoxWrapper>
        <input
          type="checkbox"
          defaultChecked={defaultChecked}
          ref={inputRef}
          id={fieldName}
          {...rest}
        />

        <label htmlFor={fieldName} key={fieldName}>
          {label}
        </label>

        {error && <span>{error}</span>}
      </FormCheckBoxWrapper>
    </FormGroupHorizontal>
  )
}
