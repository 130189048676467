import React, { useCallback, useState, useEffect } from 'react'
import { BadgeCaptacaImagensStatus, BadgeText } from './styles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsFinanciamentoStatus } from '../../../../config/InputSelectConfig'
import { IApiFinanciamentosSearchItem } from '../../../../services/ApiRequests/Financiamentos/interfaces'

interface IFinanciamentoStatusProps {
  dados?: Partial<IApiFinanciamentosSearchItem>
}

const FinanciamentoStatus: React.FC<IFinanciamentoStatusProps> = ({
  dados
}) => {
  const [status, setStatus] = useState<string>()
  const [corBadge, setCorBadge] = useState<string>()

  const handleStatus = useCallback((newStatus?: string) => {
    setStatus(
      getLabelFromOptions(InputSelectOptionsFinanciamentoStatus, newStatus)
    )

    setCorBadge('#36617a')

    // Cancelado
    if (newStatus === '2') {
      setCorBadge('#c72901')
    }

    // Concluído
    if (newStatus === '3') {
      setCorBadge('#2dc000')
    }
  }, [])

  useEffect(() => {
    handleStatus(dados?.status)
  }, [dados, handleStatus])

  return (
    <>
      {status && (
        <BadgeCaptacaImagensStatus color={corBadge}>
          <BadgeText>{status}</BadgeText>
        </BadgeCaptacaImagensStatus>
      )}
    </>
  )
}

export default FinanciamentoStatus
