import styled from 'styled-components'

export const CardBox = styled.main`
  padding: 0.5rem;
  margin: 0 2rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;

  header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    line-height: 30px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: space-between;

    h4 {
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 2px 0 -2px 0;
      line-height: 30px;
    }

    button svg {
      padding-right: 0.3rem;
    }

    div {
      display: flex;
      justify-content: space-between;

      & > button {
       margin-left: 5px;
      }
    }
  }
`
export const CardHeader = styled.header`
  border: none;
  position: relative;
  padding: 1rem;
  margin: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
    font-weight: 700;
    display: inline-block;
    font-size: 24px;
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.fontdark};
  }

  button > {
    display: flex;
    align-items: center;

    svg {
      font-size: 1.4rem;

      & + span {
        margin-left: 0.4rem;
      }
    }
  }
`
