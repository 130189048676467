export enum TypeNotificacao {
  SHOW_NOTIFICACAO = 'SHOW_NOTIFICACAO',
  HIDE_NOTIFICACAO = 'HIDE_NOTIFICACAO'
}

export interface ShowNotificacao {
  type: TypeNotificacao.SHOW_NOTIFICACAO
}

export interface HideNotificacao {
  type: TypeNotificacao.HIDE_NOTIFICACAO
}

export interface INotificacaoRedux {
  showModal: boolean
}

export type ActionNotificacao = ShowNotificacao | HideNotificacao
