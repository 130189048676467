import { IFiltroMetas, MetasSetFiltro, TypeFiltroMetas } from "./types"

export const setFiltroMetas = (filtro: IFiltroMetas): MetasSetFiltro => ({
  type: TypeFiltroMetas.SET_FILTRO,
  filtro
})

export const clearFiltroMetas = () => ({
  type: TypeFiltroMetas.CLEAR_FILTRO
})

export const modalMetasFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroMetas.MODAL_SHOW : TypeFiltroMetas.MODAL_HIDE
})
