import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FaCogs } from 'react-icons/fa'
import { BadgeCaptacaImagensStatus, BadgeText, BadgeWrapper } from './styles'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'
import { RoleType } from '../../../../config/Roles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { alertShow } from '../../../../utils/sweetAlert'
import CaptacaoImagensStatusForm from '../../forms/CaptacaoImagensStatusForm'
import { InputSelectOptionsCaptacaoImagemStatus } from '../../../../config/InputSelectConfig'

interface ICaptacaoImagensStatusData {
  id: number
  status?: string
}

interface ICaptacaoImagensStatusProps {
  dados?: ICaptacaoImagensStatusData
  userRole?: RoleType
}

const CaptacaoImagensStatus: React.FC<ICaptacaoImagensStatusProps> = ({
  dados,
  userRole
}) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [data, setData] = useState<ICaptacaoImagensStatusData>()
  const [status, setStatus] = useState<string>()
  const [corBadge, setCorBadge] = useState<string>()

  const handleStatus = useCallback((newStatus?: string) => {
    setStatus(
      getLabelFromOptions(InputSelectOptionsCaptacaoImagemStatus, newStatus)
    )

    setCorBadge('#36617a')

    //Falta Informação
    if (newStatus === '1') {
      setCorBadge('#ff5300')
    }

    //Cancelado
    if (newStatus === '2') {
      setCorBadge('#c72901')
    }

    //Cadastro Concluído
    if (newStatus === '3') {
      setCorBadge('#2dc000')
    }

    //Captando Imagens
    if (newStatus === '4') {
      setCorBadge('#8c008c')
    }
  }, [])

  const handleOnCloseModal = useCallback(
    (update?: ICaptacaoImagensStatusData) => {
      if (update) {
        setData(update)
        handleStatus(update?.status)
      }
    },
    [handleStatus]
  )

  const handleEdit = useCallback(() => {
    if (dados?.id) modalRef.current?.openModal()
  }, [dados?.id])

  useEffect(() => {
    setData(dados)
    handleStatus(dados?.status)
  }, [dados, handleStatus])

  return (
    <>
      <BadgeWrapper>
        {status && (
          <BadgeCaptacaImagensStatus color={corBadge}>
            <BadgeText>{status}</BadgeText>
          </BadgeCaptacaImagensStatus>
        )}
        {userRole !== 'CORRETOR' && (
          <Button onClick={() => handleEdit()} variant="secondary">
            <FaCogs />
          </Button>
        )}
      </BadgeWrapper>

      <Modal ref={modalRef} title={`Alteração de Status da Captacao de Imagem`}>
        <CaptacaoImagensStatusForm
          callback={(update) => {
            modalRef.current?.closeModal()
            handleOnCloseModal(update)
          }}
          status={data}
        />
      </Modal>
    </>
  )
}

export default CaptacaoImagensStatus
