import { format, parseISO } from 'date-fns'

export const dateFormat = 'dd/MM/yyyy'
export const dateTimeFormat = 'dd/MM/yyyy HH:mm'

export const formatDateTime = (
  value: string | Date,
  mask: string = dateTimeFormat
): string => {
  return format(typeof value === 'string' ? parseISO(value) : value, mask)
}

export function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = max - 3
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}