import React from 'react'
import { IApiVendaPessoas } from '../../../../services/ApiRequests/Venda/interfaces'
import * as S from './styles'

import DetalhesClienteItem from './DetalhesClienteItem'

interface IDetalhesClientes {
  tipo: 'Compradores' | 'Vendedores'
  dados?: IApiVendaPessoas[]
}

const DetalhesCliente = ({ tipo, dados }: IDetalhesClientes) => {
  if (!dados || dados.length <= 0) return <></>

  return (
    <>
      <h4>{tipo}</h4>
      {dados.map((pes, i) => (
        <S.Lista key={i}>
          <S.ListaBordaDashed>
            <DetalhesClienteItem dado={pes} />
            {pes.conjuge?.nome && (
              <div className="conjuge">
                <DetalhesClienteItem dado={pes.conjuge} isConjuge={true} />
              </div>
            )}
          </S.ListaBordaDashed>
        </S.Lista>
      ))}
    </>
  )
}

export default DetalhesCliente
