import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import { IApiFinanciamentosData, IApiFinanciamentosSearchParams, IApiFinanciamentosSearchRequest } from './interfaces'

class FinanciamentosRequests extends BaseRequests {
  constructor() {
    super('financiamentos')
  }

  async search(
    filter?: IApiFinanciamentosSearchParams
  ): Promise<IApiFinanciamentosSearchRequest> {
    return this.sendGet<IApiFinanciamentosSearchRequest>({
      params: { ...filter }
    })
  }

  async getById(id: string | number): Promise<IApiFinanciamentosData> {
    return this.sendGet<IApiFinanciamentosData>({ url: `${id}` })
  }


  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Financiamento...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }


  async update(data: any): Promise<void> {
    alertLoading('Atualizando Financiamento...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
  async delete(id: number): Promise<void> {
    alertLoading('Apagando Financiamento...')
    return this.sendDelete({ param: `${id}` }).then(() => {
      alertSucesso()
    })
  }

}
export default FinanciamentosRequests
