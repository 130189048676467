import * as S from './styles'
const Footer = () => {
  return (
    <S.Footer>
      <div className="footer-left">
        Copyright © 2021 Desenvolvido por Haroldo{' '}
      </div>
    </S.Footer>
  )
}

export default Footer
