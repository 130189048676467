type MsgProps = {
  title: string
  message: string
  closeToast?: () => void
}

const MsgToast: React.FC<MsgProps> = ({ title, message, closeToast }) => (
  <div>
    <h3>{title}</h3>
    <span>{message}</span>
  </div>
)

export { MsgToast }
