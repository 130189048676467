import { Reducer } from 'redux'
import produce from 'immer'
import { startOfMonth } from 'date-fns'
import { ActionFiltroFinanciamentos, IFinanciamentosRedux, TypeFiltroFinanciamentos } from './types'

const INITIAL_STATE: IFinanciamentosRedux = {
  pesquisa: {
    modalShow: false,
    filtro: {
      dataini: startOfMonth(new Date()).toISOString(),
      datafim: new Date().toISOString(),
      page: 1,
      pageSize: 10
    }
  }
}

export const financiamentos: Reducer<IFinanciamentosRedux, ActionFiltroFinanciamentos> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TypeFiltroFinanciamentos.SET_FILTRO: {
        draft.pesquisa.filtro = { ...draft.pesquisa.filtro, ...action.filtro }
        break
      }
      case TypeFiltroFinanciamentos.CLEAR_FILTRO: {
        draft.pesquisa.filtro = INITIAL_STATE.pesquisa.filtro
        break
      }
      case TypeFiltroFinanciamentos.MODAL_SHOW: {
        draft.pesquisa.modalShow = true
        break
      }
      case TypeFiltroFinanciamentos.MODAL_HIDE: {
        draft.pesquisa.modalShow = false
        break
      }
      default: {
        return state
      }
    }
  })
}
