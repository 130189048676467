import styled, { css } from 'styled-components'

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'
  | 'warning'
  | 'danger'

type ButtonProps = {
  variant: ButtonType
  hide?: boolean
}

export const ButtonStyled = styled.button<ButtonProps>`
  background: ${({ theme, variant }) => theme.colors[variant]};
  border-radius: 0.5rem;
  border: none;
  padding: 0 1rem;
  height: 3rem;
  color: ${({ theme, variant }) =>
    variant === 'info' ? '#4d4d4d' : theme.colors.white};
  transition: opacity 0.2s;
  cursor: pointer;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  svg {
    font-size: 1.3rem;

    & + span {
      margin-left: 0.5rem;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`
