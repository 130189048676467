import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer as Container } from 'react-toastify'

export const ToastContainer = styled(Container)`
  .Toastify__toast--default {
    background: #fff;
    color: #737373;
  }
  .Toastify__toast--info {
    background: #3498db;
  }
  .Toastify__toast--success {
    background: #07bc0c;
  }
  .Toastify__toast--warning {
    background: #f1c40f;
  }
  .Toastify__toast--error {
    background: #e74c3c;
  }
`
