import styled, { css } from 'styled-components'

export const WrapperVendaDetalhes = styled.div`
  h4 {
    padding: 0.2rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.primary};
    background-color: #f9f9f9;
  }

  h3 {
    margin-top: 1rem;
    padding: 0.2rem;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.fontdark};
    background-color: #f2f5ff;
  }

  & h4:not(:first-child) {
    margin-top: 1.3rem;
  }

  hr {
    border-top: 1px dashed #ccc;
    margin: 2rem 0.3rem 1rem 0.3rem;
    flex: 1;
  }

  section {
    display: flex;
  }

  ${({ theme }) => css`
    ${theme.media.desktop} {
      section {
        flex-wrap: wrap;
      }
    }

    ${theme.media.tablet} {
      section {
        flex-direction: column;
      }
    }

    ${theme.media.phone} {
      section {
        flex-direction: column;
      }
    }
  `}
`

export const LabelDetalhe = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  div {
    color: #1b1b1b;
    font-size: 1.1rem;
    font-weight: bold;
  }
  span {
    margin-top: 0.3rem;
  }
`

export const Lista = styled.div`
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;

  div {
    padding-top: 0.5rem;
  }
  & > .conjuge {
    background-color: green !important;
  }
`

export const ListaBordaDashed = styled.div`
  padding: 0;
  margin-bottom: 0.3rem;
  border: 1px dashed #ccc;
  border-radius: 0.5rem;

  .conjuge {
    margin: 0.5rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.grey300};
  }
`
