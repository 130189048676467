import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import {
  InputSelectOptionsMeses,
  InputSelectOptionsAnos
} from '../../../../config/InputSelectConfig'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import InputSelect from '../../../../components/InputSelect'
import { IApiMetaData } from '../../../../services/ApiRequests/Metas/interfaces'

interface MetaFormProps {
  meta?: IApiMetaData
}

const MetaForm: React.FC<IFormCallback & MetaFormProps> = ({
  callback,
  meta
}) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    if (meta && meta.id) {
      setId(meta.id)
      formRef.current.setData({
        ...meta,
        corretorid: {
          label: meta.corretor?.nome,
          value: meta.corretor?.id
        }
      })
    }
  }, [meta, usuario.id, usuario.nome, usuario.tipo])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      const dados = {
        id,
        ...data
      }

      await validateForm(dados)

      if (!id) {
        await Api.metas.save(dados)
      } else {
        await Api.metas.update(dados)
      }
      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <div
          style={{
            ...(usuario.tipo === '5' ? { display: 'none' } : {}),
            width: '30%'
          }}
        >
          <InputSelectAsync
            label="Corretor"
            name="corretorid"
            asyncLoadOptions={handleSearchCorretor}
          />
        </div>
        <InputSelect label="Mes" name="mes" options={InputSelectOptionsMeses} />
        <InputSelect label="Ano" name="ano" options={InputSelectOptionsAnos} />
        <InputCurrency name="valor_meta" label="Valor Meta" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Meta</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default MetaForm
