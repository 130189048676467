import React from 'react'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'

import LabelDetalhes from './LabelDetalhes'
import { IApiAluguelJuridico } from '../../../../services/ApiRequests/Aluguel/interfaces'

interface IDetalhesJuridicoProps {
  juridico: IApiAluguelJuridico
}

const DetalhesJuridico = ({ juridico }: IDetalhesJuridicoProps) => {
  return (
    <>
      <section>
        <LabelDetalhes
          label="Documento do Inquilino"
          text={`${juridico.docinquilino ? 'Sim' : 'Não'}`}
          obs={juridico?.docinquilinoobs}
        />
        <LabelDetalhes
          label="Documento do Fiador"
          text={`${juridico.docfiador ? 'Sim' : 'Não'}`}
          obs={juridico?.docfiadorobs}
        />
      </section>

      <section>
        <LabelDetalhes
          label="Assinatura de Contrato"
          text={`${
            juridico.assinaturacontrato ? 'Sim' : 'Não'
          } ${getLabelFromOptions(
            InputSelectOptionsAssinaturaTipo,
            juridico.assinaturacontratotipo
          )}`}
        />
        <LabelDetalhes
          label="Notificou Marketing para remover anúncio"
          text={`${juridico.notificarmarketing ? 'Sim' : 'Não'}`}
          obs={juridico?.notificarmarketingobs}
        />
      </section>
      <section>
        <LabelDetalhes
          label="Digitalizar Documentos em uma Pasta"
          text={`${juridico.digitalizoudocs ? 'Sim' : 'Não'}`}
          obs={juridico?.digitalizoudocsobs}
        />
      </section>
    </>
  )
}

export default DetalhesJuridico
