import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { InputCheckbox } from '../../../../components/InputCheckbox'

import { InputTextarea } from '../../../../components/InputTextarea'
import Api from '../../../../services/Api'
import { IApiAluguelStatus } from '../../../../services/ApiRequests/Aluguel/interfaces'

interface IAluguelStatusFormProps {
  status?: IApiAluguelStatus
  callback(update?: IApiAluguelStatus): void
}

const AluguelStatusForm: React.FC<IAluguelStatusFormProps> = ({
  status,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [statusId, setStatusId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      await Api.aluguel.updateStatus({ id: statusId, ...data })
      if (callback) callback({ id: statusId, ...data })
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!status?.id) {
      return callback?.()
    }

    const { id, ...rest } = status
    setStatusId(id)
    formRef.current.setData(rest)
  }, [callback, status])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow
        grid={true}
        gridColumns={{ default: '3', desktop: '3', tablet: '2', phone: '1' }}
      >
        <InputCheckbox name="faltadocumentos" label="Faltando Documentos" />
        <InputCheckbox
          name="assinaturacontrato"
          label="Assinatura de contrato"
        />
        <InputCheckbox
          name="aguardandotransfaguaenergia"
          label="Aguardando transferir Água e energia"
        />
        <InputCheckbox
          name="aguardandopagamento"
          label="Aguardando pagamento de aluguel"
        />
        <InputCheckbox name="retirarchaves" label="Retirar chaves" />
        <InputCheckbox name="finalizado" label="Finalizado" />
        <InputCheckbox name="cancelado" label="Contrato Cancelado" />
      </FormRow>
      <FormRow>
        <InputTextarea name="comentario" label="Comentários" rows={6} />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default AluguelStatusForm
