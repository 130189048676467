import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaEraser, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'

import { Form, FormRow } from '../../../../styles/form'
import { Button } from '../../../../components/Button'
import InputDatePiker from '../../../../components/InputDatePiker'
import { FiltroWrapper } from './styles'
import {
  clearFiltroCaptacaoImovel,
  setFiltroCaptacaoImovel
} from '../../../../redux/modules/captacaoimovel/actions'
import {
  ICaptacaoImovelRedux,
  IFiltroCaptacaoImovel
} from '../../../../redux/modules/captacaoimovel/types'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { OptionTypeBase } from '../../../../config/global'

interface ICaptacaoImovelFiltroProps {
  callback?(): void
}
const CaptacaoImovelFiltro: React.FC<ICaptacaoImovelFiltroProps> = (
  { callback },
  ref
) => {
  const formRef = useRef<FormHandles>(null)
  const captacaoImovel = useSelector<ReduxStore, ICaptacaoImovelRedux>(
    (state) => state.captacaoImovel
  )
  const [corretor, setCorretor] = useState<OptionTypeBase | null>()

  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(clearFiltroCaptacaoImovel())
    if (callback) callback()
  }, [callback, dispatch])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler<IFiltroCaptacaoImovel> = async (
    data,
    { reset },
    event
  ) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      dispatch(setFiltroCaptacaoImovel({ ...data, corretor }))
      if (callback) callback()
      // reset()
    } catch (err) {}
  }

  useEffect(() => {
    setCorretor(captacaoImovel.pesquisa.filtro.corretor)
    formRef.current?.setData(captacaoImovel.pesquisa.filtro)
  }, [captacaoImovel.pesquisa.filtro])

  return (
    <FiltroWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormRow>
          <InputDatePiker name="dataini" label="Data Inicial Cadastro" />
          <InputDatePiker name="datafim" label="Data Final Cadastro" />
          {/* <InputCheckbox name="naofinalizadas" label="Apenas não Finalizadas" /> */}
          <InputSelectAsync
            label="Corretor"
            name="corretor"
            onChangeOption={(value) => setCorretor(value)}
            asyncLoadOptions={handleSearchCorretor}
          />
        </FormRow>
        <FormRow buttons>
          <Button type="button" variant="danger" onClick={() => handleClear()}>
            <FaEraser />
            <span>Limpar Filtro</span>
          </Button>

          <Button type="submit">
            <FaSearch />
            <span>Pesquisar</span>
          </Button>
        </FormRow>
      </Form>
    </FiltroWrapper>
  )
}

export default CaptacaoImovelFiltro
