export function isArrayWithLength(arr: any) {
  return Array.isArray(arr) && arr.length
}

export function intersection(
  first: (string | number)[],
  second: (string | number)[]
) {
  // return first.filter((value) => second.includes(value))
  return first.filter((value) => second.indexOf(value) !== -1)
}
