import styled, { css } from 'styled-components'

interface ICardBoxProps {
  boxcolor?: string
}

export const DashWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem;

  ${({ theme }) => css`
    ${theme.media.tablet} {
      grid-template-columns: repeat(1, 1fr);
    }

    ${theme.media.phone} {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`
export const DashGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  h4 {
    padding-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
`

export const DashBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem;
`

export const DashCard = styled.main<ICardBoxProps>`
  padding: 1rem;
  margin: 0 0.5rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #eaeaea;
  border-radius: 3px;

  margin-bottom: 1rem;

  header {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    color: ${({ theme }) => theme.colors.secondary};
  }
  section {
    padding-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  ${({ boxcolor }) =>
    boxcolor &&
    css`
      background-color: ${boxcolor};
      /* color: #f0f0f0 !important; */
      header {
        /* color: #f0f0f0 !important; */
      }
    `}
`
