import {
  alertClose,
  alertLoading,
  alertSucesso,
  alertSucessoButtonOK
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiVendaDashboardResumo,
  IApiVendaData,
  IApiVendaDetalhes,
  IApiVendaFinanceiro,
  IApiVendaFinanciamento,
  IApiVendaJuridico,
  IApiVendaSearch,
  IApiVendaSearchParams,
  IApiVendaSearchRequest,
  IApiVendaStatus
} from './interfaces'

class VendaRequests extends BaseRequests {
  constructor() {
    super('vendas')
  }

  async search(filter?: IApiVendaSearchParams): Promise<IApiVendaSearch> {
    const results = await this.sendGet<IApiVendaSearchRequest>({
      params: { ...filter }
    })

    const listaTratada = results.list.map(
      ({ id, valorvenda, prazo, status, ...data }) => {
        const compradores = data.compradores
          .reduce<string[]>((acc, com) => {
            return [...acc, com.nome]
          }, [])
          .join(',')

        const vendedores = data.vendedores
          .reduce<string[]>((acc, com) => {
            return [...acc, com.nome]
          }, [])
          .join(',')

        return {
          id,
          valorvenda,
          compradores,
          vendedores,
          corretor: data.corretor.nome,
          prazo,
          cadastro: data.created_at,
          status
        }
      }
    )

    return { ...results, list: listaTratada }
  }

  async getDashboardResumo(): Promise<IApiVendaDashboardResumo> {
    return this.sendGet<IApiVendaDashboardResumo>({ url: `/dashboard` })
  }

  async getById(id: string | number): Promise<IApiVendaData> {
    return this.sendGet<IApiVendaData>({ url: `${id}` })
  }

  async getByIdDetailed(id: string | number): Promise<IApiVendaDetalhes> {
    return this.sendGet<IApiVendaDetalhes>({ url: `/detalhes/${id}` })
  }

  async getPdf(id: string | number): Promise<void> {
    alertLoading('Gerando PDF, aguarde....')

    this.sendGet<any>({
      url: `/pdf/${id}`,
      responseType: 'blob'
    })
      .then((res) => {
        alertSucesso()
        const url = window.URL.createObjectURL(res)
        window.open(url)
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Venda...')
    return this.sendPost({ data, multipart: true })
      .then(() => {
        alertSucessoButtonOK('PARABÉNS POR MAIS UMA CONQUISTA', 'RETIRAR A PLACA DE VENDA IMEDIATAMENTE')
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Venda...')
    return this.sendPut({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Venda...')
    return this.sendDelete({ param: `${id}` }).then(() => {
      alertSucesso()
    })
  }

  // Juridico
  async getJuridico(vendaid: number): Promise<IApiVendaJuridico> {
    return this.sendGet<IApiVendaJuridico>({ url: `/juridico/${vendaid}` })
  }

  async updateJuridico(data: any): Promise<void> {
    alertLoading('Atualizando Jurídico...')
    return this.sendPut({ url: '/juridico', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Financiamento
  async getFinanciamento(vendaid: number): Promise<IApiVendaFinanciamento> {
    return this.sendGet<IApiVendaFinanciamento>({
      url: `/financiamento/${vendaid}`
    })
  }

  async updateFinanciamento(data: any): Promise<void> {
    alertLoading('Atualizando Financiamento...')
    return this.sendPut({ url: '/financiamento', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Financeiro
  async getFinanceiro(vendaid: number): Promise<IApiVendaFinanceiro> {
    return this.sendGet<IApiVendaFinanceiro>({
      url: `/financeiro/${vendaid}`
    })
  }

  async updateFinanceiro(data: any): Promise<void> {
    alertLoading('Atualizando Financeiro...')
    return this.sendPut({ url: '/financeiro', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  // Status
  async getStatus(id: number): Promise<IApiVendaStatus> {
    return this.sendGet<IApiVendaStatus>({
      url: `/status/${id}`
    })
  }

  async updateStatus(data: any): Promise<void> {
    alertLoading('Atualizando Status...')
    return this.sendPut({ url: '/status', data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
}

export default VendaRequests
