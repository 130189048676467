import React, { useCallback, useEffect, useState } from 'react'
import { FaCheckCircle, FaClipboardCheck } from 'react-icons/fa'
import { IApiNotificacaoData } from '../../../../services/ApiRequests/NotificacaoRequests/interfaces'
import { Button } from '../../../../components/Button'
import Api from '../../../../services/Api'

import * as S from './styles'

interface INotificacaoItemProps {
  dados: IApiNotificacaoData
}

const NotificacaoItem: React.FC<INotificacaoItemProps> = ({ dados }) => {
  const [msg, setMsg] = useState<IApiNotificacaoData>({} as IApiNotificacaoData)

  useEffect(() => {
    setMsg(dados)
  }, [dados])

  const handleMarcarLido = useCallback(
    (id: number) => {
      Api.notificacao.update({ id }).then(() => {
        setMsg({ ...msg, lido: true })
      })
    },
    [msg]
  )

  if (!msg?.id) return <></>

  return (
    <S.NotificacaoItemWrapper>
      <S.NotificacaoItemMensagem>
        {msg.lido && <FaCheckCircle />} {msg.mensagem}
      </S.NotificacaoItemMensagem>

      {!msg.lido && (
        <Button variant="secondary" onClick={() => handleMarcarLido(msg.id)}>
          <FaClipboardCheck />
        </Button>
      )}
    </S.NotificacaoItemWrapper>
  )
}

export default NotificacaoItem
