import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

// const schemaUpdate = Yup.object({
//   id: Yup.number().required(),
//   valor_meta: Yup
//     .number()
//     .optional()
//     .moreThan(0, 'Informe um valor maior que zero.'),
//   mes: Yup.number().optional(),
//   ano: Yup.number().optional(),
// })

const schemaCreate = Yup.object({
  corretorid: Yup.string().required('Informe o corretor.'),
  valor_meta: Yup
    .number()
    .required('Informe o Valor para a meta')
    .moreThan(0, 'Informe um valor maior que zero.'),
  mes: Yup.number().required(),
  ano: Yup.number().required(),
})

export const validateForm = async (data: any) => {
  await schemaCreate.validate(data, {
    abortEarly: false
  })
}
