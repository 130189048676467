import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'

import { FiDelete, FiEdit } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { InputSelectOptionsPerfis } from '../../../../config/InputSelectConfig'
import { getRole, RoleType } from '../../../../config/Roles'
import UsuarioForm, { UsuarioDataForm } from '../../form/Usuario'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { IApiUsuarioData } from '../../../../services/ApiRequests/UsuarioRequests/interfaces'

import { CardBox, CardHeader } from '../../../../styles/Card'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { alertConfirma } from '../../../../utils/sweetAlert'

interface UsuarioItem extends IApiUsuarioData {
  opcoes: string
}

const Usuarios = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [usuario, setUsuario] = useState<UsuarioDataForm>({} as UsuarioDataForm)
  const [dados, setDados] = useState<IApiUsuarioData[]>([])

  const usuarioLogado = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  useEffect(() => {
    setUsuarioRole(getRole(usuarioLogado.usuario.tipo))
  }, [usuarioLogado.usuario.tipo])

  const handleLoad = useCallback(() => {
    Api.usuario.get().then((data) => {
      setDados(data)
    })
  }, [])

  const handleEdit = useCallback(async ({ id }: UsuarioDataForm) => {
    const res = await Api.usuario.getById(id)
    setUsuario(res)
    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, nome }: UsuarioDataForm) => {
      alertConfirma(`Confirma a exclusão do usuário "${nome}"?`, async () => {
        await Api.usuario.delete(id)
        handleLoad()
      })
    },
    [handleLoad]
  )

  const handleOnCloseModal = useCallback(() => {
    setUsuario({} as UsuarioDataForm)
    handleLoad()
  }, [handleLoad])

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <CardHeader>
        <h1>Usuários</h1>
        {usuarioRole === 'ADMIN' && (
          <Button onClick={() => modalRef.current?.openModal()}>
            <FaPlus /> <span>Adicionar</span>
          </Button>
        )}
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Usuários</h4>
        </header>
        <div>
          <Table<UsuarioItem>
            headers={{
              nome: 'Nome',
              email: 'E-mail',
              ativo: 'Ativo',
              tipo: 'Perfil',
              opcoes: ''
            }}
            columnSizes={{
              nome: '5rem',
              email: '4rem',
              ativo: '1rem',
              tipo: '2rem',
              opcoes: '2rem'
            }}
            items={dados}
            customRenderers={{
              tipo: (obj) => {
                return getLabelFromOptions(InputSelectOptionsPerfis, obj.tipo)
              },
              ativo: ({ ativo }) => (ativo === 'S' ? 'Sim' : 'Não'),
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(it as UsuarioDataForm)}
                  >
                    <FiEdit />
                  </Button>
                  {usuarioRole === 'ADMIN' && (
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it as UsuarioDataForm)}
                    >
                      <FiDelete />
                    </Button>
                  )}
                </TableButtonWrapper>
              )
            }}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        title={`${usuario.id ? 'Alteração' : 'Cadastro'} de Usuário`}
        onClose={handleOnCloseModal}
      >
        <UsuarioForm
          callback={() => modalRef.current?.closeModal()}
          usuario={usuario}
          role={usuarioRole}
        />
      </Modal>
    </>
  )
}

export default Usuarios
