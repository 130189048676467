import React, { useEffect, useRef, InputHTMLAttributes } from 'react'
import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'
import { InputFileWrapper } from './styles'

// import * as S from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
}

const InputFile: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <FormGroup width={rest.width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <InputFileWrapper>
        <input
          type="file"
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          className={error ? 'has-error' : ''}
          onFocus={clearError}
          {...rest}
        />
      </InputFileWrapper>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default InputFile
