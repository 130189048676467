import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaFilter, FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'

import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import { IApiMetaData } from '../../../../services/ApiRequests/Metas/interfaces'
import MetasListagem, {
  IMetaListagemHandles
} from '../../components/MetasListagem'
import { modalMetasFiltroHandler } from '../../../../redux/modules/metas/actions'
import MetaForm from '../../forms/MetaForm'

const Metas = () => {
  const dispatch = useDispatch()

  const modalRef = useRef<IModalPropsHandles>(null)
  const pesquisiaRef = useRef<IMetaListagemHandles>(null)
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const [meta, setMeta] = useState<IApiMetaData>({} as IApiMetaData)
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const handleDelete = useCallback(async (id?: number) => {
    if (!id) return

    alertConfirma(`Confirma a exclusão da Meta?`, async () => {
      await Api.metas.delete(id)
      pesquisiaRef.current?.refreshSearch()
    })
  }, [])

  const handleEdit = useCallback(async (id?: number) => {
    if (!id) return

    const dados = await Api.metas.getById(id)
    setMeta(dados)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setMeta({} as IApiMetaData)
    pesquisiaRef.current?.refreshSearch()
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Metas</h1>
        <Button onClick={() => modalRef.current?.openModal()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Metas </h4>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(modalMetasFiltroHandler(true))
            }}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <MetasListagem
            ref={pesquisiaRef}
            opcoesRender={(it) => (
              <>
                {usuarioRole !== 'CORRETOR' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(it.id)}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it.id)}
                    >
                      <FaTrash />
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          meta.id
            ? `Editar Meta: ${
                meta.corretor?.nome
              } - ${meta.mes?.toString().padStart(2, '0')}/${meta.ano}`
            : 'Cadastrar Meta'
        }`}
      >
        <MetaForm callback={() => modalRef.current?.closeModal()} meta={meta} />
      </Modal>
    </>
  )
}

export default Metas
