import React from 'react'
import { IApiVendaPessoas } from '../../../../services/ApiRequests/Venda/interfaces'
import LabelDetalhes from './LabelDetalhes'
import * as S from './styles'

import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsEstadoCivil } from '../../../../config/InputSelectConfig'
import DetalhesArquivos from './DetalhesArquivos'

interface IDetalhesClienteItem {
  isConjuge?: boolean
  dado?: IApiVendaPessoas
}

const DetalhesClienteItem = ({ isConjuge, dado }: IDetalhesClienteItem) => {
  if (!dado || !dado.nome) return <></>

  return (
    <S.Lista>
      <section>
        <LabelDetalhes
          label={`Nome ${isConjuge ? ' do Conjuge' : ''}`}
          text={dado?.nome}
        />
        <LabelDetalhes label="Documento" text={dado?.documento} />
        <LabelDetalhes label="RG/I.E." text={dado?.rg} />
      </section>
      <section>
        <LabelDetalhes label="Logradouro" text={dado?.logradouro} />
        <LabelDetalhes label="Bairro" text={dado?.bairro} />
        <LabelDetalhes label="Número" text={dado?.numero} />
        <LabelDetalhes label="Cep" text={dado?.cep} />
        <LabelDetalhes
          label="Cidade"
          text={`${dado?.cidade.nome} - ${dado?.cidade.uf}`}
        />
      </section>
      <section>
        <LabelDetalhes label="Telefone" text={dado?.telefone} />
        <LabelDetalhes label="Celular" text={dado?.celular} />
        <LabelDetalhes
          label="Estado Cívil"
          text={getLabelFromOptions(
            InputSelectOptionsEstadoCivil,
            dado?.estadocivil
          )}
        />
        <LabelDetalhes label="E-mail" text={dado?.email} />
        <LabelDetalhes label="Profissão" text={dado?.profissao} />
      </section>
      <DetalhesArquivos anexos={dado?.arquivos} exibeTitulo={false} />
    </S.Lista>
  )
}

export default DetalhesClienteItem
