import { OptionTypeBase } from "../../../config/global"


export enum TypeFiltroFinanciamentos {
  SET_FILTRO = 'FILTRO_FINANCIAMENTOS_SET',
  CLEAR_FILTRO = 'FILTRO_FINANCIAMENTOS_CLEAR',
  MODAL_SHOW = 'FILTRO_FINANCIAMENTOS_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_FINANCIAMENTOS_MODAL_HIDE'
}

export interface IFiltroFinanciamentos {
  dataini?: string
  datafim?: string
  status?: string
  corretor?: OptionTypeBase | null
  cliente?: OptionTypeBase | null
  page?: number
  pageSize?: number
}

export interface IFinanciamentosRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroFinanciamentos
  }
}

export interface FinanciamentosSetFiltro {
  type: TypeFiltroFinanciamentos.SET_FILTRO
  filtro: IFiltroFinanciamentos
}

interface FinanciamentosClearFiltro {
  type: TypeFiltroFinanciamentos.CLEAR_FILTRO
}

interface FinanciamentosModalShow {
  type: TypeFiltroFinanciamentos.MODAL_SHOW
}

interface FinanciamentosModalHide {
  type: TypeFiltroFinanciamentos.MODAL_HIDE
}

export type ActionFiltroFinanciamentos =
  | FinanciamentosSetFiltro
  | FinanciamentosClearFiltro
  | FinanciamentosModalShow
  | FinanciamentosModalHide
