import 'react-toastify/dist/ReactToastify.css'

import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility !important;  
  }

  ${({ theme }) => css`
    html {
      font-size: 65%;
      scroll-behavior: smooth;
    }

    body {
      font-family: ${theme.font.family};
      background-color: ${theme.colors.mainBg};
      color: ${theme.colors.font};
      font-size: ${theme.font.sizes.small};

      input:not([type='checkbox']),
      textarea {
        width: 100%;
        padding: 0.5rem 1rem;
        line-height: 2rem;
        font-size: 1.3rem;
        font-family: inherit;
        border-radius: 6px;
        -webkit-appearance: none;
        border: 1px solid ${({ theme }) => theme.colors.light};
        background: fff;
        transition: border 0.3s ease;

        &::placeholder {
          color: #cbd1dc;
          font: 1rem;
        }
        &:focus {
          outline: none;
          border-color: #2684ff;
        }
      }

      input[readonly] {
        background-color: ${({ theme }) => theme.colors.grey200};
      }
    }

    body html #root {
      height: 100vh;
    }

    .swal2-styled,
    .swal2-html-container {
      font-size: 1.3rem !important;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 15px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${theme.colors.grey300};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.grey};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.primary};
    }
  `}
`

export default GlobalStyle
