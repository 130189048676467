import { useCallback, useEffect, useState } from 'react'
import * as S from './styles'

import { useRef } from 'react'
// import { WebSocket } from '../../../../services/WebSocket'
// import { addToast } from '../../../../components/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { hideNotificacao } from '../../../../redux/modules/notificacao/actions'
import { INotificacaoRedux } from '../../../../redux/modules/notificacao/types'
import NotificacaoItem from './NotificacaoItem'
import { IApiNotificacaoSearchRequest } from '../../../../services/ApiRequests/NotificacaoRequests/interfaces'
import Api from '../../../../services/Api'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import Pagination from '../../../../components/Pagination'
import { Button } from '../../../../components/Button'
import { FaTimesCircle } from 'react-icons/fa'

// const instance = new WebSocket()

// instance.onMessage(addToast)

const Notification = () => {
  const dispatch = useDispatch()
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const [dados, setDados] = useState<IApiNotificacaoSearchRequest>()
  const pageSize = 10

  const userRedux = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const notificacaoRedux = useSelector<ReduxStore, INotificacaoRedux>(
    (state) => state.notificacao
  )

  const handleSearch = useCallback((page = 1) => {
    loadRef.current?.showLoading()
    Api.notificacao.get({ page, pageSize }).then((res) => {
      loadRef.current?.hideLoading()
      setDados(res)
    })
  }, [])

  const handleOpenModal = useCallback(() => {
    modalRef.current?.openModal()

    handleSearch()
  }, [handleSearch])

  const handleCloseModal = useCallback(() => {
    dispatch(hideNotificacao())
    setDados({} as IApiNotificacaoSearchRequest)
  }, [dispatch])

  useEffect(() => {
    if (notificacaoRedux.showModal) {
      handleOpenModal()
    }
  }, [handleOpenModal, notificacaoRedux.showModal])

  useEffect(() => {
    if (!userRedux.usuario?.id) return

    // instance.authConfig(userRedux.token)
    // instance.register({
    //   id: `${userRedux.usuario.id}`,
    //   tipo: userRedux.usuario.tipo
    // })
  }, [userRedux.token, userRedux.usuario.id, userRedux.usuario.tipo])

  return (
    <Modal
      ref={modalRef}
      title={`Notificações`}
      onClose={() => handleCloseModal()}
    >
      <S.NotificacaoWrapper>
        {/* <header>
          <Button variant="primary">
            <FaClipboardCheck /> Marcar todas como Lida
          </Button>
        </header> */}
        <Loading ref={loadRef} />
        <main>
          {dados?.list?.map((item) => (
            <NotificacaoItem dados={item} key={item.id} />
          ))}
        </main>
        <footer>
          <Pagination
            pageSize={pageSize}
            totalCount={dados?.count}
            onPageChange={(page) => handleSearch(page)}
          />
        </footer>
        <div>
          <Button
            type="button"
            variant="danger"
            onClick={() => {
              modalRef.current?.closeModal()
            }}
          >
            <FaTimesCircle />
            <span>Fechar</span>
          </Button>
        </div>
      </S.NotificacaoWrapper>
    </Modal>
  )
}

export default Notification
