import { Reducer } from 'redux'
import produce from 'immer'
import { ActionFiltroMetas, IMetasRedux, TypeFiltroMetas } from './types'

const INITIAL_STATE: IMetasRedux = {
  pesquisa: {
    modalShow: false,
    filtro: {
    }
  }
}

export const metas: Reducer<IMetasRedux, ActionFiltroMetas> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TypeFiltroMetas.SET_FILTRO: {
        draft.pesquisa.filtro = { ...draft.pesquisa.filtro, ...action.filtro }
        break
      }
      case TypeFiltroMetas.CLEAR_FILTRO: {
        draft.pesquisa.filtro = INITIAL_STATE.pesquisa.filtro
        break
      }
      case TypeFiltroMetas.MODAL_SHOW: {
        draft.pesquisa.modalShow = true
        break
      }
      case TypeFiltroMetas.MODAL_HIDE: {
        draft.pesquisa.modalShow = false
        break
      }
      default: {
        return state
      }
    }
  })
}
