import React, { useRef, useEffect, useState } from 'react'
import { useField } from '@unform/core'
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale
} from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { parseISO } from 'date-fns'

import { StyledCalendarContainer } from './styles'
import { FormGroup } from '../../styles/form'

registerLocale('pt-BR', ptBR)

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string
  label?: string
  width?: string
}

export default function InputDatePiker({ name, label, width, ...rest }: Props) {
  const datepickerRef = useRef(null)

  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError
  } = useField(name)

  const [date, setDate] = useState(defaultValue || null)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      getValue: (ref) => {
        if (ref.props.selected)
          return new Date(ref.props.selected).toISOString()
      },
      setValue: (ref, value) => {
        if (ref && value) {
          setDate(parseISO(`${value}`))
        }
      },
      clearValue: (ref: any) => {
        ref.clear()
      }
    })
  }, [fieldName, registerField])

  return (
    <FormGroup width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <StyledCalendarContainer>
        <ReactDatePicker
          id={fieldName}
          locale="pt-BR"
          dateFormat="dd/MM/yyyy"
          closeOnScroll={(e) => e.target === document}
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          onFocus={clearError}
          // withPortal
          {...rest}
        />
      </StyledCalendarContainer>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}
