import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import InputSelect from '../../../../components/InputSelect'
import { InputSelectOptionsCaptacaoImovelStatus } from '../../../../config/InputSelectConfig'
import Api from '../../../../services/Api'

interface ICaptacaoImovelStatusFormData {
  id: number
  status?: string
}

interface ICaptacaoImovelStatusFormProps {
  status?: ICaptacaoImovelStatusFormData
  callback(update?: ICaptacaoImovelStatusFormData): void
}

const CaptacaoImovelStatusForm: React.FC<ICaptacaoImovelStatusFormProps> = ({
  status,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [statusId, setStatusId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      const dataForm = { id: statusId, ...data }

      await Api.captacaoImovel.updateStatus(dataForm)
      if (callback) callback(dataForm)
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!status?.id) {
      return callback?.()
    }

    const { id, ...rest } = status
    setStatusId(id)
    formRef.current.setData(rest)
  }, [callback, status])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <InputSelect
          label="Status do Imóvel"
          name="status"
          options={InputSelectOptionsCaptacaoImovelStatus}
        />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default CaptacaoImovelStatusForm
