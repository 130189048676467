import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaFilter, FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AluguelPesquisa, {
  IAluguelPesquisaHandles
} from '../../components/AluguelPesquisa'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'

import { getRole, RoleType } from '../../../../config/Roles'
import AluguelForm from '../../forms/Aluguel'
import { ReduxStore } from '../../../../redux'
import { modalAluguelFiltroHandler } from '../../../../redux/modules/aluguel/actions'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { IApiAluguelData } from '../../../../services/ApiRequests/Aluguel/interfaces'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'

const Aluguel = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const modalRef = useRef<IModalPropsHandles>(null)
  const pesquisiaRef = useRef<IAluguelPesquisaHandles>(null)
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const [aluguelData, setAluguelData] = useState<IApiAluguelData>(
    {} as IApiAluguelData
  )
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const handleDelete = useCallback(async (id?: number) => {
    if (!id) return

    alertConfirma(
      `Confirma a exclusão do Aluguel de código "${id}"?`,
      async () => {
        await Api.aluguel.delete(id)
        pesquisiaRef.current?.refreshSearch()
      }
    )
  }, [])

  const handleEdit = useCallback(async (id?: number) => {
    if (!id) return

    const dados = await Api.aluguel.getById(id)
    setAluguelData(dados)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setAluguelData({} as IApiAluguelData)
    pesquisiaRef.current?.refreshSearch()
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Aluguel</h1>
        <Button onClick={() => history.push('/dashboard/aluguel/cadastro')}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Aluguel</h4>
          <Button
            variant="secondary"
            onClick={() => dispatch(modalAluguelFiltroHandler(true))}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <AluguelPesquisa
            ref={pesquisiaRef}
            opcoesRender={(it) => (
              <>
                {usuarioRole !== 'CORRETOR' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(it.id)}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it.id)}
                    >
                      <FaTrash />
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          aluguelData.id
            ? `Editar Aluguel de código ${aluguelData.id}`
            : 'Cadastro de Aluguel'
        }`}
      >
        <AluguelForm
          callback={() => modalRef.current?.closeModal()}
          aluguel={aluguelData}
        />
      </Modal>
    </>
  )
}

export default Aluguel
