import { useRef, useEffect, TextareaHTMLAttributes } from 'react'

import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'
import { Textarea } from './styles'

/**
 * Textarea component for Unform.
 *
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
interface Props {
  name: string
  label?: string
  width?: string
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & Props

export const InputTextarea = ({
  name,
  label,
  width,
  ...rest
}: TextareaProps) => {
  const textareaRef = useRef(null)

  const { fieldName, defaultValue = '', registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: (ref) => {
        return ref.current?.value || null
      },
      setValue: (ref, value) => {
        if (value) ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <FormGroup width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <Textarea
        ref={textareaRef}
        id={fieldName}
        defaultValue={defaultValue}
        {...rest}
      />

      {error && <span>{error}</span>}
    </FormGroup>
  )
}
