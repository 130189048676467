import React, { useCallback, useEffect, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { OptionTypeBase } from 'react-select'
import { FormRow } from '../../styles/form'

import { Button } from '../Button'
import InputFileSelect from '../InputFileSelect'

type InputsProps = {
  name: string
  label: string
}

interface IInputFileSelectMultiploProps {
  inputFile: InputsProps
  inputSelect: InputsProps
  optionsSelect: OptionTypeBase[]
  minCount?: number
}

const InputFileSelectMultiplo: React.FC<IInputFileSelectMultiploProps> = ({
  inputFile,
  inputSelect,
  optionsSelect,
  minCount
}) => {
  const [count, setCount] = useState<Array<{ id: string }>>([])

  const handleAdd = useCallback(() => {
    setCount([...count, { id: (+new Date()).toString(36) }])
  }, [count])

  const handleDelete = useCallback(
    (id: string) => {
      const newList = count.filter((val) => val.id !== id)
      setCount(newList)
    },
    [count]
  )

  useEffect(() => {
    if (minCount) {
      const initialData = Object.keys([...Array(minCount)]).map((value) => {
        return { id: (+new Date()).toString(36) }
      })
      setCount(initialData)
    } else setCount([])
  }, [minCount])

  return (
    <>
      {count.map((value, index) => (
        <FormRow key={value.id}>
          <InputFileSelect
            index={index}
            id={value.id}
            inputFile={inputFile}
            inputSelect={inputSelect}
            optionsSelect={optionsSelect}
            remove={handleDelete}
          />
        </FormRow>
      ))}
      <div className="buttonRight">
        <Button type="button" onClick={() => handleAdd()} variant="secondary">
          <FaPlusCircle />
        </Button>
      </div>
    </>
  )
}

export default InputFileSelectMultiplo
