import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { CardBox, CardHeader } from '../../../../styles/Card'
import {
  IApiMetaData,
  IApiMetasSearchParams
} from '../../../../services/ApiRequests/Metas/interfaces'

import InputSelect from '../../../../components/InputSelect'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import {
  InputSelectOptionsAnos,
  InputSelectOptionsMeses
} from '../../../../config/InputSelectConfig'
import { Form } from '@unform/web'
import { FormGroup, FormRow } from '../../../../styles/form'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { formatCurrency } from '../../../../utils/currency'
import { Table } from '../../../../components/Table'
import { OptionTypeBase } from '../../../../config/global'
import ReactSelect from 'react-select'
import { customStylesReactSelect } from '../../../../styles/react-select.style'
import { getReactSelectMultiValue } from '../../../../utils/react-select'
import { FaAngleDown, FaAngleUp, FaEquals } from 'react-icons/fa'

const MinhasMetas = () => {
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()
  const loadRef = useRef<ILoadingHandles>(null)
  const [corretor, setCorretor] = useState<OptionTypeBase | null>(null)
  const [data, setData] = useState<IApiMetaData[]>()
  const [filter, setFilter] = useState<IApiMetasSearchParams>({
    ano: new Date().getFullYear()
  })

  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    const role = getRole(usuario.tipo)
    setUsuarioRole(role)
    setCorretor({ label: usuario.nome, value: usuario.id })
    setFilter({ ...filter, corretorid: usuario.id })
  }, [usuario])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  useEffect(() => {
    setData([])
    if (!filter.ano) return
    loadRef.current?.showLoading()
    Api.metas
      .getAll(filter)
      .then((data) => {
        setData(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
    console.log('mudou Looppp', filter)
    loadRef.current?.hideLoading()
  }, [filter])

  return (
    <>
      <CardHeader>
        <h1>Minhas Metas</h1>
      </CardHeader>
      <CardBox>
        <Form onSubmit={() => {}}>
          <FormRow>
            {usuarioRole === 'ADMIN' && (
              <>
                <InputSelectAsync
                  label="Corretor"
                  name="corretorid"
                  value={corretor}
                  onChange={(corretor) => {
                    setFilter({ ...filter, corretorid: corretor?.value })
                    setCorretor(corretor)
                  }}
                  asyncLoadOptions={handleSearchCorretor}
                />
                <FormGroup>
                  <label htmlFor="mes">Mês</label>
                  <div>
                    <ReactSelect
                      name="mes"
                      classNamePrefix="react-select"
                      placeholder="Opções"
                      noOptionsMessage={() => 'Sem opções'}
                      isClearable={true}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          meses: getReactSelectMultiValue(e)
                        })
                        setCorretor(corretor)
                      }}
                      isMulti
                      options={InputSelectOptionsMeses}
                      styles={customStylesReactSelect}
                    />
                  </div>
                </FormGroup>
              </>
            )}
            <InputSelect
              label="Ano"
              name="ano"
              isClearable={false}
              value={{
                label: filter.ano,
                value: filter.ano
              }}
              onChange={(e) => setFilter({ ...filter, ano: e?.value })}
              options={InputSelectOptionsAnos}
            />
          </FormRow>
        </Form>
        <header></header>
        <div>
          <Loading ref={loadRef} />
          <Table<IApiMetaData>
            headers={{
              corretor: 'Corretor',
              mes: 'Mês/Ano',
              valor_meta: 'Valor da Meta',
              valor_realizado: 'Valor Realizado',
              valor_restante: 'Valor Restante'
            }}
            columnSizes={{
              corretor: '10rem',
              mes: '5rem',
              valor_meta: '5rem',
              valor_realizado: '5rem',
              valor_restante: '5rem'
            }}
            items={data}
            customRenderers={{
              corretor: (it) => {
                return it.corretor?.nome
              },
              mes: (it) => {
                return `${it.mes?.toString().padStart(2, '0')}/${it.ano}`
              },
              valor_meta: (it) => {
                if (it.valor_meta) return formatCurrency(it.valor_meta)
              },
              valor_realizado: (it) => {
                if (it.valor_realizado)
                  return formatCurrency(it.valor_realizado)
              },
              valor_restante: (it) => {
                if (it.valor_restante) {
                  return (
                    <>
                      {it.valor_restante < 0 ? (
                        <FaAngleUp color="green" />
                      ) : (
                        <FaAngleDown color="red" />
                      )}{' '}
                      {formatCurrency(it.valor_restante)}
                    </>
                  )
                } else
                  return (
                    <>
                      <FaEquals color="gray" /> 0,00
                    </>
                  )
              }
            }}
          />
        </div>
      </CardBox>
    </>
  )
}

export default MinhasMetas
