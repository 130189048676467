import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChevronLeft, FaFilePdf } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import LabelDetalhes from './LabelDetalhes'
import { IApiVendaDetalhes } from '../../../../services/ApiRequests/Venda/interfaces'
import Api from '../../../../services/Api'

import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import DetalhesDepositos from './DetalhesDepositos'
import DetalhesArquivos from './DetalhesArquivos'
import DetalhesJuridico from './DetalhesJuridico'
import DetalhesFinanciamento from './DetalhesFinanciamento'
import DetalhesFinanceiro from './DetalhesFinanceiro'
import DetalhesCliente from './DetalhesCliente'

interface RouteParams {
  id: string
}

const VendaDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiVendaDetalhes>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)

  const handlePDF = useCallback(() => {
    if (!data?.id) return
    Api.venda.getPdf(data.id)
  }, [data])

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.venda
        .getByIdDetailed(params.id)
        .then((res) => {
          setData(res)
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard/vendas')
        })
    }
  }, [handleLoading, history, params.id])

  return (
    <div id="printableArea">
      <CardHeader>
        <h1>Detalhes da Venda</h1>
        <div className="print-hide">
          <Button variant="danger" onClick={() => handlePDF()}>
            <FaFilePdf />
            PDF
          </Button>
        </div>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Venda Código {params.id}</h4>
          <div className="print-hide">
            <Button onClick={() => history.goBack()}>
              <FaChevronLeft />
              Voltar
            </Button>
          </div>
        </header>
        <div>
          <Loading ref={loadRef} />

          {!isLoading && (
            <div id="printme">
              <S.WrapperVendaDetalhes>
                <h4>Dados Gerais</h4>
                <section className="print-left">
                  <LabelDetalhes label="Corretor" text={data?.corretor.nome} />
                  <LabelDetalhes label="Cadastro" dateTime={data?.created_at} />
                  <LabelDetalhes label="Prazo Final" date={data?.prazo} />
                  <LabelDetalhes label="Posse" date={data?.dataposse} />
                </section>
                {data?.codigoimovel && (
                  <section>
                    <LabelDetalhes
                      label="Cód. Imóvel Site"
                      text={data?.codigoimovel}
                    />
                  </section>
                )}
                <DetalhesCliente tipo="Compradores" dados={data?.compradores} />
                <DetalhesCliente tipo="Vendedores" dados={data?.vendedores} />

                <h4>Valores</h4>
                <section>
                  <LabelDetalhes
                    label="Valor da Venda"
                    value={data?.valorvenda}
                  />
                  <LabelDetalhes
                    label="Valor na Escritura"
                    value={data?.valorescritura}
                  />
                  <LabelDetalhes
                    label="Valor será Pago à vista"
                    value={data?.valorpagoavista}
                  />
                  <LabelDetalhes
                    label="Valor a Financiar"
                    value={data?.valorfinanciamento}
                  />
                </section>
                <section>
                  <LabelDetalhes
                    label="Valor a usar do FGTS"
                    value={data?.valorfgts}
                  />
                  <LabelDetalhes
                    label="Imóvel já Financiado?"
                    text={data?.imoveljafinanciado ? 'Sim' : 'Não'}
                  />
                  {data?.partesdeclaraimpostorenda && (
                    <LabelDetalhes
                      label="Valor a declarar Imposto Renda"
                      value={data?.partesdeclaraimpostorendavalor}
                    />
                  )}
                  <LabelDetalhes label="" text="" />
                </section>
                <h4>Pagamento do imovel</h4>
                <section>
                  {data?.pagasscontrato && (
                    <LabelDetalhes
                      label="Assinatura do Contrato"
                      value={data?.pagasscontratovalor}
                    />
                  )}
                  {data?.pagassescritura && (
                    <LabelDetalhes
                      label="Assinatura da Escritura"
                      value={data?.pagassescrituravalor}
                    />
                  )}
                  {data?.pagassfinanciamento && (
                    <LabelDetalhes
                      label="Assinatura do Financiamento"
                      value={data?.pagassfinanciamentovalor}
                    />
                  )}
                  {data?.pagaposregistro && (
                    <LabelDetalhes
                      label="Após Registro"
                      value={data?.pagaposregistrovalor}
                    />
                  )}
                </section>

                <DetalhesDepositos depositos={data?.depositos} />
                <h4>Comissão</h4>
                <section>
                  {data?.comissaoavista && (
                    <div>
                      <strong>À Vista</strong>
                    </div>
                  )}

                  {data?.comissaorecfinanciamento && (
                    <strong>No recebimento do Financiamento</strong>
                  )}
                  {data?.comissaoaprazo && (
                    <strong>A Prazo ({data?.comissaoaprazodias} dias)</strong>
                  )}
                </section>

                <DetalhesArquivos anexos={data?.arquivos} />

                <h4>Corretores Parceiros</h4>
                <section>
                  {data?.corretoresParceiros
                    ?.map(({ nome }) => nome)
                    .join(', ')}
                </section>

                <h4>Outras Informações</h4>
                <section>
                  {data?.corretorcaptador?.id && (
                    <LabelDetalhes
                      label="Corretor Captador"
                      text={`${data?.corretorcaptador?.id} - ${data?.corretorcaptador?.nome}`}
                    />
                  )}
                </section>
                <section>
                  <LabelDetalhes label="Observações" text={data?.observacoes} />
                </section>
                <hr />
                {data?.juridico?.id && (
                  <>
                    <h3>Jurídico</h3>
                    <DetalhesJuridico juridico={data.juridico} />
                  </>
                )}
                {data?.financiamento?.id && (
                  <>
                    <h3>Financiamento</h3>
                    <DetalhesFinanciamento financiamento={data.financiamento} />
                  </>
                )}
                {data?.financeiro?.id && (
                  <>
                    <h3>Financeiro</h3>
                    <DetalhesFinanceiro financeiro={data.financeiro} />
                  </>
                )}
              </S.WrapperVendaDetalhes>
            </div>
          )}
        </div>
      </CardBox>
    </div>
  )
}

export default VendaDetalhes
