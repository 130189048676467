import React from 'react'
import { IApiVendaFinanceiro } from '../../../../services/ApiRequests/Venda/interfaces'

import LabelDetalhes from './LabelDetalhes'

interface IDetalhesFinanceiroProps {
  financeiro: IApiVendaFinanceiro
}

const DetalhesFinanceiro = ({ financeiro }: IDetalhesFinanceiroProps) => {
  return (
    <>
      <section>
        <LabelDetalhes
          label="Recebeu Honorários"
          text={`${financeiro.honorario ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Pagou Corretor"
          text={`${financeiro.pagoucorretor ? 'Sim' : 'Não'}`}
        />
      </section>
    </>
  )
}

export default DetalhesFinanceiro
