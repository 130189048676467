import styled, { css } from 'styled-components'
import { Form as FormUnform } from '@unform/web'

interface GridOptions {
  default: string
  desktop: string
  tablet: string
  phone: string
}

interface IFormRow {
  buttons?: boolean
  grid?: boolean
  gridColumns?: GridOptions
}

interface IFormGroup {
  width?: string | number | undefined
}

export const Form = styled(FormUnform)`
  font: inherit;
  box-sizing: border-box;

  .error {
    padding: 0.4rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.danger};
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`

export const FormRow = styled.div<IFormRow>`
  display: flex;
  padding: 0.2rem;
  flex: 1;

  ${({ theme, buttons, grid, gridColumns }) => css`
    ${theme.media.phone} {
      flex-direction: column;
    }

    ${buttons &&
    css`
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid ${({ theme }) => theme.colors.light};
      padding-top: 1rem;

      button + button {
        margin-left: 0.5rem;
      }

      ${theme.media.phone} {
        flex-direction: row;
      }
    `}

    ${grid &&
    gridColumns &&
    css`
      display: grid;
      grid-template-columns: repeat(${gridColumns.default}, 1fr);

      ${theme.media.desktop} {
        grid-template-columns: repeat(${gridColumns.desktop}, 1fr);
      }

      ${theme.media.tablet} {
        grid-template-columns: repeat(${gridColumns.tablet}, 1fr);
      }

      ${theme.media.phone} {
        grid-template-columns: repeat(${gridColumns.phone}, 1fr);
      }
    `}
  `}

  .buttonBottom {
    display: flex;
    align-items: flex-end;
    padding: 0.4rem;
  }

  .buttonRight {
    display: flex;
    justify-content: flex-end;
    padding: 0.4rem;
  }
`

export const FormRowStrippedWrapper = styled.div`
  & > div {
    border-radius: 0.5rem;
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.grey300};
    }
  }

  &:last-child {
    margin-bottom: 1rem;
  }

  .buttonRight {
    display: flex;
    justify-content: flex-end;
    padding: 0.4rem;
    background-color: white !important;
  }
`

export const FormGroup = styled.div<IFormGroup>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.4rem;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
      flex: none;
    `}

  & + div {
    margin-left: 0.5rem;
  }

  label {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }

  ${({ theme, width }) => css`
    ${theme.media.phone} {
      & + div {
        margin-left: 0rem;
      }

      ${width &&
      css`
        width: 100%;
        flex: auto;
      `}
    }
  `}
`

export const FormGroupHorizontal = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: 0.5rem;
  }

  /* & + div {
    margin-left: 0.5rem;
  } */

  ${({ theme }) => css`
    ${theme.media.phone} {
      padding: 0.5rem;
    }
  `}
`

export const FormGroupSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin: 1rem 0.5rem;

  hr {
    border-top: 1px dashed #ccc;
    margin: 0 0.3rem;
    flex: 1;
  }

  /* span::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
  } */
  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
`

export const FormWrapperColumn = styled.div<IFormGroup>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.5rem;

  & > div + div {
    margin-left: 0;
    margin-top: 0.6rem;
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
      flex: none;
    `}

  ${({ theme }) => css`
    ${theme.media.phone} {
      padding: 0;
      width: 100%;
      flex: auto;
    }
  `}
`

export const FormWrapperRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  &:not(:last-child) {
    margin-bottom: 0.3rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey200} !important;
  }
`
export const FormCheckBoxWrapper = styled.div`
  padding: 1.5rem 0.5rem 0 0.5rem;
`
