import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaEraser, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'

import { Form, FormRow } from '../../../../styles/form'
import { Button } from '../../../../components/Button'
import { FiltroWrapper } from './styles'

import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { OptionTypeBase } from '../../../../config/global'
import {
  IFiltroMetas,
  IMetasRedux
} from '../../../../redux/modules/metas/types'
import {
  clearFiltroMetas,
  setFiltroMetas
} from '../../../../redux/modules/metas/actions'
import InputSelect from '../../../../components/InputSelect'
import {
  InputSelectOptionsAnos,
  InputSelectOptionsMeses
} from '../../../../config/InputSelectConfig'

interface IMetasFiltroProps {
  callback?(): void
}
const MetasFiltro: React.FC<IMetasFiltroProps> = ({ callback }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const metas = useSelector<ReduxStore, IMetasRedux>((state) => state.metas)
  const [corretor, setCorretor] = useState<OptionTypeBase | null>()

  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(clearFiltroMetas())
    if (callback) callback()
  }, [callback, dispatch])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler<IFiltroMetas> = async (
    data,
    { reset },
    event
  ) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      dispatch(setFiltroMetas({ ...data, corretorid: corretor?.value }))
      if (callback) callback()
      // reset()
    } catch (err) {}
  }

  useEffect(() => {
    setCorretor({})
    formRef.current?.setData(metas.pesquisa.filtro)
  }, [metas.pesquisa.filtro])

  return (
    <FiltroWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormRow>
          <InputSelect
            label="Mes"
            name="mes"
            options={InputSelectOptionsMeses}
          />
          <InputSelect
            label="Ano"
            name="ano"
            options={InputSelectOptionsAnos}
          />
          {/* <InputCheckbox name="naofinalizadas" label="Apenas não Finalizadas" /> */}
          <InputSelectAsync
            label="Corretor"
            name="corretor"
            onChangeOption={(value) => setCorretor(value)}
            asyncLoadOptions={handleSearchCorretor}
          />
        </FormRow>
        <FormRow buttons>
          <Button type="button" variant="danger" onClick={() => handleClear()}>
            <FaEraser />
            <span>Limpar Filtro</span>
          </Button>

          <Button type="submit">
            <FaSearch />
            <span>Pesquisar</span>
          </Button>
        </FormRow>
      </Form>
    </FiltroWrapper>
  )
}

export default MetasFiltro
