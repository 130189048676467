import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaAngleDown, FaAngleUp, FaEquals } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import Api from '../../../../services/Api'
import { formatCurrency } from '../../../../utils/currency'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'

import { IApiMetaData } from '../../../../services/ApiRequests/Metas/interfaces'
import { IMetasRedux } from '../../../../redux/modules/metas/types'
import MetasFiltro from '../MetasFiltro'
import { modalMetasFiltroHandler } from '../../../../redux/modules/metas/actions'

export interface IMetaListagemHandles {
  refreshSearch: () => void
}

interface IMetaProps {
  opcoesRender?: (item: Partial<IApiMetaData>) => React.ReactNode
}

const MetasListagem: React.ForwardRefRenderFunction<
  IMetaListagemHandles,
  IMetaProps
> = ({ opcoesRender }, ref) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const dispatch = useDispatch()
  const [search, setSearch] = useState<IApiMetaData[]>()
  const metasRedux = useSelector<ReduxStore, IMetasRedux>(
    (state) => state.metas
  )

  useEffect(() => {
    if (metasRedux.pesquisa.modalShow) modalRef.current?.openModal()
  }, [metasRedux.pesquisa.modalShow])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setSearch([])

    Api.metas
      .getAll({ ...metasRedux.pesquisa.filtro })
      .then((data) => {
        setSearch(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [metasRedux.pesquisa.filtro])

  useImperativeHandle(ref, () => {
    return {
      refreshSearch: () => handleLoad()
    }
  })

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <Table<IApiMetaData>
        headers={{
          corretor: 'Corretor',
          mes: 'Mês/Ano',
          valor_meta: 'Valor da Meta',
          valor_realizado: 'Valor Realizado',
          valor_restante: 'Valor Restante',
          opcoes: 'Opções'
        }}
        columnSizes={{
          corretor: '10rem',
          mes: '5rem',
          valor_meta: '5rem',
          valor_realizado: '5rem',
          valor_restante: '5rem',
          opcoes: '9rem'
        }}
        items={search}
        customRenderers={{
          corretor: (it) => {
            return it.corretor?.nome
          },
          mes: (it) => {
            return `${it.mes?.toString().padStart(2, '0')}/${it.ano}`
          },
          valor_meta: (it) => {
            if (it.valor_meta) return formatCurrency(it.valor_meta)
          },
          valor_realizado: (it) => {
            if (it.valor_realizado) return formatCurrency(it.valor_realizado)
          },
          valor_restante: (it) => {
            if (it.valor_restante) {
              return (
                <>
                  {it.valor_restante < 0 ? (
                    <FaAngleUp color="green" />
                  ) : (
                    <FaAngleDown color="red" />
                  )}{' '}
                  {formatCurrency(it.valor_restante)}
                </>
              )
            } else
              return (
                <>
                  <FaEquals color="gray" /> 0,00
                </>
              )
          },

          opcoes: (it) => (
            <TableButtonWrapper>
              {opcoesRender && opcoesRender(it)}
            </TableButtonWrapper>
          )
        }}
      />

      <Loading ref={loadRef} />

      <Modal
        ref={modalRef}
        title={`Filtrar Metas`}
        onClose={() => dispatch(modalMetasFiltroHandler(false))}
      >
        <MetasFiltro callback={() => modalRef.current?.closeModal()} />
      </Modal>
    </>
  )
}

export default forwardRef(MetasListagem)
