import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 0.7fr repeat(3, 1fr);
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    'sidebar nav nav nav'
    'sidebar main main main';

  @media only screen and (max-width: 978px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas: 'nav' 'main';
  }

  @media print {
    grid-template-columns: 1fr;
    grid-template-areas: 'main';
  }
`

export const WrapperMain = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
`

export const WrapperChildren = styled.div`
  flex: 1;
`

export const SectionMain = styled.main`
  padding: 0.5rem;
  margin: 0 2rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #fff;
  border-radius: 3px;
  border: none;
  position: relative;
  margin-bottom: 30px;

  header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    line-height: 30px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;

    h4 {
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      margin: 2px 0 -2px 0;
      line-height: 30px;
    }
  }
`
export const SectionMainHeader = styled.header`
  border: none;
  position: relative;
  padding: 1rem;
  margin: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin-bottom: 0;
    font-weight: 700;
    display: inline-block;
    font-size: 24px;
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.fontdark};
  }
`
