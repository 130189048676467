import BaseRequests from '../BaseRequests'
import { IApiMunicipio } from './interfaces'

class MunicipioRequests extends BaseRequests {
  constructor() {
    super('municipios')
  }

  get = async (search?: string): Promise<IApiMunicipio[]> => {
    return this.sendGet<IApiMunicipio[]>({ params: { search } })
  }
}

export default MunicipioRequests
