import * as Yup from 'yup'

export const yupGetErrors = (err: any) => {
  const validationErrors: { [key: string]: string } = {}
  if (err instanceof Yup.ValidationError) {
    err.inner.forEach((error) => {
      if (error.path) validationErrors[error.path] = error.message
    })
  }

  // Retorno do Backend
  const validation = err?.response?.data?.validation
  if (validation) {
    Object.keys(validation).map((key) => {
      validationErrors[key] = validation[key]
      return key
    })
  }

  return validationErrors
}
