import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { clearUsuario } from '../../redux/modules/usuario/actions'
import { Box, Container } from '../Login/styles'
import { authLogout } from '../../hooks/auth'

const Logout: React.FC<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    authLogout()
    dispatch(clearUsuario())

    history.push('/')
  }, [dispatch, history])

  return (
    <Container>
      <Box>
        <header>
          <h2>Saindo...</h2>
        </header>
      </Box>
    </Container>
  )
}

export default Logout
