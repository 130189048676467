import { IFiltroAluguel, SetFiltro, TypeFiltroAluguel } from './types'

export const setFiltroAluguel = (filtro: IFiltroAluguel): SetFiltro => ({
  type: TypeFiltroAluguel.SET_FILTRO,
  filtro
})

export const clearFiltroAluguel = () => ({
  type: TypeFiltroAluguel.CLEAR_FILTRO
})

export const modalAluguelFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroAluguel.MODAL_SHOW : TypeFiltroAluguel.MODAL_HIDE
})
