import React, { useCallback, useRef, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import AluguelPesquisa from '../../components/AluguelPesquisa'
import { Button } from '../../../../components/Button'

import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import AluguelVistoriaForm from '../../forms/AluguelVistoria'
import { modalAluguelFiltroHandler } from '../../../../redux/modules/aluguel/actions'
import Api from '../../../../services/Api'
import { IApiAluguelVistoria } from '../../../../services/ApiRequests/Aluguel/interfaces'
import { CardBox, CardHeader } from '../../../../styles/Card'

const AluguelVistoria = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const dispatch = useDispatch()

  const [data, setData] = useState<IApiAluguelVistoria>(
    {} as IApiAluguelVistoria
  )
  const [aluguelid, setAluguelId] = useState<number | null>()

  const handleEdit = useCallback((id?: number) => {
    setData({} as IApiAluguelVistoria)
    setAluguelId(null)

    if (!id) return
    Api.aluguel.getVistoria(id).then((res) => {
      setAluguelId(id)
      setData(res)
      modalRef.current?.openModal()
    })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Aluguel Vistoria</h1>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Aluguel</h4>
          <Button
            variant="secondary"
            onClick={() => dispatch(modalAluguelFiltroHandler(true))}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <AluguelPesquisa
            opcoesRender={(it) => (
              <>
                <Button variant="primary" onClick={() => handleEdit(it.id)}>
                  <FiEdit />
                </Button>
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        title={`Controle de Vistoria do Aluguel ${aluguelid}`}
      >
        <AluguelVistoriaForm
          vistoria={data}
          callback={() => modalRef.current?.closeModal()}
        />
      </Modal>
    </>
  )
}

export default AluguelVistoria
