import { OptionTypeBase } from "../../../config/global"

export enum TypeFiltroVenda {
  SET_FILTRO = 'FILTRO_VENDA_SET',
  CLEAR_FILTRO = 'FILTRO_VENDA_CLEAR',
  MODAL_SHOW = 'FILTRO_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_MODAL_HIDE'
}

export interface IFiltroVenda {
  dataini?: string
  datafim?: string
  page?: number
  pageSize?: number
  corretor?: OptionTypeBase | null
  comprador?: OptionTypeBase | null
  vendedor?: OptionTypeBase | null
}

export interface IVendaRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroVenda
  }
}

export interface SetFiltro {
  type: TypeFiltroVenda.SET_FILTRO
  filtro: IFiltroVenda
}

interface ClearFiltro {
  type: TypeFiltroVenda.CLEAR_FILTRO
}

interface ModalShow {
  type: TypeFiltroVenda.MODAL_SHOW
}

interface ModalHide {
  type: TypeFiltroVenda.MODAL_HIDE
}

export type ActionFiltroVenda = SetFiltro | ClearFiltro | ModalShow | ModalHide
