import { generateArrayOfYears } from "../utils/date"

export const InputSelectOptionsFile = [
  { value: '0', label: 'Documento Pessoal' },
  { value: '1', label: 'Certidões Pessoais' },
  { value: '2', label: 'Comprovante de Endereço' },
  { value: '3', label: 'Matícula/Certidão Imóvel' },
  { value: '4', label: 'Certidões Diversas' },
  { value: '5', label: 'Comprovante de Renda' },
  { value: '6', label: 'Outros' }
]

export const InputSelectOptionsFileCaptacaoImovel = [
  { value: '0', label: 'Fotos' },
  { value: '1', label: 'Documentação' },
  { value: '2', label: 'Ficha' },
]

export const InputSelectOptionsSimNao = [
  { value: 'S', label: 'Sim' },
  { value: 'N', label: 'Não' }
]

export const InputSelectOptionsQuantidade = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]

export const InputSelectOptionsPerfis = [
  { value: '1', label: 'Administrador' },
  { value: '2', label: 'Financeiro' },
  { value: '3', label: 'Financiamento' },
  { value: '4', label: 'Jurídico' },
  { value: '5', label: 'Corretor' },
  { value: '6', label: 'Marketing' }
]

export const InputSelectOptionsEstadoCivil = [
  { value: '0', label: 'Solteiro (a)' },
  { value: '1', label: 'Casado (a)' },
  { value: '2', label: 'Divorciado (a)' },
  { value: '3', label: 'União estável' },
  { value: '4', label: 'Viúvo (a)' }
]

export const InputSelectOptionsAssinaturaTipo = [
  { value: '0', label: 'Físico' },
  { value: '1', label: 'Digital' }
]

export const InputSelectOptionsTipoContaBancaria = [
  { value: 'C', label: 'Corrente' },
  { value: 'P', label: 'Poupança' }
]

export const InputSelectOptionsStatusVenda = [
  { value: 'F', label: 'Finalizado', color: '#008000' },
  { value: 'A', label: 'Aguardando', color: '#191d21' },
  { value: 'N', label: 'Não Realizado', color: '#c10207' }
]

export const InputSelectOptionsTipoGarantia = [
  { value: '0', label: 'Caução' },
  { value: '1', label: 'Fiador' },
  { value: '2', label: 'Seguro Fiança' }
]

export const InputSelectOptionsCaptacaoImovelTipoCaptacao = [
  { value: '0', label: 'Venda' },
  { value: '1', label: 'Aluguel' },
  { value: '2', label: 'Permuta' }
]

export const InputSelectOptionsCaptacaoImovelTipoImovel = [
  { value: '0', label: 'Terreno' },
  { value: '1', label: 'Casa' },
  { value: '2', label: 'Sobrado' },
  { value: '3', label: 'Apartamento' },
  { value: '4', label: 'Comercial' },
  { value: '5', label: 'Rural' },
  { value: '6', label: 'Prédio' },
]

export const InputSelectOptionsCaptacaoImovelTipoVaga = [
  { value: 'C', label: 'Coberta' },
  { value: 'D', label: 'Descoberta' },
]

export const InputSelectOptionsCaptacaoImovelTipoPiso = [
  { value: '0', label: 'Porcelanato' },
  { value: '1', label: 'Cerâmica' },
  { value: '2', label: 'Outros' },
]

export const InputSelectOptionsCaptacaoImovelTipoForro = [
  { value: '0', label: 'Sem Forro' },
  { value: '1', label: 'Forro Paulista' },
  { value: '2', label: 'Forro PVC' },
  { value: '3', label: 'Forro Gesso' },
]

export const InputSelectOptionsCaptacaoImovelOcupado = [
  { value: '0', label: 'Desocupado' },
  { value: '1', label: 'Proprietário' },
  { value: '2', label: 'Inquilíno' },
]

export const InputSelectOptionsCaptacaoImovelTipoRua = [
  { value: '0', label: 'Asfalto' },
  { value: '1', label: 'Sem Asfalto' },
]

export const InputSelectOptionsCaptacaoImovelPosicaoSol = [
  { value: '0', label: 'Nascente' },
  { value: '1', label: 'Poente' },
]

export const InputSelectOptionsCaptacaoImovelLocalChaves = [
  { value: '0', label: 'Proprietário' },
  { value: '1', label: 'Imobiliária' },
]

export const InputSelectOptionsCaptacaoImovelStatus = [
  { value: '0', label: 'Faltando Documentos' },
  { value: '1', label: 'Cadastro Concluído' },
  { value: '2', label: 'Cancelado' },
]

export const InputSelectOptionsCaptacaoImagemStatus = [
  { value: '0', label: 'Agendado' },
  { value: '1', label: 'Falta Informação' },
  { value: '4', label: 'Captando Imagens' },
  { value: '2', label: 'Cancelado' },
  { value: '3', label: 'Concluído' },
]

export const InputSelectOptionsMeses = [
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
]
export const InputSelectOptionsAnos = generateArrayOfYears().map(year => ({ value: year, label: year }))

export const InputSelectOptionsFinanciamentoStatus = [
  { value: '1', label: 'Em Andamento' },
  { value: '2', label: 'Cancelado' },
  { value: '3', label: 'Concluído' },
]