import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import {
  Form,
  FormRow,
  FormGroupSeparator,
  FormRowStrippedWrapper
} from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import {
  InputSelectOptionsCaptacaoImovelTipoImovel,
  InputSelectOptionsCaptacaoImovelTipoCaptacao,
  InputSelectOptionsCaptacaoImovelTipoVaga,
  InputSelectOptionsCaptacaoImovelTipoPiso,
  InputSelectOptionsCaptacaoImovelTipoForro,
  InputSelectOptionsCaptacaoImovelTipoRua,
  InputSelectOptionsCaptacaoImovelPosicaoSol,
  InputSelectOptionsSimNao,
  InputSelectOptionsCaptacaoImovelOcupado,
  InputSelectOptionsCaptacaoImovelLocalChaves,
  InputSelectOptionsQuantidade,
  InputSelectOptionsFileCaptacaoImovel
} from '../../../../config/InputSelectConfig'
import { FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputFileSelectMultiplo from '../../../../components/InputFileSelectMultiplo'
import { InputTextarea } from '../../../../components/InputTextarea'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import InputSelect from '../../../../components/InputSelect'
import InputMask from '../../../../components/InputMask'
import {
  IApiCaptacaoImovelArquivoData,
  IApiCaptacaoImovelData
} from '../../../../services/ApiRequests/CaptacaoImovel/interfaces'
import { clearMaskNumber } from '../../../../utils/mask'
import { OptionTypeBase, ValueType } from 'react-select'

interface CaptacaoImovelFormProps {
  captacao?: IApiCaptacaoImovelData
}

const CaptacaoImovelForm: React.FC<IFormCallback & CaptacaoImovelFormProps> = ({
  callback,
  captacao
}) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)
  const [arquivosApagados, setArquivosApagados] = useState<number[]>([])
  const [isTerreno, setIsTerreno] = useState<boolean>(false)

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    if (captacao && captacao.id) {
      setId(captacao.id)

      const { cidade, corretor, corretorparceiro, cliente, ...dados } = captacao

      formRef.current.setData({
        ...dados,

        cidadeid: {
          label: cidade?.nome,
          value: cidade?.id
        },
        corretorid: {
          label: corretor?.nome,
          value: corretor?.id
        },
        ...(corretorparceiro?.id && {
          corretorparceiroid: {
            label: corretorparceiro?.nome,
            value: corretorparceiro?.id
          }
        }),
        ...(cliente?.id && {
          clienteid: {
            label: cliente?.nome,
            value: cliente?.id
          }
        })
      })
    }
  }, [captacao, usuario.id, usuario.nome, usuario.tipo])

  const handleDeleteFile = useCallback(
    (id: number) => {
      if (captacao && captacao.arquivos)
        captacao.arquivos = captacao.arquivos.filter((arq) => arq.id !== id)
      setArquivosApagados([...arquivosApagados, id])
    },
    [captacao, arquivosApagados]
  )

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCidade = useCallback(async (inputValue?: string) => {
    return Api.municipio.get(inputValue).then((data) => {
      const arrayData = data.map((mun) => ({
        label: `${mun.nome} - ${mun.uf}`,
        value: mun.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      const dados = {
        id,
        ...data,
        arquivosApagados
      }

      await validateForm(dados)

      const areacontruida = +clearMaskNumber(dados.areacontruida)
      const areaterreno = +clearMaskNumber(dados.areaterreno)

      const CASA = '1'

      if (data.tipoimovel == CASA) {
        if (areacontruida < 0)
          throw new Error('Informe uma "Área Construída (m²)" maior que 0')
      }

      if (areaterreno <= 0)
        throw new Error('Informe um "Terreno (m²)" maior que 0')

      if (!data.arquivos?.length && !captacao?.arquivos?.length)
        throw new Error('É necessário informar um anexo para o registro.')

      const formData = new FormData()
      formData.append('data', JSON.stringify(dados))

      if (data.arquivos?.length) {
        data.arquivos.map((file: File) =>
          formData.append(`arquivos`, file, file.name)
        )
      }

      if (!id) {
        await Api.captacaoImovel.save(formData)
      } else {
        await Api.captacaoImovel.update(formData)
      }
      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  const handleChangeTipoImovel = (option: ValueType<OptionTypeBase, false>) => {
    setIsTerreno(option?.value === '0')
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormGroupSeparator>
        <span>Proprietário</span>
        <hr />
      </FormGroupSeparator>
      <FormRowStrippedWrapper>
        <InputSelectAsync
          label=""
          name="clienteid"
          asyncLoadOptions={handleSearchCliente}
        />
      </FormRowStrippedWrapper>
      {captacao?.nome && (
        <>
          <FormGroupSeparator>
            <span>
              Selecione acima o cadastro do cliente para poder salvar o registro
            </span>
            <hr />
          </FormGroupSeparator>
          <FormRow>
            <Input name="nome" label="Nome" readOnly={true} />
            <Input name="email" label="E-mail" readOnly={true} />
          </FormRow>
          <FormRow>
            <InputMask
              mask="(99) 99999-9999"
              name="telefone"
              label="Telefone"
              width="14rem"
              readOnly={true}
            />
            <Input name="cpf" label="CPF" readOnly={true} />
            <Input name="rg" label="RG" readOnly={true} />
            <Input
              name="cliente_endereco"
              label="Logradouro"
              width="40%"
              readOnly={true}
            />
          </FormRow>
        </>
      )}

      <FormGroupSeparator>
        <span>Dados do Imóvel</span>
        <hr />
      </FormGroupSeparator>

      <FormRow>
        <div
          style={{
            ...(usuario.tipo === '5' ? { display: 'none' } : {}),
            width: '30%'
          }}
        >
          <InputSelectAsync
            label="Corretor"
            name="corretorid"
            asyncLoadOptions={handleSearchCorretor}
          />
        </div>
        <InputSelectAsync
          label="Corretor Parceiro"
          name="corretorparceiroid"
          asyncLoadOptions={handleSearchCorretor}
        />
        <InputSelect
          label="Tipo de Captação"
          name="tipocaptacao"
          width="18%"
          options={InputSelectOptionsCaptacaoImovelTipoCaptacao}
        />
        <InputSelect
          label="Tipo de Imóvel"
          name="tipoimovel"
          width="23%"
          onChange={handleChangeTipoImovel}
          options={InputSelectOptionsCaptacaoImovelTipoImovel}
        />
      </FormRow>

      <FormRow>
        <Input name="endereco" label="Endereço" width="50%" />
        <Input name="numero" label="Número" width="10rem" />
        <Input name="bairro" label="Bairro" />
      </FormRow>
      <FormRow>
        <Input name="complemento" label="Complemento" />
        <Input name="quadra" label="Quadra" width="6rem" />
        <Input name="lote" label="Lote" width="6rem" />
        <Input name="apartamento" label="Apartamento" width="9rem" />
        <Input name="bloco" label="Bloco" width="6rem" />

        <InputSelectAsync
          width="22rem"
          name="cidadeid"
          label="Cidade"
          asyncLoadOptions={handleSearchCidade}
        />
        <InputMask mask="99999-999" name="cep" label="CEP" width="10rem" />
      </FormRow>

      <FormGroupSeparator>
        <span>Valores</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputCurrency name="valorimovel" label="Imóvel" />
        <InputCurrency name="valoriptu" label="IPTU" />
        <InputCurrency name="valorcondominio" label="Condomínio" />
        <InputCurrency name="valorcomissao" label="Comissão %" />

        <InputCurrency
          name="valorhonoloc"
          label="Honorários Loc. %"
          hide={isTerreno}
        />
        <InputCurrency
          name="valortaxaadm"
          label="Taxa Mensal Adm. %"
          hide={isTerreno}
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Detalhes</span>
        <hr />
      </FormGroupSeparator>
      <FormRow style={isTerreno ? { display: 'none' } : {}}>
        <InputSelect
          label="Dormitórios"
          name="dormitorios"
          options={InputSelectOptionsQuantidade}
        />
        <InputSelect
          label="Suítes"
          name="suites"
          options={InputSelectOptionsQuantidade}
        />
        <InputSelect
          label="Banheiros"
          name="banheiros"
          options={InputSelectOptionsQuantidade}
        />
        <InputSelect
          label="Salas"
          name="salas"
          options={InputSelectOptionsQuantidade}
        />
        <InputSelect
          label="Cozinhas"
          name="cozinhas"
          options={InputSelectOptionsQuantidade}
        />
      </FormRow>

      <FormRow>
        <InputSelect
          label="Esgoto"
          name="esgoto"
          options={InputSelectOptionsSimNao}
        />
        <InputSelect
          label="Tipo de Rua"
          name="tiporua"
          options={InputSelectOptionsCaptacaoImovelTipoRua}
        />
        <InputSelect
          label="Placa"
          name="placa"
          options={InputSelectOptionsSimNao}
        />
      </FormRow>
      <FormRow style={isTerreno ? { display: 'none' } : {}}>
        <InputSelect
          label="Vaga de Garagem"
          name="vaga"
          options={InputSelectOptionsCaptacaoImovelTipoVaga}
          hide={isTerreno}
        />
        <InputSelect
          label="Laje"
          name="laje"
          options={InputSelectOptionsSimNao}
          hide={isTerreno}
        />

        <InputSelect
          label="Tipo de Piso"
          name="tipopiso"
          options={InputSelectOptionsCaptacaoImovelTipoPiso}
          hide={isTerreno}
        />
        <InputSelect
          label="Tipo de Forro"
          name="tipoforro"
          options={InputSelectOptionsCaptacaoImovelTipoForro}
          hide={isTerreno}
        />

        <InputSelect
          label="Posição do Sol"
          name="posicaosol"
          options={InputSelectOptionsCaptacaoImovelPosicaoSol}
          hide={isTerreno}
        />
      </FormRow>
      <FormRow
        grid
        gridColumns={{ default: '6', desktop: '6', tablet: '4', phone: '2' }}
        style={isTerreno ? { display: 'none' } : {}}
      >
        <InputCheckbox name="churrasqueira" label="Churrasqueira" />
        <InputCheckbox name="lavabo" label="Lavabo" />
        <InputCheckbox name="piscina" label="Piscina" />
        <InputCheckbox name="dispensa" label="Dispensa" />
        <InputCheckbox name="areadeservico" label="Área de Serviço" />
        <InputCheckbox name="elevador" label="Elevador" />
        <InputCheckbox name="lavanderia" label="Lavanderia" />
        <InputCheckbox name="hidro" label="Hidro" />
        <InputCheckbox name="cercaeletrica" label="Cerca Elétrica" />
        <InputCheckbox name="concertina" label="Concertina" />
        <InputCheckbox name="energiasolar" label="Energia Solar" />
      </FormRow>
      <FormRow>&nbsp;</FormRow>
      <FormRow style={isTerreno ? { display: 'none' } : {}}>
        <Input name="apartamentoporandar" label="Apt. por Andar" />
        <Input name="apartamentonumandar" label="Nº de Andares" />
      </FormRow>

      <FormRow>
        <InputSelect
          label="Exclusividade"
          name="exclusividade"
          options={InputSelectOptionsSimNao}
        />
        <Input name="exclusividadeprazo" label="Prazo de Exclusividade" />
        <InputSelect
          label="Ocupado por"
          name="ocupado"
          options={InputSelectOptionsCaptacaoImovelOcupado}
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Chave da Propriedade</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputSelect
          label="Local das Chaves"
          name="localchaves"
          options={InputSelectOptionsCaptacaoImovelLocalChaves}
        />
        <Input
          name="contatoresponsavelchave"
          label="Nome e contato do reponsável"
        />
      </FormRow>

      <FormGroupSeparator style={isTerreno ? { display: 'none' } : {}}>
        <span>Armários</span>
        <hr />
      </FormGroupSeparator>
      <FormRow
        grid
        gridColumns={{ default: '6', desktop: '6', tablet: '3', phone: '2' }}
        style={isTerreno ? { display: 'none' } : {}}
      >
        <InputCheckbox name="armariodormitorio" label="Dormitório" />
        <InputCheckbox name="armariosuites" label="Suítes" />
        <InputCheckbox name="armariosala" label="Sala" />
        <InputCheckbox name="armariocozinha" label="Cozinha" />
        <InputCheckbox name="armariowc" label="WC" />
        <InputCheckbox name="armarioedicula" label="Edícula" />
      </FormRow>

      <FormGroupSeparator>
        <span>Área</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <Input name="areacontruida" label="Área Construída (m²)" />
        <Input name="areautil" label="Útil (m²)" />
        <Input name="areacomum" label="Comum (m²)" />
        <Input name="areaterreno" label="Terreno (m²)" />
        <Input name="areafrente" label="Frente" />
        <Input name="areafundo" label="Fundo" />
        <Input name="arealateraldir" label="Lateral Dir." />
        <Input name="arealateralesq" label="Lateral Esq." />
      </FormRow>

      <FormGroupSeparator>
        <span>Documentação</span>
        <hr />
      </FormGroupSeparator>
      <FormRow
        grid
        gridColumns={{ default: '4', desktop: '4', tablet: '2', phone: '1' }}
      >
        <InputCheckbox name="docfinanciamento" label="Aceita Financiamento" />
        <InputCheckbox name="docfgts" label="Aceita FGTS" />
        <InputCheckbox name="docsomenteavista" label="Somente a Vista" />
        <InputCheckbox
          name="docpapliacao"
          label="Possuí planta Apliação/Agio"
          hide={isTerreno}
        />
      </FormRow>
      <FormRow>
        <InputSelect
          label="É Averbado ?"
          name="docaverbado"
          options={InputSelectOptionsSimNao}
          hide={isTerreno}
        />
        <InputSelect
          label="É registrado ?"
          name="docregistrado"
          options={InputSelectOptionsSimNao}
        />
      </FormRow>
      <FormRow>
        <InputTextarea
          label="Observações / Confidêncial"
          name="obsconfidencial"
          rows={5}
        />
      </FormRow>

      <FormGroupSeparator>
        <span>Descrição do Imóvel / Texto para Divulgação na Internet</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputTextarea name="descricaoimovel" rows={5} />
      </FormRow>

      <FormGroupSeparator>
        <span>Anexos</span>
        <hr />
      </FormGroupSeparator>

      <FormRowStrippedWrapper>
        {captacao?.arquivos && (
          <>
            <Table<IApiCaptacaoImovelArquivoData & { opcoes: string }>
              headers={{
                nome: 'Arquivo',
                categoria: 'Categoria',
                url: 'Visualizar',
                opcoes: 'Opções'
              }}
              columnSizes={{
                nome: '10rem',
                categoria: '10rem',
                url: '5rem',
                opcoes: '2rem'
              }}
              items={captacao.arquivos}
              customRenderers={{
                url: (obj) => {
                  if (obj.nome) {
                    return (
                      <a
                        href={`/api/arquivos/${obj.nome}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Clique Aqui
                      </a>
                    )
                  }
                },
                categoria: (obj) => {
                  return getLabelFromOptions(
                    InputSelectOptionsFileCaptacaoImovel,
                    obj.categoria
                  )
                },
                opcoes: (obj) => (
                  <TableButtonWrapper>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        if (obj.id) handleDeleteFile(obj.id)
                      }}
                    >
                      <FaTrash />
                    </Button>
                  </TableButtonWrapper>
                )
              }}
            />
          </>
        )}
        <InputFileSelectMultiplo
          inputFile={{ name: 'arquivos', label: 'Arquivo' }}
          inputSelect={{ name: 'categorias', label: 'Categoria' }}
          optionsSelect={InputSelectOptionsFileCaptacaoImovel}
        />
      </FormRowStrippedWrapper>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Imóvel</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default CaptacaoImovelForm
