import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaEraser, FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import {
  clearFiltroAluguel,
  setFiltroAluguel
} from '../../../../redux/modules/aluguel/actions'
import {
  IAluguelRedux,
  IFiltroAluguel
} from '../../../../redux/modules/aluguel/types'
import { Form, FormRow } from '../../../../styles/form'
import { Button } from '../../../../components/Button'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputDatePiker from '../../../../components/InputDatePiker'
import { FiltroWrapper } from './styles'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import Api from '../../../../services/Api'
import { OptionTypeBase } from '../../../../config/global'

interface IAluguelFiltroProps {
  callback?(): void
}
const AluguelFiltro: React.FC<IAluguelFiltroProps> = ({ callback }, ref) => {
  const formRef = useRef<FormHandles>(null)
  const aluguel = useSelector<ReduxStore, IAluguelRedux>(
    (state) => state.aluguel
  )
  const [corretor, setCorretor] = useState<OptionTypeBase | null>()
  const [proprietario, setProprietario] = useState<OptionTypeBase | null>()
  const [inquilino, setInquilino] = useState<OptionTypeBase | null>()

  const dispatch = useDispatch()

  const handleClear = useCallback(() => {
    dispatch(clearFiltroAluguel())
    if (callback) callback()
  }, [callback, dispatch])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCliente = useCallback(async (inputValue?: string) => {
    return Api.cliente
      .search({ coluna: 'nome', valor: inputValue })
      .then((data) => {
        const arrayData = data.map((cliente) => ({
          label: cliente.nome,
          value: cliente.id
        }))
        return arrayData
      })
  }, [])

  const handleSubmit: SubmitHandler<IFiltroAluguel> = async (
    data,
    { reset },
    event
  ) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      // Remove all previous errors
      formRef.current.setErrors({})

      dispatch(
        setFiltroAluguel({
          ...data,
          corretor,
          proprietario,
          inquilino,
          page: 1
        })
      )
      if (callback) callback()
      reset()
    } catch (err) {}
  }

  useEffect(() => {
    setCorretor(aluguel.pesquisa.filtro.corretor)
    setProprietario(aluguel.pesquisa.filtro.proprietario)
    setInquilino(aluguel.pesquisa.filtro.inquilino)
    formRef.current?.setData(aluguel.pesquisa.filtro)
  }, [aluguel.pesquisa.filtro])

  return (
    <FiltroWrapper>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormRow>
          <InputDatePiker name="dataini" label="Data Inicial Cadastro" />
          <InputDatePiker name="datafim" label="Data Final Cadastro" />
          <InputSelectAsync
            label="Corretor"
            name="corretor"
            onChangeOption={(value) => setCorretor(value)}
            asyncLoadOptions={handleSearchCorretor}
          />
          <InputCheckbox name="naofinalizadas" label="Apenas não Finalizadas" />
          {/* <InputSelect name="tipo" label="Tipo de Usuário" options={[]} /> */}
        </FormRow>
        <FormRow>
          <InputSelectAsync
            label="Proprietario"
            name="proprietario"
            onChangeOption={(value) => setProprietario(value)}
            asyncLoadOptions={handleSearchCliente}
          />{' '}
          <InputSelectAsync
            label="Inquilino"
            name="inquilino"
            onChangeOption={(value) => setInquilino(value)}
            asyncLoadOptions={handleSearchCliente}
          />
        </FormRow>
        <FormRow buttons>
          <Button type="button" variant="danger" onClick={() => handleClear()}>
            <FaEraser />
            <span>Limpar Filtro</span>
          </Button>

          <Button type="submit">
            <FaSearch />
            <span>Pesquisar</span>
          </Button>
        </FormRow>
      </Form>
    </FiltroWrapper>
  )
}

export default AluguelFiltro
