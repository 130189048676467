import { useEffect, useState } from 'react'
import Api from '../../services/Api'
import { IApiAluguelDashboardResumo } from '../../services/ApiRequests/Aluguel/interfaces'
import { IApiVendaDashboardResumo } from '../../services/ApiRequests/Venda/interfaces'
import { CardHeader } from '../../styles/Card'
import * as S from './styles'

const Main = () => {
  const [vendas, setVendas] = useState<IApiVendaDashboardResumo>()
  const [alugueis, setAlugueis] = useState<IApiAluguelDashboardResumo>()

  useEffect(() => {
    Api.venda.getDashboardResumo().then((data) => setVendas(data))
    Api.aluguel.getDashboardResumo().then((data) => setAlugueis(data))
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Dashboard</h1>
      </CardHeader>
      <S.DashWrapper>
        <S.DashGroup>
          <h4>Resumo das Vendas</h4>
          <S.DashBox>
            <S.DashCard>
              <header>Pedido de Certidões</header>
              <section>{vendas?.pedidocertidao}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Contrato particular Assinado</header>
              <section>{vendas?.contratoparticularassinado}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Banco analisando documentação/Avaliação</header>
              <section>{vendas?.bancoanalisedoc}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Banco gerando Contrato</header>
              <section>{vendas?.bancogerandocontrato}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Enviando para Escritura/Financiamento</header>
              <section>{vendas?.escritfinanassinado}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Recolhendo ITBI</header>
              <section>{vendas?.recolheuitbi}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Registrando</header>
              <section>{vendas?.registrando}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Contrato Cancelado</header>
              <section>{vendas?.cancelado}</section>
            </S.DashCard>
            <S.DashCard boxcolor="#97cea9">
              <header>Finalizado</header>
              <section>{vendas?.finalizado}</section>
            </S.DashCard>
          </S.DashBox>
        </S.DashGroup>
        <S.DashGroup>
          <h4>Resumo dos Alugueis</h4>
          <S.DashBox>
            <S.DashCard>
              <header>Assinatura Contrato</header>
              <section>{alugueis?.assinaturacontrato || 0}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Aguardando transferir Água e energia</header>
              <section>{alugueis?.aguardandotransfaguaenergia || 0}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Aguardando pagamento de aluguel</header>
              <section>{alugueis?.aguardandopagamento || 0}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Retirar chaves</header>
              <section>{alugueis?.retirarchaves || 0}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Contrato Cancelado</header>
              <section>{alugueis?.cancelado || 0}</section>
            </S.DashCard>
            <S.DashCard boxcolor="#97cea9">
              <header>Finalizado</header>
              <section>{alugueis?.finalizado || 0}</section>
            </S.DashCard>
          </S.DashBox>
        </S.DashGroup>
      </S.DashWrapper>
    </>
  )
}

export default Main
