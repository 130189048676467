import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import { IApiMetaData, IApiMetasSearchParams } from './interfaces'


class Metas extends BaseRequests {
  constructor() {
    super('metas')
  }

  async getAll(
    filter?: IApiMetasSearchParams
  ): Promise<IApiMetaData[]> {
    return await this.sendGet<IApiMetaData[]>({
      params: { ...filter }
    })
  }

  async getById(id: string | number): Promise<IApiMetaData> {
    return this.sendGet<IApiMetaData>({ url: `${id}` })
  }


  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Meta...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Meta...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Meta...')
    return this.sendDelete({ param: `${id}` })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
}

export default Metas
