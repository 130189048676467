import React from 'react'
import { FormWrapperColumn } from '../../styles/form'
import { InputCheckbox } from '../InputCheckbox'
import InputCurrency from '../InputCurrency'

type InputsProps = {
  name: string
  label: string
}

interface IInputFileSelectProps {
  inputCheck: InputsProps
  inputCurrency: InputsProps
  width?: string
}

const InputCheckboxCurrency: React.FC<IInputFileSelectProps> = ({
  inputCheck,
  inputCurrency,
  width
}) => {
  return (
    <FormWrapperColumn width={width}>
      <InputCheckbox name={inputCheck.name} label={inputCheck.label} />
      <InputCurrency name={inputCurrency.name} label={inputCurrency.label} />
    </FormWrapperColumn>
  )
}

export default InputCheckboxCurrency
