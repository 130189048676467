import React from 'react'
import { IApiVendaFinanciamento } from '../../../../services/ApiRequests/Venda/interfaces'

import LabelDetalhes from './LabelDetalhes'

interface IDetalhesFinanciamentoProps {
  financiamento: IApiVendaFinanciamento
}

const DetalhesFinanciamento = ({
  financiamento
}: IDetalhesFinanciamentoProps) => {
  return (
    <>
      <section>
        <LabelDetalhes
          label="Interveniente Quitante"
          text={`${financiamento.interveniente ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Valor Aprovado"
          value={financiamento?.valoraprovado}
        />
      </section>
      <h4>Etapas</h4>
      <section>
        <LabelDetalhes
          label="Em Vistoria"
          text={`${financiamento.vistoria ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Gerando Contrato"
          text={`${financiamento.gerandocontrato ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Contrato Assinado"
          text={`${financiamento.contratoassinado ? 'Sim' : 'Não'}`}
        />
      </section>
      <section>
        <LabelDetalhes
          label="Recolhendo ITBI"
          text={`${financiamento.recolhendoitbi ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Registrando"
          text={`${financiamento.registrando ? 'Sim' : 'Não'}`}
        />
        <LabelDetalhes
          label="Retornou para Banco Pagar"
          text={`${financiamento.retornobanco ? 'Sim' : 'Não'}`}
        />
      </section>
      <section>
        <LabelDetalhes
          label="Finalizado"
          text={`${financiamento.finalizado ? 'Sim' : 'Não'}`}
        />
      </section>
    </>
  )
}

export default DetalhesFinanciamento
