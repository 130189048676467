import React, { useRef, useEffect } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'
import { customStylesReactSelect } from '../../styles/react-select.style'

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
  width?: string
  hide?: boolean
}

const InputSelect: React.FC<Props> = ({
  name,
  label,
  hide = false,
  width,
  ...rest
}) => {
  const selectRef = useRef(null)
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        }
        if (!ref.state.value) {
          return null
        }
        return ref.state.value.value
      },
      setValue: (ref: any, value: any) => {
        if (rest.isMulti && Array.isArray(value)) {
          const items = ref?.props?.options?.filter((option: any) =>
            value.includes(option.value)
          )
          ref?.select.setValue(items)
        } else {
          const item = ref?.props?.options?.find(
            (option: any) => option.value === value
          )
          if (item) {
            ref?.select?.setValue(item)
          }
        }
      },
      clearValue: (ref) => {
        ref.select.clearValue()
      }
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <FormGroup width={width} style={hide ? { display: 'none' } : {}}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          placeholder="Opções"
          noOptionsMessage={() => 'Sem opções'}
          styles={customStylesReactSelect}
          isClearable={true}
          onFocus={clearError}
          {...rest}
        />
      </div>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default InputSelect
