import BaseRequests from '../BaseRequests'
import IApiNotificacaoSearch, {
  IApiNotificacaoSearchRequest,
  IApiNotificacaoUpdate
} from './interfaces'

class NotificacaoRequests extends BaseRequests {
  constructor() {
    super('notificacao')
  }

  async get(
    filter?: IApiNotificacaoSearch
  ): Promise<IApiNotificacaoSearchRequest> {
    return await this.sendGet<IApiNotificacaoSearchRequest>({
      params: { ...filter }
    })
  }

  async update(data: IApiNotificacaoUpdate): Promise<void> {
    return this.sendPut({ data })
  }
}

export default NotificacaoRequests
