export enum TypeFiltroMetas {
  SET_FILTRO = 'FILTRO_METAS_SET',
  CLEAR_FILTRO = 'FILTRO_METAS_CLEAR',
  MODAL_SHOW = 'FILTRO_METAS_MODAL_SHOW',
  MODAL_HIDE = 'FILTRO_METAS_MODAL_HIDE'
}

export interface IFiltroMetas {
  mes?: number
  ano?: number
  corretorid?: number
}

export interface IMetasRedux {
  pesquisa: {
    modalShow: boolean
    filtro: IFiltroMetas
  }
}

export interface MetasSetFiltro {
  type: TypeFiltroMetas.SET_FILTRO
  filtro: IFiltroMetas
}

interface MetasClearFiltro {
  type: TypeFiltroMetas.CLEAR_FILTRO
}

interface MetasModalShow {
  type: TypeFiltroMetas.MODAL_SHOW
}

interface MetasModalHide {
  type: TypeFiltroMetas.MODAL_HIDE
}

export type ActionFiltroMetas =
  | MetasSetFiltro
  | MetasClearFiltro
  | MetasModalShow
  | MetasModalHide
