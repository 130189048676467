import React, { useCallback, useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useRouteMatch
} from 'react-router-dom'
import Dashboard from '../modules/dashboard/pages/Dashboard'
import { IMenuProps } from './interface'
import { getPrivateRoutesConfig } from '../config/PrivateRoutesConfig'
import { filterAllowedRoutes } from '../utils/getAllowedRoutes'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxStore } from '../redux'
import { IUsuarioRedux } from '../redux/modules/usuario/types'
import { authGet } from '../hooks/auth'
import { clearUsuario, setUsuario } from '../redux/modules/usuario/actions'
import { getRole } from '../config/Roles'
import Api from '../services/Api'

const DefaultComponent = () => <div>{`Nenhum componente definido.`}</div>

const DashBoardRoutes = ({ history }: RouteComponentProps) => {
  const [privateRoutes, setPrivateRoutes] = useState<IMenuProps[]>([])

  let { path } = useRouteMatch()
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const dispatch = useDispatch()
  const role = getRole(usuario.tipo)

  useEffect(() => {
    if (!usuario.id) {
      const user = authGet()
      if (user?.usuario.id) {
        dispatch(setUsuario(user))
      } else {
        dispatch(clearUsuario())
        history.push('/')
      }
    }
  }, [dispatch, history, usuario])

  const handlePrivateRoutes = useCallback(async () => {
    const ativos = await Api.empeendimentoDisponivel.getAllActives()
    const menuRoutes = getPrivateRoutesConfig(path, ativos)
    const routes = filterAllowedRoutes([role], menuRoutes)
    setPrivateRoutes(routes)
  }, [path, role])

  useEffect(() => {
    handlePrivateRoutes()
  }, [handlePrivateRoutes])

  return (
    <Dashboard menu={privateRoutes}>
      <Switch>
        {privateRoutes?.map(({ routes }: IMenuProps) =>
          routes.map((route) =>
            route.subRoutes ? (
              route.subRoutes.map((item) => (
                <Route
                  key={`${route.to}${item.to}`}
                  path={`${route.to}${item.to}`}
                  component={item.component || DefaultComponent}
                  exact
                />
              ))
            ) : (
              <Route
                key={`${route.to}`}
                path={`${route.to}`}
                component={route.component || DefaultComponent}
                exact
              />
            )
          )
        )}
        <Route>
          <Redirect to={path} />
        </Route>
      </Switch>
    </Dashboard>
  )
}

export default DashBoardRoutes
