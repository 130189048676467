import React, { useRef, useCallback, useState, useEffect } from 'react'
import { FaCogs } from 'react-icons/fa'
import { BadgeCaptacaImovelStatus, BadgeText, BadgeWrapper } from './styles'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Button } from '../../../../components/Button'
import { RoleType } from '../../../../config/Roles'
import CaptacaoImovelStatusForm from '../../forms/CaptacaoImovelStatusForm'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsCaptacaoImovelStatus } from '../../../../config/InputSelectConfig'
import { alertShow } from '../../../../utils/sweetAlert'

interface ICaptacaoImovelStatusData {
  id: number
  status?: string
}

interface ICaptacaoImovelStatusProps {
  dados?: ICaptacaoImovelStatusData
  userRole?: RoleType
}

const CaptacaoImovelStatus: React.FC<ICaptacaoImovelStatusProps> = ({
  dados,
  userRole
}) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [data, setData] = useState<ICaptacaoImovelStatusData>()
  const [status, setStatus] = useState<string>()
  const [corBadge, setCorBadge] = useState<string>()

  const handleStatus = useCallback((newStatus?: string) => {
    setStatus(
      getLabelFromOptions(InputSelectOptionsCaptacaoImovelStatus, newStatus)
    )

    setCorBadge('#c72901')

    //Cadastro Concluído
    if (newStatus === '1') {
      setCorBadge('#2dc000')
    }

    //Faltando Documentos
    if (newStatus === '0') {
      setCorBadge('#f56b18')
    }
  }, [])

  const handleOnCloseModal = useCallback(
    (update?: ICaptacaoImovelStatusData) => {
      if (update) {
        setData(update)
        handleStatus(update?.status)
        if (update?.status === '1')
          alertShow(
            '',
            'Parabéns imóvel cadastrado! \n\nAgora envie no grupo para que todos os corretores fiquem sabendo!'
          )
      }
    },
    [handleStatus]
  )

  const handleEdit = useCallback(() => {
    if (dados?.id) modalRef.current?.openModal()
  }, [dados?.id])

  useEffect(() => {
    setData(dados)
    handleStatus(dados?.status)
  }, [dados, handleStatus])

  return (
    <>
      <BadgeWrapper>
        {status && (
          <BadgeCaptacaImovelStatus color={corBadge}>
            <BadgeText>{status}</BadgeText>
          </BadgeCaptacaImovelStatus>
        )}
        {userRole !== 'CORRETOR' && (
          <Button onClick={() => handleEdit()} variant="secondary">
            <FaCogs />
          </Button>
        )}
      </BadgeWrapper>

      <Modal ref={modalRef} title={`Alteração de Status da Captacao de Imóvel`}>
        <CaptacaoImovelStatusForm
          callback={(update) => {
            modalRef.current?.closeModal()
            handleOnCloseModal(update)
          }}
          status={data}
        />
      </Modal>
    </>
  )
}

export default CaptacaoImovelStatus
