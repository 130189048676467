import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import { InputTextarea } from '../../../../components/InputTextarea'
import Api from '../../../../services/Api'
import { IApiAluguelVistoria } from '../../../../services/ApiRequests/Aluguel/interfaces'
import InputSelect from '../../../../components/InputSelect'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'
import { IFormCallback } from '../../../../forms/interfaces'

interface IAluguelVistoriaFormProps extends IFormCallback {
  vistoria?: IApiAluguelVistoria
}

const AluguelVistoriaForm: React.FC<IAluguelVistoriaFormProps> = ({
  vistoria,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [visId, setVisId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await Api.aluguel.updateVistoria({ id: visId, ...data })
      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!vistoria?.id) {
      return callback?.()
    }

    const { id, ...rest } = vistoria
    setVisId(id)
    formRef.current.setData(rest)
  }, [callback, vistoria])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="vistoria" label="Vistoria" />
          <InputTextarea name="vistoriaobs" />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox name="assinatura" label="Assinatura" />
          <InputSelect
            label="Tipo de Assinatura"
            name="assinaturatipo"
            options={InputSelectOptionsAssinaturaTipo}
            width="15rem"
          />
        </FormWrapperColumn>
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default AluguelVistoriaForm
