import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'

import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import { InputTextarea } from '../../../../components/InputTextarea'
import Api from '../../../../services/Api'
import { IApiAluguelJuridico } from '../../../../services/ApiRequests/Aluguel/interfaces'
import InputSelect from '../../../../components/InputSelect'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'
import { IFormCallback } from '../../../../forms/interfaces'

interface IAluguelJuridicoFormProps extends IFormCallback {
  juridico?: IApiAluguelJuridico
}

const AluguelJuridicoForm: React.FC<IAluguelJuridicoFormProps> = ({
  juridico,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [jurId, setJurId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await Api.aluguel.updateJuridico({ id: jurId, ...data })
      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!juridico?.id) {
      return callback?.()
    }

    const { id, ...rest } = juridico
    setJurId(id)
    formRef.current.setData(rest)
  }, [callback, juridico])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="docinquilino" label="Documento do Inquilino" />
          <InputTextarea name="docinquilinoobs" />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox name="docfiador" label="Documento do Fiador" />
          <InputTextarea name="docfiadorobs" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox
            name="assinaturacontrato"
            label="Assinatura de Contrato"
          />
          <InputSelect
            label="Tipo de Assinatura"
            name="assinaturacontratotipo"
            options={InputSelectOptionsAssinaturaTipo}
            width="15rem"
          />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox
            name="notificarmarketing"
            label="Notificar Marketing"
          />
          <InputTextarea name="notificarmarketingobs" />
        </FormWrapperColumn>
        <FormWrapperColumn>
          <InputCheckbox
            name="digitalizoudocs"
            label="Digitalizar Documentos em uma Pasta"
          />
          <InputTextarea name="digitalizoudocsobs" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default AluguelJuridicoForm
