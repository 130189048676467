import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const Box = styled.div`
  width: 35rem;
  height: 35rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 40px rgb(0 0 0 / 5%);

  animation: ${appearFromLeft} 1s;

  header {
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  main {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  form {
    width: 100%;
    padding: 2rem;
    label {
      font-size: 1.2rem;
    }
    &:last-child div {
      display: flex;
      justify-content: center;
    }
  }
`
