import React, { useCallback, useRef, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../components/Button'

import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import VendasPesquisa from '../../components/VendasPesquisa'
import VendaJuridicoForm from '../../forms/VendaJuridico'
import { modalFiltroHandler } from '../../../../redux/modules/vendas/actions'
import Api from '../../../../services/Api'
import { IApiVendaJuridico } from '../../../../services/ApiRequests/Venda/interfaces'
import { CardBox, CardHeader } from '../../../../styles/Card'

const VendasJuridico = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const dispatch = useDispatch()

  const [data, setData] = useState<IApiVendaJuridico>({} as IApiVendaJuridico)
  const [vendaid, setVendaId] = useState<number | null>()

  const handleEdit = useCallback((id?: number) => {
    setData({} as IApiVendaJuridico)
    setVendaId(null)

    if (!id) return
    Api.venda.getJuridico(id).then((res) => {
      setVendaId(id)
      setData(res)
      modalRef.current?.openModal()
    })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Vendas Jurídico</h1>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Vendas</h4>
          <Button
            variant="secondary"
            onClick={() => dispatch(modalFiltroHandler(true))}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <VendasPesquisa
            opcoesRender={(it) => (
              <>
                <Button variant="primary" onClick={() => handleEdit(it.id)}>
                  <FiEdit />
                </Button>
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal ref={modalRef} title={`Controle Jurídico da venda ${vendaid}`}>
        <VendaJuridicoForm
          juridico={data}
          callback={() => modalRef.current?.closeModal()}
        />
      </Modal>
    </>
  )
}

export default VendasJuridico
