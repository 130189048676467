import AluguelRequests from './Aluguel'
import AuthRequests from './AuthRequests'
import CaptacaoImagensRequests from './CaptacaoImagens'
import CaptacaoImovelRequests from './CaptacaoImovel'
import ClienteRequests from './ClienteRequests'
import EmpreendimentosDisponivel from './EmpreendimentoDisponivel'
import FinanciamentosRequests from './Financiamentos'
import Metas from './Metas'
import MunicipioRequests from './Municipio'
import NotificacaoRequests from './NotificacaoRequests'
import UsuarioRequests from './UsuarioRequests'
import VendaRequests from './Venda'

class ApiRequests {
  public cliente: ClienteRequests
  public auth: AuthRequests
  public municipio: MunicipioRequests
  public usuario: UsuarioRequests
  public venda: VendaRequests
  public notificacao: NotificacaoRequests
  public aluguel: AluguelRequests
  public captacaoImovel: CaptacaoImovelRequests
  public empeendimentoDisponivel: EmpreendimentosDisponivel
  public metas: Metas
  public captacaoImagens: CaptacaoImagensRequests
  public financiamentos: FinanciamentosRequests

  constructor() {
    this.cliente = new ClienteRequests()
    this.auth = new AuthRequests()
    this.municipio = new MunicipioRequests()
    this.usuario = new UsuarioRequests()
    this.venda = new VendaRequests()
    this.notificacao = new NotificacaoRequests()
    this.aluguel = new AluguelRequests()
    this.captacaoImovel = new CaptacaoImovelRequests()
    this.empeendimentoDisponivel = new EmpreendimentosDisponivel()
    this.metas = new Metas()
    this.captacaoImagens = new CaptacaoImagensRequests()
    this.financiamentos = new FinanciamentosRequests()
  }
}

export default ApiRequests
