import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import { Form, FormRow, FormWrapperColumn } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'

import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import InputSelect from '../../../../components/InputSelect'
import { InputTextarea } from '../../../../components/InputTextarea'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'
import { IApiVendaJuridico } from '../../../../services/ApiRequests/Venda/interfaces'
import Api from '../../../../services/Api'
import { IFormCallback } from '../../../../forms/interfaces'

interface IVendaJuridicoFormProps extends IFormCallback {
  juridico?: IApiVendaJuridico
}

const VendaJuridicoForm: React.FC<IVendaJuridicoFormProps> = ({
  juridico,
  callback
}) => {
  const formRef = useRef<FormHandles>(null)
  const [jurId, setJurId] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      //console.log({ id: jurId, ...data })
      await Api.venda.updateJuridico({ id: jurId, ...data })
      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return
    if (!juridico?.id) {
      return callback?.()
    }

    const { id, ...rest } = juridico
    setJurId(id)
    formRef.current.setData(rest)
  }, [callback, juridico])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="docimovel" label="Documento do Imóvel" />
          <InputTextarea name="docimovelobs" />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox name="doccomprador" label="Documento do Comprador" />
          <InputTextarea name="doccompradorobs" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox name="docvendedor" label="Documento do Vendedor" />
          <InputTextarea name="docvendedorobs" />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox
            name="confeccaocontrato"
            label="Confecção de Contrato Compra e Venda"
          />
          <InputTextarea name="confeccaocontratoobs" />
        </FormWrapperColumn>
      </FormRow>

      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox
            name="conferenciacontrato"
            label="Conferência de Contrato"
          />
        </FormWrapperColumn>
        <FormWrapperColumn>
          <InputCheckbox
            name="assinaturacontrato"
            label="Assinatura de Contrato"
          />
          <InputSelect
            label="Tipo de Assinatura"
            name="assinaturacontratotipo"
            options={InputSelectOptionsAssinaturaTipo}
            width="15rem"
          />
        </FormWrapperColumn>
      </FormRow>

      <FormRow>
        <FormWrapperColumn>
          <InputCheckbox
            name="notificoumarketing"
            label="Notificar Marketing para remover anúncio"
          />
          <InputTextarea name="notificoumarketingobs" />
        </FormWrapperColumn>

        <FormWrapperColumn>
          <InputCheckbox
            name="digitalizoudocs"
            label="Digitalizar Documentos em uma Pasta"
          />
          <InputTextarea name="digitalizoudocsobs" />
        </FormWrapperColumn>
      </FormRow>
      <FormWrapperColumn>
        <InputCheckbox
          name="entradaescritura"
          label="Deu entrada na Escritura"
        />
        <InputCheckbox name="entradaitbi" label="Deu entrada no ITBI" />
        <InputCheckbox name="entradaregistro" label="Deu entrada no Registro" />
      </FormWrapperColumn>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default VendaJuridicoForm
