import React from 'react'
import { Table } from '../../../../components/Table'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsFile } from '../../../../config/InputSelectConfig'
import { IApiAluguelArquivoData } from '../../../../services/ApiRequests/Aluguel/interfaces'

interface IDetalhesArquivosProps {
  exibeTitulo?: boolean
  anexos?: IApiAluguelArquivoData[]
}

const DetalhesArquivos = ({
  anexos,
  exibeTitulo = true
}: IDetalhesArquivosProps) => {
  if (!anexos || anexos.length <= 0) return <></>

  return (
    <>
      {exibeTitulo && <h4>Arquivos Anexados</h4>}
      <section>
        <Table<IApiAluguelArquivoData>
          headers={{
            nome: 'Arquivo',
            categoria: 'Categoria',
            url: 'Visualizar'
          }}
          columnSizes={{
            nome: '15rem',
            categoria: '10rem',
            url: '5rem'
          }}
          items={anexos}
          customRenderers={{
            url: (obj) => {
              if (obj.nome) {
                return (
                  <a
                    href={`/api/arquivos/${obj.nome}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Clique Aqui
                  </a>
                )
              }
            },
            categoria: (obj) => {
              return getLabelFromOptions(InputSelectOptionsFile, obj.categoria)
            }
          }}
        />
      </section>
    </>
  )
}

export default DetalhesArquivos
