import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import ReactInputMask, { Props as InputProps } from 'react-input-mask'
import { FormGroup } from '../../styles/form'

// import * as S from './styles'

interface Props extends InputProps {
  name: string
  label?: string
}

const InputMask: React.FC<Props> = ({ name, label, width, ...rest }) => {
  const inputRef = useRef<ReactInputMask>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        if (value) ref.setInputValue(value)
      },
      clearValue(ref: any) {
        ref.setInputValue('')
      }
    })
  }, [fieldName, registerField])

  return (
    <FormGroup width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        <ReactInputMask
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          onFocus={clearError}
          {...rest}
        />
      </div>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default InputMask
