import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiClienteData,
  IApiClienteSearchData,
  IApiClienteSearchFiltro
} from './interfaces'

class ClienteRequests extends BaseRequests {
  constructor() {
    super('clientes')
  }

  async search(
    filtro?: IApiClienteSearchFiltro
  ): Promise<IApiClienteSearchData[]> {
    return this.sendGet<IApiClienteSearchData[]>({ params: { ...filtro } })
  }

  async getById(id: number): Promise<IApiClienteData> {
    return this.sendGet<IApiClienteData>({ url: `${id}` })
  }

  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Cliente...')
    return this.sendPost({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Cliente...')
    return this.sendPut({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Cliente...')
    return this.sendDelete({ param: `${id}` })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
}

export default ClienteRequests
