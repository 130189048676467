import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

import { CardBox, CardHeader } from '../../../../styles/Card'
import * as S from './styles'

import LabelDetalhes from './LabelDetalhes'
import Api from '../../../../services/Api'
import { Button } from '../../../../components/Button'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { IApiFinanciamentosData } from '../../../../services/ApiRequests/Financiamentos/interfaces'
import FinanciamentoStatus from '../../components/FinanciamentoStatus'

interface RouteParams {
  id: string
}

const FinanciamentosDetalhes = () => {
  const history = useHistory()
  const params = useParams<RouteParams>()
  const [data, setData] = useState<IApiFinanciamentosData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const loadRef = useRef<ILoadingHandles>(null)

  const handleLoading = useCallback((show: boolean) => {
    if (show) loadRef.current?.showLoading()
    if (!show) loadRef.current?.hideLoading()
    setIsLoading(show)
  }, [])

  useEffect(() => {
    if (!!params.id) {
      handleLoading(true)

      Api.financiamentos
        .getById(params.id)
        .then((res) => {
          setData(res)
          handleLoading(false)
        })
        .catch(() => {
          history.push('/dashboard/financiamentos')
        })
    }
  }, [handleLoading, history, params.id])

  return (
    <div>
      <CardHeader>
        <h1>Detalhes do Financiamento</h1>
        <div className="print-hide"></div>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Financiamento de Código {params.id}</h4>
          <div className="print-hide">
            <Button onClick={() => history.goBack()}>
              <FaChevronLeft />
              Voltar
            </Button>
          </div>
        </header>
        <div>
          <Loading ref={loadRef} />

          {!isLoading && (
            <div id="printme">
              <S.WrapperVendaDetalhes>
                <section>
                  <LabelDetalhes label="Cliente" text={data?.cliente.nome} />
                  <LabelDetalhes label="Corretor" text={data?.corretor.nome} />
                  <LabelDetalhes label="Cadastro" dateTime={data?.created_at} />
                  <FinanciamentoStatus dados={data} />
                </section>

                <h4>Detalhes</h4>
                <section>
                  <LabelDetalhes
                    label="Código do Imóvel"
                    text={data?.cod_imovel}
                  />
                  <LabelDetalhes
                    label="Valor Financiamento"
                    value={data?.valor_financ}
                  />
                  <LabelDetalhes
                    label="Valor de Entrada"
                    value={data?.valor_entrada}
                  />
                  <LabelDetalhes
                    label="Cidade do Imóvel"
                    text={
                      data?.cidade?.nome
                        ? `${data?.cidade?.nome} - ${data?.cidade?.uf}`
                        : ''
                    }
                  />
                </section>
                <section>
                  <LabelDetalhes label="Situação" text={data?.situation} />
                </section>
                <h4>Dados Bancários</h4>
                <section>
                  <LabelDetalhes label="Banco" text={data?.banco} />
                  <LabelDetalhes
                    label="Nome do Gerente do banco"
                    text={data?.nome_gerente_banco}
                  />
                  <LabelDetalhes
                    label="Cidade do Banco Financiador"
                    text={
                      data?.cidade_banco?.nome
                        ? `${data?.cidade_banco?.nome} - ${data?.cidade_banco?.uf}`
                        : ''
                    }
                  />
                </section>

                <hr />
              </S.WrapperVendaDetalhes>
            </div>
          )}
        </div>
      </CardBox>
    </div>
  )
}

export default FinanciamentosDetalhes
