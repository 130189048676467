import React, { useState, useCallback, useEffect } from 'react'
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight
} from 'react-icons/fi'

import {
  PaginationWrapper,
  PaginationItem,
  PaginationList,
  PaginationHeader
} from './styles'

interface IPaginationProps {
  pageSize?: number
  totalCount?: number
  pageLimit?: number
  onPageChange: (pagina: number) => void
}

const Pagination = ({
  pageSize,
  totalCount,
  onPageChange
}: IPaginationProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [pages, setPages] = useState<number[]>()
  const [items, setItems] = useState<React.ReactNode[]>([])

  const changeCurrentPage = useCallback(
    (page: number) => {
      setCurrentPage(page)
      if (onPageChange) {
        onPageChange(page)
      }
    },
    [onPageChange]
  )

  useEffect(() => {
    setItems([])
    if (pages?.length) {
      const list = pages.map((value) => (
        <PaginationItem
          key={value}
          active={value === currentPage}
          onClick={() => {
            if (value !== currentPage) changeCurrentPage(value)
          }}
        >
          {value}
        </PaginationItem>
      ))

      setItems(list)
    }
  }, [changeCurrentPage, currentPage, pages])

  useEffect(() => {
    const neighbors = 2

    if (totalPages <= neighbors * 2 + 1) {
      return setPages(Array.from({ length: totalPages }, (v, k) => k + 1))
    }

    const start = currentPage - neighbors >= 1 ? currentPage - neighbors : 1
    let end = 0
    if (start === 1) {
      end = neighbors * 2 + 2 >= totalPages ? totalPages : neighbors * 2 + 2
    } else {
      end =
        currentPage + neighbors <= totalPages
          ? currentPage + neighbors + 1
          : totalPages + 1
    }
    const list = Array.from({ length: end - start }, (_, k) => k + start)

    setPages(list)
  }, [currentPage, totalPages])

  useEffect(() => {
    if (!pageSize || pageSize === 0 || !totalCount || totalCount === 0)
      return setTotalPages(0)

    setTotalPages(Math.ceil(totalCount / pageSize))
  }, [pageSize, totalCount])

  const nextPage = () => {
    if (currentPage < totalPages) {
      changeCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      changeCurrentPage(currentPage - 1)
    }
  }

  const paginationRender = (
    <PaginationWrapper>
      <PaginationHeader>
        <span>{totalPages} páginas</span>
        <span>{totalCount} registros </span>
      </PaginationHeader>
      <div>
        <PaginationList>
          <PaginationItem
            disabled={currentPage === 1}
            onClick={() => {
              changeCurrentPage(1)
            }}
          >
            <FiChevronsLeft />
          </PaginationItem>

          <PaginationItem
            disabled={currentPage === 1}
            onClick={() => {
              prevPage()
            }}
          >
            <FiChevronLeft />
          </PaginationItem>
          {items}
          <PaginationItem
            disabled={currentPage === totalPages}
            onClick={() => {
              nextPage()
            }}
          >
            <FiChevronRight />
          </PaginationItem>
          <PaginationItem
            disabled={currentPage === totalPages}
            onClick={() => {
              changeCurrentPage(totalPages)
            }}
          >
            <FiChevronsRight />
          </PaginationItem>
        </PaginationList>
      </div>
    </PaginationWrapper>
  )
  return totalPages > 0 ? paginationRender : <></>
}

export default Pagination
