import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiEmpreendimentosDisponivelData
} from './interfaces'

class EmpreendimentosDisponivel extends BaseRequests {
  constructor() {
    super('empreendimentodisponivel')
  }

  async getAll(): Promise<IApiEmpreendimentosDisponivelData[]> {
    return this.sendGet<IApiEmpreendimentosDisponivelData[]>({})
  }

  async getAllActives(): Promise<IApiEmpreendimentosDisponivelData[]> {
    return this.sendGet<IApiEmpreendimentosDisponivelData[]>({ url: `ativos` })
  }

  async getById(id: string): Promise<IApiEmpreendimentosDisponivelData> {
    return this.sendGet<IApiEmpreendimentosDisponivelData>({ url: `${id}` })
  }

  async save(data: any): Promise<void> {
    alertLoading('Cadastrando Empreendimento...')
    return this.sendPost({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: any): Promise<void> {
    alertLoading('Atualizando Empreendimento...')
    return this.sendPut({ data, multipart: true })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    alertLoading('Apagando Empreendimento...')
    return this.sendDelete({ param: `${id}` })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }
}

export default EmpreendimentosDisponivel
