/* eslint-disable import/no-anonymous-default-export */
const customMediaQuery = (maxWidth: number) =>
  `@media (max-width: ${maxWidth}px)`

export default {
  border: {
    radius: '0.4rem'
  },
  font: {
    family:
      "'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    bold: 700,
    sizes: {
      xxsmall: '0.9rem',
      xsmall: '1rem',
      small: '1.25rem',
      medium: '2rem',
      large: '2.5rem',
      xlarge: '3.5rem',
      xxlarge: '5rem'
    }
  },
  colors: {
    primary: '#023e7d',
    secondary: '#33415c',
    success: '#008000',
    info: '#99c1de',
    warning: '#ff8700',
    danger: '#c10207',
    light: '#f0efeb',
    grey: '#868e96',
    grey100: '#cbcbcb',
    grey200: '#e9e3e6',
    grey300: '#eff7f6',
    dark: '#191d21',
    body: '#f4f6f9',
    font: '#1b263b',
    fontdark: '#001233',
    white: '#ffffff',
    mainBg: '#f4f6f9'
  },
  media: {
    custom: customMediaQuery,
    desktop: customMediaQuery(978),
    tablet: customMediaQuery(768),
    phone: customMediaQuery(576)
  }
}

/**
 * ${media.phone} {
    background: palevioletred;
  }
 */
