import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow, FormGroupSeparator } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import {
  InputSelectOptionsSimNao,
  InputSelectOptionsQuantidade
} from '../../../../config/InputSelectConfig'
import { FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa'
import InputCurrency from '../../../../components/InputCurrency'
import { alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import InputSelectAsync from '../../../../components/InputSelectAsync'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import InputSelect from '../../../../components/InputSelect'
import InputMask from '../../../../components/InputMask'
import InputDatePiker from '../../../../components/InputDatePiker'
import { IApiCaptacaoImagensData } from '../../../../services/ApiRequests/CaptacaoImagens/interfaces'

interface CaptacaoImagensFormProps {
  captacao?: IApiCaptacaoImagensData
}

const CaptacaoImagensForm: React.FC<
  IFormCallback & CaptacaoImagensFormProps
> = ({ callback, captacao }) => {
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  const formRef = useRef<FormHandles>(null)
  const [id, setId] = useState<number | null>(null)

  useEffect(() => {
    if (!formRef.current) return

    if (usuario.tipo === '5') {
      formRef.current.setData({
        corretorid: {
          label: usuario.nome,
          value: usuario.id
        }
      })
    }

    formRef.current.setData({
      cadastrado_site: 'N'
    })

    if (captacao && captacao.id) {
      setId(captacao.id)

      const { cidade, corretor, cod_imovel, ...dados } = captacao
      formRef.current.setData({
        ...dados,
        corretorid: {
          label: corretor?.nome,
          value: corretor?.id
        },
        cidadeid: {
          label: cidade?.nome,
          value: cidade?.id
        }
      })
    }
  }, [captacao, usuario.id, usuario.nome, usuario.tipo])

  const handleSearchCidade = useCallback(async (inputValue?: string) => {
    return Api.municipio.get(inputValue).then((data) => {
      const arrayData = data.map((mun) => ({
        label: `${mun.nome} - ${mun.uf}`,
        value: mun.id
      }))
      return arrayData
    })
  }, [])

  const handleSearchCorretor = useCallback(async (inputValue?: string) => {
    return Api.usuario.searchCorretor(inputValue).then((data) => {
      const arrayData = data.map((cor) => ({
        label: cor.nome,
        value: cor.id
      }))
      return arrayData
    })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})
      const dados = {
        id,
        ...data
      }

      await validateForm(dados)

      if (!id) {
        await Api.captacaoImagens.save(dados)
      } else {
        await Api.captacaoImagens.update(dados)
      }
      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)

      const message = err.response?.data?.error || err.message
      if (!Object.keys(errors).length && message) {
        alertError('', message)
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <InputSelect
          label="Imóvel cadastrado no Site ?"
          name="cadastrado_site"
          width="50%"
          isClearable={false}
          options={InputSelectOptionsSimNao}
        />
        <Input name="cod_imovel" label="Código do Imóvel" />
        <div
          style={{
            ...(usuario.tipo === '5' ? { display: 'none' } : {}),
            width: '30%'
          }}
        >
          <InputSelectAsync
            label="Corretor"
            name="corretorid"
            asyncLoadOptions={handleSearchCorretor}
          />
        </div>
      </FormRow>
      <FormGroupSeparator>
        <span>Localização do Imóvel</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <Input name="endereco" label="Endereço" />
        <Input name="numero" label="Número" width="10rem" />
      </FormRow>
      <FormRow>
        <Input name="bairro" label="Bairro" />
        <Input name="complemento" label="Complemento" />
        <Input name="quadra" label="Quadra" width="6rem" />
        <Input name="lote" label="Lote" width="6rem" />
        <Input name="apartamento" label="Apartamento" width="9rem" />
      </FormRow>
      <FormRow>
        <InputSelectAsync
          name="cidadeid"
          label="Cidade"
          asyncLoadOptions={handleSearchCidade}
        />
        <InputMask mask="99999-999" name="cep" label="CEP" width="12rem" />
      </FormRow>

      <FormGroupSeparator>
        <span>Dados do Imóvel</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputCurrency name="valor_imovel" label="Valor do Imóvel" />
        <InputSelect
          label="Dormitórios"
          name="dormitorios"
          options={InputSelectOptionsQuantidade}
        />
        <InputSelect
          label="Suítes"
          name="suites"
          options={InputSelectOptionsQuantidade}
        />
        <Input name="area_terreno" label="Área Terreno (m²)" />
        <Input name="area_edificacao" label="Área Edificação (m²)" />
      </FormRow>

      <FormGroupSeparator>
        <span>Detalhes da Captação</span>
        <hr />
      </FormGroupSeparator>
      <FormRow>
        <InputSelect
          label="Precisa de Drone ?"
          name="precisa_drone"
          options={InputSelectOptionsSimNao}
        />
        <InputSelect
          label="Precisa de Videos ?"
          name="precisa_videos"
          options={InputSelectOptionsSimNao}
        />
        <InputSelect
          label="Precisa de Fotos ?"
          name="precisa_fotos"
          options={InputSelectOptionsSimNao}
        />
      </FormRow>

      <FormRow>
        <InputSelect
          label="Agendado com Proprietário ?"
          name="agendado_proprietario"
          options={InputSelectOptionsSimNao}
        />
        <InputDatePiker
          name="data_agendamento"
          label="Dia de Visita"
          dateFormat="dd/MM/yyyy HH:mm"
          timeFormat="HH:mm"
          timeCaption="Horário"
          showTimeSelect
          timeIntervals={15}
        />
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default CaptacaoImagensForm
