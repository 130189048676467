import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'
import { CalendarContainer } from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export const StyledCalendarContainer = styled(CalendarContainer)`
  .react-datepicker {
    font-size: 1.1rem;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    border-top-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.primary};
    .react-datepicker__current-month {
      color: ${({ theme }) => theme.colors.white} !important;
    }

    .react-datepicker__day-names {
      font-size: 1rem;

      .react-datepicker__day-name {
        color: #fff !important;
      }
    }
  }
`
