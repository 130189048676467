import { Scope } from '@unform/core'
import React, { useCallback, useEffect, useState } from 'react'
import { FaPlusCircle, FaTrash } from 'react-icons/fa'
import { InputSelectOptionsTipoContaBancaria } from '../../config/InputSelectConfig'
import { FormRow } from '../../styles/form'
import { Button } from '../Button'
import Input from '../Input'
import InputCurrency from '../InputCurrency'
import InputSelect from '../InputSelect'
import { WrapperContasBancarias } from './styles'

interface IInputContasBancariasProps {
  minCount?: number
}

const InputContasBancarias: React.FC<IInputContasBancariasProps> = ({
  minCount = 0
}) => {
  const [count, setCount] = useState<Array<{ id: string }>>([])

  const handleAdd = useCallback(() => {
    setCount([...count, { id: (+new Date()).toString(36) }])
  }, [count])

  const handleDelete = useCallback(
    (id: string) => {
      const newList = count.filter((val, i) => val.id !== id)
      setCount(newList)
    },
    [count]
  )

  useEffect(() => {
    if (minCount) {
      const initialData = Object.keys([...Array(minCount)]).map((value) => {
        return { id: (+new Date()).toString(36) }
      })

      setCount(initialData)
    } else setCount([])
  }, [minCount])

  return (
    <>
      {count.map((value, index) => (
        <FormRow key={value.id}>
          <Scope path={`depositos[${index}]`}>
            <WrapperContasBancarias>
              <Input name="titular" label="Titular" />
              <Input name="banco" label="Banco" width="6rem" />
              <Input name="agencia" label="Agência" width="10rem" />
              <Input name="conta" label="Conta" width="9rem" />
              <InputSelect
                name="tipo"
                label="Tipo"
                options={InputSelectOptionsTipoContaBancaria}
                width="12rem"
              />
              <InputCurrency name="valor" label="Valor" width="10rem" />
            </WrapperContasBancarias>
          </Scope>
          <div className="buttonBottom">
            <Button
              type="button"
              variant="secondary"
              onClick={() => handleDelete(value.id)}
            >
              <FaTrash />
            </Button>
          </div>
        </FormRow>
      ))}
      <div className="buttonRight">
        <Button type="button" onClick={() => handleAdd()} variant="secondary">
          <FaPlusCircle />
        </Button>
      </div>
    </>
  )
}

export default InputContasBancarias
