import { CaptacaoImagensSetFiltro, IFiltroCaptacaoImagens, TypeFiltroCaptacaoImagens } from "./types"

export const setFiltroCaptacaoImagens = (filtro: IFiltroCaptacaoImagens): CaptacaoImagensSetFiltro => ({
  type: TypeFiltroCaptacaoImagens.SET_FILTRO,
  filtro
})

export const clearFiltroCaptacaoImagens = () => ({
  type: TypeFiltroCaptacaoImagens.CLEAR_FILTRO
})

export const modalCaptacaoImagensFiltroHandler = (show: boolean) => ({
  type: show ? TypeFiltroCaptacaoImagens.MODAL_SHOW : TypeFiltroCaptacaoImagens.MODAL_HIDE
})
