import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  clienteid: Yup.string().nullable().required('Cliente é um campo obrigatório'),
  corretorid: Yup.string().nullable().required('Corretor é um campo obrigatório'),
  valor_financ: Yup.number()
    .required('Informe o Valor')
    .moreThan(0, 'Informe um valor maior que zero.'),
  banco: Yup.string().required(),
  status: Yup.string().nullable().required('Status é um campo obrigatório'),
  cidadeid: Yup.string().nullable().required('Cidade é um campo obrigatório'),
  cidadebancoid: Yup.string().nullable().required('Cidade do Banco Financiador é um campo obrigatório'),
})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
