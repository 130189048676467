import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaFilter, FaPlus, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'

import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import Api from '../../../../services/Api'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import { IApiFinanciamentosData } from '../../../../services/ApiRequests/Financiamentos/interfaces'
import FinanciamentosPesquisa, {
  IFinanciamentosPesquisaHandles
} from '../../components/FinanciamentosPesquisa'
import { modalFinanciamentosFiltroHandler } from '../../../../redux/modules/financiamentos/actions'
import FinanciamentosForm from '../../forms/FinanciamentosForm'

const Financiamentos = () => {
  const dispatch = useDispatch()

  const modalRef = useRef<IModalPropsHandles>(null)
  const pesquisiaRef = useRef<IFinanciamentosPesquisaHandles>(null)
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const [financiamento, setFinanciamento] = useState<IApiFinanciamentosData>(
    {} as IApiFinanciamentosData
  )
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const handleDelete = useCallback(async (id?: number) => {
    if (!id) return

    alertConfirma(
      `Confirma a exclusão do Financiamento de código "${id}"?`,
      async () => {
        await Api.financiamentos.delete(id)
        pesquisiaRef.current?.refreshSearch()
      }
    )
  }, [])

  const handleEdit = useCallback(async (id?: number) => {
    if (!id) return

    const dados = await Api.financiamentos.getById(id)
    console.log(' edit ', dados)
    setFinanciamento(dados)
    modalRef.current?.openModal()
  }, [])

  const handleNew = useCallback(async () => {
    setFinanciamento({} as any)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setFinanciamento({} as IApiFinanciamentosData)
    pesquisiaRef.current?.refreshSearch()
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Financiamentos</h1>
        <Button onClick={() => handleNew()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Financiamentos</h4>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(modalFinanciamentosFiltroHandler(true))
            }}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <FinanciamentosPesquisa
            ref={pesquisiaRef}
            opcoesRender={(it) => (
              <>
                {usuarioRole !== 'CORRETOR' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={() => handleEdit(it.id)}
                    >
                      <FaEdit />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it.id)}
                    >
                      <FaTrash />
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${
          financiamento.id
            ? `Editar Financiamento de código ${financiamento.id}`
            : 'Cadastro de Financiamento'
        }`}
      >
        <FinanciamentosForm
          callback={() => modalRef.current?.closeModal()}
          financiamento={financiamento}
        />
      </Modal>
    </>
  )
}

export default Financiamentos
