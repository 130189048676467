import * as Yup from 'yup'
import { yupFiles } from '../../../../utils/yup/yup.files'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  corretorid: Yup.string().required('Informe o Corretor'),
  inquilinoid: Yup.string().required('Informe o Inquilino'),
  vencimento: Yup.string().required('Informe o Vencimento'),
  prazolocacao: Yup.string().required('Informe o Prazo de Locação'),
  valor: Yup.number()
    .required('Informe o Valor')
    .moreThan(0, 'Informe um valor maior que zero.'),
  arquivos: yupFiles(true),
  categorias: Yup.array().of(
    Yup.string().nullable().required('Informe uma categoria para o arquivo')
  )
})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
