import styled, { css } from 'styled-components'

export const Container = styled.div`
  table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    font-size: 1.2rem;
    border: 0;
  }
`

export const SemRegistros = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.grey};
`

export const TableHeader = styled.thead`
  border-bottom: 1px solid #cecece;
  th {
    line-height: 3rem;
  }

  ${({ theme }) => css`
    ${theme.media.tablet} {
      display: none;
    }
  `}
`
export const TableBody = styled.tbody`
  tr:nth-of-type(odd) {
    background: #f8f8f8;
  }

  tr {
    border-bottom: 1px solid #f0f0f0;
  }

  ${({ theme }) => css`
    ${theme.media.tablet} {
      tr:first-child {
        margin-top: 1rem;
      }
    }
  `}
`

type CellHeaderProps = {
  width?: string | null
}

export const Cell = styled.td`
  padding: 0.3rem;
  ${({ theme }) => css`
    ${theme.media.tablet} {
      display: flex;
      padding: 0.1rem;

      &::before {
        content: attr(data-heading);
        font-weight: bold;
        width: 10rem;
        height: 100%;
      }
    }
  `}
`

export const CellHeader = styled.th<CellHeaderProps>`
  text-align: left;
  ${({ width }) =>
    css`
      width: ${width ? width : 'auto'};
    `};
`

export const Row = styled.tr`
  line-height: 2rem;

  ${({ theme }) => css`
    ${theme.media.tablet} {
      display: block;
      margin-bottom: 0.625em;
      border-radius: 5px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;

      &:nth-of-type(odd) {
        background: #f4f4f4;
      }
    }
  `}
`

export const TableButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & > button + button,
  & > a + Button {
    margin-left: 5px;
  }
`
