import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducers } from './modules/rootReducer'
import { IUsuarioRedux } from './modules/usuario/types'
import { IVendaRedux } from './modules/vendas/types'
import { INotificacaoRedux } from './modules/notificacao/types'
import { IAluguelRedux } from './modules/aluguel/types'
import { ICaptacaoImovelRedux } from './modules/captacaoimovel/types'
import { IMetasRedux } from './modules/metas/types'
import { ICaptacaoImagensRedux } from './modules/captacaoimagens/types'
import { IFinanciamentosRedux } from './modules/financiamentos/types'

export type ReduxStore = {
  venda: IVendaRedux
  aluguel: IAluguelRedux
  usuario: IUsuarioRedux
  notificacao: INotificacaoRedux
  captacaoImovel: ICaptacaoImovelRedux
  captacaoImagens: ICaptacaoImagensRedux
  metas: IMetasRedux,
  financiamentos: IFinanciamentosRedux
}

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
)
