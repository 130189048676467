import React from 'react'
import { InputSelectOptionsAssinaturaTipo } from '../../../../config/InputSelectConfig'
import { IApiAluguelVistoria } from '../../../../services/ApiRequests/Aluguel/interfaces'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'

import LabelDetalhes from './LabelDetalhes'

interface IDetalhesVistoriaProps {
  vistoria?: IApiAluguelVistoria
}

const DetalhesVistoria = ({ vistoria }: IDetalhesVistoriaProps) => {
  if (!vistoria?.id) return <></>

  return (
    <>
      <section>
        <LabelDetalhes
          label="Vistoria"
          text={`${vistoria.vistoria ? 'Sim' : 'Não'}`}
          obs={vistoria?.vistoriaobs}
        />

        <LabelDetalhes
          label="Assinatura"
          text={`${vistoria.assinatura ? 'Sim' : 'Não'} ${getLabelFromOptions(
            InputSelectOptionsAssinaturaTipo,
            vistoria.assinaturatipo
          )}`}
        />
      </section>
    </>
  )
}

export default DetalhesVistoria
