import React, { useEffect, useImperativeHandle, forwardRef } from 'react'
import { useCallback } from 'react'

import { FiX } from 'react-icons/fi'
import { useBooleanState } from '../../hooks/useBooleanState'

import * as S from './styles'

interface IModalProps {
  title?: string
  children?: React.ReactNode
  onClose?: () => void
}

export interface IModalPropsHandles {
  openModal: () => void
  closeModal: () => void
}

const Modal: React.ForwardRefRenderFunction<IModalPropsHandles, IModalProps> = (
  { title, children, onClose },
  ref
) => {
  const { value: show, setTrue, setFalse } = useBooleanState(false)

  const handleClose = useCallback(() => {
    setFalse()
    if (onClose) onClose()
  }, [setFalse, onClose])

  useImperativeHandle(ref, () => {
    return {
      openModal: () => setTrue(),
      closeModal: () => handleClose()
    }
  })

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'scroll'
    }
  }, [show])

  return !show ? (
    <></>
  ) : (
    <S.ModalWrapper>
      <S.ModalBody>
        <S.ModalContainer>
          <S.ModalContainerHeader>
            <h4>{title}</h4>
            <span onClick={() => handleClose()}>
              <FiX />
            </span>
          </S.ModalContainerHeader>
          <S.ModalContainerMain>{children}</S.ModalContainerMain>
          <footer></footer>
        </S.ModalContainer>
      </S.ModalBody>
    </S.ModalWrapper>
  )
}

export default forwardRef(Modal)
