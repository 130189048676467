import { ButtonHTMLAttributes } from 'react'
import * as S from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: S.ButtonType
  hide?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  hide = false,
  ...rest
}) => {
  return (
    <S.ButtonStyled variant={variant} hide={hide} {...rest}>
      {children}
    </S.ButtonStyled>
  )
}

export { Button }
