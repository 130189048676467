import React from 'react'
import * as S from './styles'

import { IApiCaptacaoImovelData } from '../../../../services/ApiRequests/CaptacaoImovel/interfaces'
import LabelDetalhes from './LabelDetalhes'
import { formatTelefone } from '../../../../utils/mask'

interface IDetalhesClientes {
  dado?: IApiCaptacaoImovelData
}

const DetalhesCliente = ({ dado }: IDetalhesClientes) => {
  return (
    <>
      <h4>Dados do Proprietário</h4>
      <S.Lista>
        <section>
          <LabelDetalhes
            label="Nome"
            text={dado?.cliente?.nome || dado?.nome}
          />
          <LabelDetalhes
            label="CPF"
            text={dado?.cliente?.documento || dado?.cpf}
          />
          <LabelDetalhes label="RG" text={dado?.cliente?.rg || dado?.rg} />
        </section>
        <section>
          <LabelDetalhes
            label="Logradouro"
            text={
              dado?.cliente?.logradouro
                ? `${dado?.cliente?.logradouro}, ${dado?.cliente?.numero}, ${dado?.cliente?.bairro}`
                : dado?.cliente_endereco
            }
          />
          <LabelDetalhes
            label="E-mail"
            text={dado?.cliente?.email || dado?.email}
          />
          <LabelDetalhes
            label="Telefone"
            text={formatTelefone(dado?.cliente?.telefone || dado?.telefone)}
          />
          <LabelDetalhes
            label="Celular"
            text={formatTelefone(dado?.cliente?.celular)}
          />
        </section>
      </S.Lista>
    </>
  )
}

export default DetalhesCliente
