import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaEye, FaImage } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxStore } from '../../../../redux'
import Api from '../../../../services/Api'
import {
  formatDateTime,
  dateFormat,
  dateTimeFormat
} from '../../../../utils/date'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { Button } from '../../../../components/Button'
import Pagination from '../../../../components/Pagination'
import {
  IApiCaptacaoImagensSearchItem,
  IApiCaptacaoImagensSearchRequest
} from '../../../../services/ApiRequests/CaptacaoImagens/interfaces'
import CaptacaoImagensFiltro from '../CaptacaoImagensFiltro'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { getRole, RoleType } from '../../../../config/Roles'
import CaptacaoImagensStatus from '../CaptacaoImagensStatus'
import { ICaptacaoImagensRedux } from '../../../../redux/modules/captacaoimagens/types'
import {
  modalCaptacaoImagensFiltroHandler,
  setFiltroCaptacaoImagens
} from '../../../../redux/modules/captacaoimagens/actions'

export interface ICaptacaoImagensPesquisaHandles {
  refreshSearch: () => void
}

interface ICaptacaoImagensPesquisaProps {
  opcoesRender?: (
    item: Partial<IApiCaptacaoImagensSearchItem>
  ) => React.ReactNode
}

const CaptacaoImagensPesquisa: React.ForwardRefRenderFunction<
  ICaptacaoImagensPesquisaHandles,
  ICaptacaoImagensPesquisaProps
> = ({ opcoesRender }, ref) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const dispatch = useDispatch()
  const [search, setSearch] = useState<IApiCaptacaoImagensSearchRequest>()
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  const captacaoImagensRedux = useSelector<ReduxStore, ICaptacaoImagensRedux>(
    (state) => state.captacaoImagens
  )
  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  useEffect(() => {
    if (captacaoImagensRedux.pesquisa.modalShow) modalRef.current?.openModal()
  }, [captacaoImagensRedux?.pesquisa?.modalShow])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setSearch({} as IApiCaptacaoImagensSearchRequest)
    Api.captacaoImagens
      .search({ ...captacaoImagensRedux.pesquisa.filtro })
      .then((data) => {
        setSearch(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [captacaoImagensRedux.pesquisa.filtro])

  useImperativeHandle(ref, () => {
    return {
      refreshSearch: () => handleLoad()
    }
  })

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <Table<IApiCaptacaoImagensSearchItem>
        headers={{
          id: 'Código',
          created_at: 'Cadastro',
          corretor: 'Corretor',
          data_agendamento: 'Dia Agendado',
          cod_imovel: 'Código Imóvel',
          status: 'Status',
          opcoes: 'Opções'
        }}
        columnSizes={{
          id: '3rem',
          created_at: '5rem',
          corretor: '8rem',
          data_agendamento: '6rem',
          cod_imovel: '8rem',
          status: '7rem',
          opcoes: '9rem'
        }}
        items={search?.list}
        customRenderers={{
          corretor: (it) => {
            return it.corretor?.nome
          },
          data_agendamento: (it) => {
            if (it?.data_agendamento)
              return formatDateTime(it.data_agendamento, dateTimeFormat)
          },
          created_at: (it) => {
            if (it.created_at) return formatDateTime(it.created_at, dateFormat)
          },
          opcoes: (it) => (
            <TableButtonWrapper>
              <Link
                to={`/dashboard/marketing/captacaoImagens/detalhes/${it.id}`}
              >
                <Button variant="info">
                  <FaEye />
                </Button>
              </Link>
              {opcoesRender && opcoesRender(it)}
            </TableButtonWrapper>
          ),
          status: (it) => {
            if (it.id)
              return (
                <CaptacaoImagensStatus
                  dados={{
                    id: it.id,
                    status: it.status
                  }}
                  userRole={usuarioRole}
                />
              )
          }
        }}
      />
      <Pagination
        pageSize={search?.pageSize}
        totalCount={search?.count}
        onPageChange={(page) => dispatch(setFiltroCaptacaoImagens({ page }))}
      />

      <Loading ref={loadRef} />

      <Modal
        ref={modalRef}
        title={`Filtrar Pesquisa de Captação de Imagens`}
        onClose={() => dispatch(modalCaptacaoImagensFiltroHandler(false))}
      >
        <CaptacaoImagensFiltro
          callback={() => modalRef.current?.closeModal()}
        />
      </Modal>
    </>
  )
}

export default forwardRef(CaptacaoImagensPesquisa)
