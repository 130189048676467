export const Roles = {
  ADMIN: '1',
  FINANCEIRO: '2',
  FINANCIAMENTO: '3',
  JURIDICO: '4',
  CORRETOR: '5',
  MARKETING: '6'
}

export type RolesTypes = (keyof typeof Roles)[]
export type RoleType = keyof typeof Roles

export const getRole = (value: string): RoleType => {
  const keys = Object.keys(Roles) as Array<keyof typeof Roles>
  const index = Object.values(Roles).findIndex((role) => role === value)
  return keys[index]
}
