import React from 'react'
import { objectKeys } from '../../utils/object'
import { MinTableItem, TableProps } from './interfaces'
import { Items } from './Items'

import {
  CellHeader,
  Container,
  Row,
  SemRegistros,
  TableBody,
  TableHeader
} from './styles'

function Table<T extends MinTableItem>({
  items,
  headers,
  columnSizes,
  customRenderers
}: TableProps<T>) {
  if (items?.length === 0)
    return (
      <Container>
        <SemRegistros>Nenhum registro carregado</SemRegistros>
      </Container>
    )

  return (
    <>
      <Container>
        <table>
          <TableHeader>
            <Row>
              {objectKeys(headers).map((key, index) => {
                return (
                  <CellHeader key={index} width={columnSizes?.[key]}>
                    {headers[key]}
                  </CellHeader>
                )
              })}
            </Row>
          </TableHeader>
          <TableBody>
            <Items
              items={items}
              headers={headers}
              customRenderers={customRenderers}
            />
          </TableBody>
        </table>
      </Container>
    </>
  )
}

export { Table }
