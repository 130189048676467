import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FaEye } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReduxStore } from '../../../../redux'
import Api from '../../../../services/Api'
import { formatDateTime, dateFormat } from '../../../../utils/date'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import { Button } from '../../../../components/Button'
import Pagination from '../../../../components/Pagination'
import {
  IApiFinanciamentosSearchItem,
  IApiFinanciamentosSearchRequest
} from '../../../../services/ApiRequests/Financiamentos/interfaces'
import { IFinanciamentosRedux } from '../../../../redux/modules/financiamentos/types'
import { formatCurrency } from '../../../../utils/currency'
import {
  modalFinanciamentosFiltroHandler,
  setFiltroFinanciamentos
} from '../../../../redux/modules/financiamentos/actions'
import FinanciamentosFiltro from '../FinanciamentosFiltro'
import FinanciamentoStatus from '../FinanciamentoStatus'

export interface IFinanciamentosPesquisaHandles {
  refreshSearch: () => void
}

interface IFinanciamentosPesquisaProps {
  opcoesRender?: (
    item: Partial<IApiFinanciamentosSearchItem>
  ) => React.ReactNode
}

const FinanciamentosPesquisa: React.ForwardRefRenderFunction<
  IFinanciamentosPesquisaHandles,
  IFinanciamentosPesquisaProps
> = ({ opcoesRender }, ref) => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)

  const dispatch = useDispatch()
  const [search, setSearch] = useState<IApiFinanciamentosSearchRequest>()

  const financiamentosRedux = useSelector<ReduxStore, IFinanciamentosRedux>(
    (state) => state.financiamentos
  )

  useEffect(() => {
    if (financiamentosRedux.pesquisa.modalShow) modalRef.current?.openModal()
  }, [financiamentosRedux?.pesquisa?.modalShow])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    setSearch({} as IApiFinanciamentosSearchRequest)
    Api.financiamentos
      .search({ ...financiamentosRedux.pesquisa.filtro })
      .then((data) => {
        setSearch(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [financiamentosRedux.pesquisa.filtro])

  useImperativeHandle(ref, () => {
    return {
      refreshSearch: () => handleLoad()
    }
  })

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <Table<IApiFinanciamentosSearchItem>
        headers={{
          created_at: 'Cadastro',
          cliente: 'Cliente',
          banco: 'Banco',
          valor_financ: 'Valor Financ.',
          corretor: 'Corretor',
          situation: 'Situação',
          status: 'Situação',
          opcoes: 'Opções'
        }}
        columnSizes={{
          cliente: '9rem',
          banco: '4rem',
          valor_financ: '4rem',
          created_at: '4rem',
          corretor: '5rem',
          situation: '7rem',
          status: '4rem',
          opcoes: '6rem'
        }}
        items={search?.list}
        customRenderers={{
          corretor: (it) => {
            return it.corretor?.nome
          },
          cliente: (it) => {
            return it.cliente?.nome
          },
          valor_financ: (it) => {
            if (it.valor_financ) return formatCurrency(it.valor_financ)
          },
          created_at: (it) => {
            if (it.created_at) return formatDateTime(it.created_at, dateFormat)
          },
          opcoes: (it) => (
            <TableButtonWrapper>
              <Link to={`/dashboard/financiamentos/detalhes/${it.id}`}>
                <Button variant="info">
                  <FaEye />
                </Button>
              </Link>
              {opcoesRender && opcoesRender(it)}
            </TableButtonWrapper>
          ),
          status: (it) => <FinanciamentoStatus dados={it} />
        }}
      />
      <Pagination
        pageSize={search?.pageSize}
        totalCount={search?.count}
        onPageChange={(page) => dispatch(setFiltroFinanciamentos({ page }))}
      />

      <Loading ref={loadRef} />

      <Modal
        ref={modalRef}
        title={`Filtrar Pesquisa de Financiamentos`}
        onClose={() => dispatch(modalFinanciamentosFiltroHandler(false))}
      >
        <FinanciamentosFiltro callback={() => modalRef.current?.closeModal()} />
      </Modal>
    </>
  )
}

export default forwardRef(FinanciamentosPesquisa)
