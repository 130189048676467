import { objectKeys } from '../../utils/object'
import { isPrimitive } from '../../utils/primitiveValue'
import { MinTableItem, ItemsProps } from './interfaces'
import { Cell, Row } from './styles'

export function Items<T extends MinTableItem>({
  items,
  headers,
  customRenderers
}: ItemsProps<T>) {
  return (
    <>
      {items?.map((item) => (
        <Row key={`${item.id}row`}>
          {objectKeys(headers).map((key, i) => {
            const customRenderer = customRenderers?.[key]

            if (customRenderer) {
              return (
                <Cell data-heading={headers[key]} key={i}>
                  {customRenderer(item)}
                </Cell>
              )
            }

            return (
              <Cell data-heading={headers[key]} key={i}>
                {isPrimitive(item[key]) ? item[key] : ''}
              </Cell>
            )
          })}
        </Row>
      ))}
    </>
  )
}
