import { Reducer } from 'redux'
import produce from 'immer'
import { ActionUsuario, IUsuarioRedux, TypeUsuario } from './types'
import { request } from '../../../services/ApiRequests/http'

const INITIAL_STATE: IUsuarioRedux = {
  usuario: {},
  token: ''
} as IUsuarioRedux

export const usuario: Reducer<IUsuarioRedux, ActionUsuario> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case TypeUsuario.SET_USUARIO: {
        draft.usuario = action.payload.usuario
        draft.token = action.payload.token

        request.defaults.headers.Authorization = `Bearer ${draft.token}`
        break
      }
      case TypeUsuario.CLEAR_USUARIO: {
        draft = INITIAL_STATE
        request.defaults.headers.Authorization = ''
        break
      }
      default: {
        return state
      }
    }
  })
}
