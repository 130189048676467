import Swal from 'sweetalert2'

export const alertShow = async (
  texto?: string,
  titulo?: string,
  props?: any
) => {
  return Swal.fire({
    title: titulo,
    text: texto,
    confirmButtonColor: '#023e7d',
    ...props
  })
}

export const alertClose = () => Swal.close()

export const alertLoading = async (titulo = 'Carregando', texto?: string) => {
  return alertShow(texto, titulo, {
    allowEscapeKey: false,
    allowOutsideClick: false,
    timer: 10000,
    didOpen: () => {
      Swal.showLoading()
    }
  })
}

export const alertSucesso = async (titulo = 'Sucesso!', texto?: string) => {
  return alertShow(texto, titulo, {
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  })
}

export const alertSucessoButtonOK = async (titulo = 'Sucesso!', texto?: string) => {
  return alertShow(texto, titulo, {
    icon: 'success',
    confirmButtonText: 'OK'
  })
}

export const alertError = async (
  titulo = 'Erro encontrado!',
  texto?: string
) => {
  return alertShow(texto, titulo, {
    icon: 'error',
    confirmButtonText: 'OK'
  })
}

export const alertConfirma = async (
  titulo = 'Você confirma?',
  callConfirmed?: () => void,
  callCanceled?: () => void
) => {
  return Swal.fire({
    title: titulo,
    showCancelButton: true,
    confirmButtonText: 'Sim',
    confirmButtonColor: '#2d3192',
    cancelButtonText: 'Não'
  }).then((result) => {
    if (result.isConfirmed) {
      if (callConfirmed) callConfirmed()
    } else if (result.isDismissed) {
      if (callCanceled) callCanceled()
    }
    Swal.close()
  })
}
