import React, { useState, useCallback } from 'react'
import { useRef } from 'react'
import { FiEdit } from 'react-icons/fi'
import { Button } from '../../../../components/Button'

import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import VendasPesquisa from '../../components/VendasPesquisa'
import VendaFinanciamentoForm from '../../forms/VendaFinanciamento'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { IApiVendaFinanciamento } from '../../../../services/ApiRequests/Venda/interfaces'
import Api from '../../../../services/Api'
import { useDispatch } from 'react-redux'
import { modalFiltroHandler } from '../../../../redux/modules/vendas/actions'
import { FaFilter } from 'react-icons/fa'

const VendasFinanciamento = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const dispatch = useDispatch()

  const [data, setData] = useState<IApiVendaFinanciamento>(
    {} as IApiVendaFinanciamento
  )
  const [vendaid, setVendaId] = useState<number | null>()

  const handleEdit = useCallback((id?: number) => {
    setData({} as IApiVendaFinanciamento)
    setVendaId(null)

    if (!id) return
    Api.venda.getFinanciamento(id).then((res) => {
      setVendaId(id)
      setData(res)
      modalRef.current?.openModal()
    })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Vendas Financiamento</h1>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Vendas</h4>
          <Button
            variant="secondary"
            onClick={() => dispatch(modalFiltroHandler(true))}
          >
            <FaFilter /> Filtrar
          </Button>
        </header>
        <div>
          <VendasPesquisa
            opcoesRender={(it) => (
              <>
                <Button variant="primary" onClick={() => handleEdit(it.id)}>
                  <FiEdit />
                </Button>
              </>
            )}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        title={`Controle de Financiamento da venda ${vendaid}`}
      >
        <VendaFinanciamentoForm
          financiamento={data}
          callback={() => modalRef.current?.closeModal()}
        />
      </Modal>
    </>
  )
}

export default VendasFinanciamento
