import React from 'react'
import { IApiAluguelFinanceiro } from '../../../../services/ApiRequests/Aluguel/interfaces'

import LabelDetalhes from './LabelDetalhes'

interface IDetalhesFinanceiroProps {
  financeiro: IApiAluguelFinanceiro
}

const DetalhesFinanceiro = ({ financeiro }: IDetalhesFinanceiroProps) => {
  return (
    <>
      <section>
        <LabelDetalhes label="Repasses" text={financeiro.repasses} />
      </section>
    </>
  )
}

export default DetalhesFinanceiro
