import HttpRequest from '../http'
import {
  IHttpBaseRequest,
  IHttpRequest,
  IHttpRequestDelete,
  IHttpRequestGet,
  IHttpRequestPost,
  IHttpRequestPut
} from '../interfaces'

class BaseRequests implements IHttpBaseRequest {
  private http: IHttpRequest

  constructor(public readonly baseUrl: string) {
    this.http = new HttpRequest()
  }

  sendPost = async <T>({
    url,
    data,
    multipart = false
  }: IHttpRequestPost): Promise<T> => {
    return this.http.post<T>({
      url: `/${this.baseUrl}${url ? `/${url}` : ''}`,
      data,
      multipart
    })
  }

  sendPut = async <T>({
    url,
    data,
    multipart = false
  }: IHttpRequestPut): Promise<T> => {
    return this.http.put<T>({
      url: `/${this.baseUrl}${url ? `/${url}` : ''}`,
      data,
      multipart
    })
  }

  sendGet = async <T>({
    url,
    params,
    responseType
  }: IHttpRequestGet): Promise<T> => {
    return this.http.get<T>({
      url: `/${this.baseUrl}${url ? `/${url}` : ''}`,
      params,
      responseType
    })
  }

  sendDelete = async <T>({ url, param }: IHttpRequestDelete): Promise<T> => {
    return this.http.delete<T>({
      url: `/${this.baseUrl}${url ? `/${url}` : ''}`,
      param
    })
  }
}

export default BaseRequests
